<template>
  <base-modal modal-name="roles-modal">
    <template #content>
      <div class="text-center mb-4">
        <h4 class="text-muted fw-bold">Role: {{ role }}</h4>
      </div>
      <ul>
        <li v-for="(permission, index) in permissions" :key="index">
          {{ permission }}
        </li>
      </ul>
      <button
        type="button"
        class="btn btn-warning text-dark fw-bold w-100 mt-4"
        data-bs-dismiss="modal"
      >
        Tutup
      </button>
    </template>
  </base-modal>
</template>

<script>
export default {
  props: {
    role: {
      type: String,
      default: ''
    },
    permissions: {
      type: Array,
    }
  }
}
</script>