import MainLayout from "@/components/layouts/MainLayout";
import store from "@/store";

const changeMenuAccount = () => {
  store.dispatch("changeMenu", "account_management");
};

const routes = [
  {
    path: "/account-management/view",
    name: "account-management.view",
    component: () => import("@/views/account_management/AccountView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Daftar Akun",
      breadcrumbs: [
        { name: "Manajemen Akun Anggota", url: "/" },
        { name: "Daftar Akun", url: "/" },
      ],
    },
    beforeEnter: changeMenuAccount,
  },
  {
    path: "/account-management/configuration",
    name: "account-management.configuration",
    component: () => import("@/views/account_management/ConfigurationView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Konfigurasi",
      breadcrumbs: [
        { name: "Manajemen Akun Anggota", url: "/" },
        { name: "Konfigurasi", url: "/" },
      ],
    },
    beforeEnter: changeMenuAccount,
  },
  {
    path: "/account-management/change-request",
    name: "account-management.change-request",
    component: () => import("@/views/account_management/ChangeRequestView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Permohonan Perubahan",
      breadcrumbs: [
        { name: "Manajemen Akun Anggota", url: "/" },
        { name: "Permohonan Perubahan", url: "/" },
      ],
    },
    beforeEnter: changeMenuAccount,
  },
];

export default routes;
