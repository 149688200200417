<template>
  <button type="button" :class="`btn btn-${color} py-2 px-5`" data-bs-dismiss="modal" aria-label="Close">
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String
    }
  }
}
</script>