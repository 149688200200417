<template>
  <button type="button" :class="`btn btn-${color} py-2 px-5 fw-bold`" data-bs-toggle="modal" :data-bs-target="`#${modal}`">
    <slot></slot>
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    modal: {
      default: '-',
      type: String
    },
    color: {
      default: '-',
      type: String
    },
    label: {
      default: '-',
      type: String
    }
  }
}
</script>