<template>
  <div class="d-flex justify-content-start align-items-center pt-2">
    <p class="m-0 text-muted">Total Data: {{ totalData }}</p>
    <div class="d-flex align-items-center ms-auto">
      <p class="m-0 text-muted me-2">Baris perhalaman</p>
      <select
        name="per-page"
        id="per-page"
        class="form-select form-select-sm bg-light border-0"
        style="width: auto"
        :value="perPage"
        @change="handleChange"
      >
        <option value="1">1</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <div class="d-flex align-items-center ms-3">
      <div>
        <small>{{ page }}</small>
        <small class="mx-2">of</small>
        <small>{{ totalPage }}</small>
      </div>
      <button class="btn btn-sm btn-light ms-2" @click="handlePrevPage">
        <font-awesome icon="chevron-left" />
      </button>
      <button class="btn btn-sm btn-light" @click="handleNextPage">
        <font-awesome icon="chevron-right" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    totalData: {
      type: Number,
      default: 0,
    },
    totalPage: {
      type: Number,
      default: 1,
    },
    handleChange: {
      type: Function,
    },
    handlePrevPage: {
      type: Function,
    },
    handleNextPage: {
      type: Function,
    },
  },
};
</script>
