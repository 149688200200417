import RegistrationService from "@/services/registration";

const state = {
  borrowers: null,
  principles: [],
  principle: null,
  meta: null,
};

const getters = {
  borrowers: (state) => state.borrowers,
  principles: (state) => state.principles,
  principle: (state) => state.principle,
  meta: (state) => state.meta,
};

const mutations = {
  SET_BORROWERS(state, payload) {
    state.borrowers = payload;
  },
  SET_PRINCIPLES(state, payload) {
    state.principles = payload;
  },
  SET_PRINCIPLE(state, payload) {
    state.principle = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

const actions = {
  async retrieveBorrowers({ commit }, params) {
    const response = await RegistrationService.retrieveBorrowers(params);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
    commit("SET_BORROWERS", response.data.data.items);
  },
  async retrievePrinciples({ commit }, payload) {
    const response = await RegistrationService.retrievePricinples(payload);

    commit("SET_PRINCIPLES", response.data.data.items);
  },
  async showPricinple({ commit }, payload) {
    const response = await RegistrationService.retrievePricinples(
      payload.status
    );
    const principle = await response.data.data.items.find((value) => {
      return value.id === payload.principle_id;
    });

    commit("SET_PRINCIPLE", principle);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
