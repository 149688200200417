<template>
  <ul style="list-style: none">
    <li class="list-group-item px-0">
      <div class="card border-0 p-0">
        <div class="card-body p-0">
          <div class="d-flex justify-content-between">
            <span>
              <span class="fw-bold">{{ history.admin.full_name }}</span>
              {{ history.config_change_type }}
            </span>
            <span>{{ dateFormat(history.created_at, "D MMMM") }}</span>
          </div>
          <div class="mt-3">
            {{ rupiahFormat(history.config_value.minimum_acceptance) }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    history: {
      type: Object,
    },
  },
};
</script>
