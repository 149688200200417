<template>
  <base-modal :modal-name="name">
    <template #content>
      <div class="text-center mb-5">
        <h3 class="fw-bold fs-4">{{ title }}</h3>
        <p v-if="subtitle">{{ subtitle }}</p>
      </div>

      <div class="stepper d-flex flex-column mt-5 ml-2">
        <div v-for="(item, index) of dataList" :key="index">
          <div class="d-flex mb-1 w-100">
            <div class="d-flex flex-column pr-4 align-items-center">
              <div
                class="rounded-circle circle text-white mb-1"
                :class="{
                  'bg-green': item.icon === 'check',
                  'bg-light': item.icon === '',
                  'bg-danger': item.icon === 'close',
                }"
              >
                <span
                  class="checklist d-flex justify-content-center align-items-center p-1"
                >
                  <font-awesome :icon="item.icon"></font-awesome>
                </span>
              </div>
              <div v-if="index + 1 < dataList.length" class="line h-30"></div>
            </div>
            <div class="row w-100">
              <div class="col-lg-6">
                <div class="mx-4 text-md fw-bold">{{ item.name }}</div>
              </div>
              <div
                class="col-lg-6 text-right d-flex align-items-center justify-content-end"
              >
                <span
                  class="fw-bold d-flex align-items-center text-nowrap text-md"
                  >{{ item.date }}
                  <tooltip-icon
                    class="ms-2"
                    v-if="item.message"
                    :message="item.message"
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-3" v-if="createdAt">
        <span>Tanggal Permohonan</span>
        <span class="fw-bold">{{ fullDateFormat(createdAt) }}</span>
      </div>
      <div class="d-flex justify-content-between mb-3" v-if="verifyDataAt">
        <span>Tanggal Disetujui Verifikasi Data</span>
        <span class="fw-bold">{{ fullDateFormat(verifyDataAt) }}</span>
      </div>
      <div class="d-flex justify-content-between mb-3" v-if="verifyLoanAt">
        <span>Tanggal Loan Limit di Submit</span>
        <span class="fw-bold">{{ fullDateFormat(verifyLoanAt) }}</span>
      </div>
      <div class="d-flex justify-content-between mb-3" v-if="verifyComitteeAt">
        <span>Tanggal Verifikasi Komite</span>
        <span class="fw-bold">{{ fullDateFormat(verifyComitteeAt) }}</span>
      </div>
      <div class="d-flex justify-content-between mb-3" v-if="uploadDocumentAt">
        <span>Tanggal Upload Dokumen</span>
        <span class="fw-bold">{{ fullDateFormat(uploadDocumentAt) }}</span>
      </div>
      <div class="d-flex justify-content-between mb-3" v-if="uploadDocumentAt">
        <span>No. Rekening Master</span>
        <span class="fw-bold">{{ loanMasterNumber }}</span>
      </div>
      <div class="text-center">
        <dismiss-button
          @click="redirect"
          label="OK"
          color="warning"
          class="fw-bold text-dark w-75 mt-5 text-white"
        />
      </div>
    </template>
  </base-modal>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    name: {
      type: String,
      default: "approved",
    },
    title: {
      type: String,
      default: "",
    },
    createdAt: {
      type: Date,
      default: null,
    },
    verifyDataAt: {
      type: Date,
      default: null,
    },
    verifyComitteeAt: {
      type: Date,
      default: null,
    },
    uploadDocumentAt: {
      type: Date,
      default: null,
    },
    verifyLoanAt: {
      type: Date,
      default: null,
    },
    redirect: {
      type: Function,
      default: () => {
        this.$router.push({
          name: "home",
        });
      },
    },
    subtitle: {
      type: String,
      default: null,
    },
    loanMasterNumber: {
      type: String,
      default: "",
    },
    dataList: {
      type: Array,
      default: () => [
        {
          name: "Data Nasabah Disimpan",
          date: "20 Juni 2023",
          icon: "check", // check | close
          message: "", // optional
        },
        {
          name: "Verifikasi Data Ditolak",
          date: "20 Juni 2023",
          icon: "close", // check | close
          message: "Alasan Penolakan: Informasi perusahaan tidak sesuai", // optional
        },
        {
          name: "Verifikasi Komite",
          date: "",
          icon: "", // check | close
          message: "", // optional
        },
      ],
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  .bg-light {
    background-color: #ebebeb !important;
    border: 1px solid #b6b6b6 !important;
  }

  .line {
    width: 1px;
    background-color: #dadada;
  }

  .lead {
    font-size: 1.1rem;
  }

  .circle {
    height: 24px;
    width: 24px;
    background-color: #fafafd;
    border-radius: 50%;
    display: inline-block;
  }

  .dot {
    margin-left: 5px;
    margin-bottom: 3px;
    height: 10px;
    width: 10px;
    background-color: #fafafd;
    border-radius: 50%;
    display: inline-block;
  }

  .no-dot {
    margin-left: 5px;
    margin-bottom: 3px;
    height: 10px;
    width: 10px;
    background-color: #ebebeb;
    border-radius: 50%;
    display: inline-block;
  }
}

.h-30 {
  height: 30px;
}
</style>
