<template>
  <form @submit.prevent="submit">
    <p class="fw-bold text-muted mb-2">Filter Data</p>
    <div class="d-xl-flex justify-content-between align-items-center gap-3">
      <div class="flex-fill">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 mb-3">
            <date-picker
              v-model="filter.due_date"
              :format="format"
              placeholder="Tanggal Jatuh Tempo"
              inputClassName="form-control-date"
              autoApply
              :enableTimePicker="false"
            />
          </div>
          <div class="col-12 col-md-6 col-xl-4 mb-3">
            <date-picker
              v-model="filter.expected_billing_date"
              :format="format"
              placeholder="Tanggal Janji Bayar"
              inputClassName="form-control-date"
              autoApply
              :enableTimePicker="false"
            />
          </div>
          <div class="col-12 col-md-6 col-xl-4 mb-3">
            <select-input
              v-model="filter.status"
              :options="[
                { value: 'Tidak Diangkat', name: 'Tidak Diangkat' },
                {
                  value: 'Pembayaran Invoice Terlambat',
                  name: 'Pembayaran Invoice Terlambat',
                },
                { value: 'Masalah Ekonomi', name: 'Masalah Ekonomi' },
                { value: 'Salah Sambung', name: 'Salah Sambung' },
                { value: 'Tidak di tempat', name: 'Tidak di tempat' },
                { value: 'Lainnya (Koneksi)', name: 'Lainnya (Koneksi)' },
                { value: 'Lainnya (Alasan)', name: 'Lainnya (Alasan)' },
                { value: 'Janji Bayar', name: 'Janji Bayar' },
              ]"
              placeholder="Status Penagihan Terakhir"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="input-group">
              <label class="input-group-text bg-white border-end-0 text-muted">
                <font-awesome icon="fa-solid fa-search" />
              </label>
              <BaseInput
                v-model="filter.borrower_name"
                placeholder="Nama Peminjam"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="input-group">
              <label class="input-group-text bg-white border-end-0 text-muted">
                <font-awesome icon="fa-solid fa-search" />
              </label>
              <BaseInput
                v-model="filter.principle_name"
                placeholder="Nama Principal"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 mb-3">
            <select-input
              v-model="filter.financing_program"
              :options="[
                { value: 'supplier', name: 'Supplier' },
                { value: 'distributor', name: 'Distributor' },
              ]"
              placeholder="Produk Fasilitas Pinjaman"
            />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center gap-3 pb-3">
        <button
          class="btn btn-outline-warning fw-bold px-4 outline-none shadow-none"
        >
          Apply
        </button>
        <button
          @click="reset"
          class="btn btn-outline-primary border-0 fw-bold px-4 outline-none shadow-none"
        >
          Reset
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import dayjs from "dayjs";
import { mapActions } from "vuex";
import SelectInput from "../input/SelectInput.vue";
import BaseInput from "../input/BaseInput.vue";
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  name: "CollectionFilter",
  components: {
    SelectInput,
    BaseInput,
  },
  props: {
    type: {
      type: String,
      default: "bill_reminder",
    },
  },
  data() {
    return {
      format: "dd/MM/yyyy",
      filter: {
        due_date: "",
        expected_billing_date: "",
        last_billing_status: "",
        borrower_name: "",
        principle_name: "",
        financing_program: "",
      },
      page: {
        page: 1,
        per_page: 10,
      },
    };
  },
  methods: {
    ...mapActions("collectionModule", [
      "retrieveBillReminder",
      "retrieveDueDate",
      "retrieveLatePayment",
      "retrieveArrears",
      "retrieveTaskManagement",
    ]),
    async submit() {
      const filter = { ...this.page };

      for (const key in this.filter) {
        if (this.filter[key] !== "") {
          filter[key] = this.filter[key];
        }
      }

      switch (this.type) {
        case "bill_reminder":
          await this.retrieveBillReminder(filter);
          break;
        case "due_date":
          await this.retrieveDueDate(filter);
          break;
        case "late_payment":
          await this.retrieveLatePayment(filter);
          break;
        case "arrears":
          await this.retrieveArrears(filter);
          break;
        case "task_management":
          await this.retrieveTaskManagement(filter);
          break;

        default:
          break;
      }
    },
    async reset() {
      this.filter = {
        due_date: "",
        expected_billing_date: "",
        last_billing_status: "",
        borrower_name: "",
        principle_name: "",
        financing_program: "",
      };

      switch (this.type) {
        case "bill_reminder":
          await this.retrieveBillReminder(this.page);
          break;
        case "due_date":
          await this.retrieveDueDate(this.page);
          break;
        case "late_payment":
          await this.retrieveLatePayment(this.page);
          break;
        case "arrears":
          await this.retrieveArrears(this.page);
          break;
        case "task_management":
          await this.retrieveTaskManagement(this.page);
          break;

        default:
          break;
      }
    },
  },
  watch: {
    "filter.due_date"(newValue) {
      if (dayjs(newValue).isValid()) {
        this.filter.due_date = this.dateFormat(newValue, "YYYY-MM-DD");
      }
    },
    "filter.expected_billing_date"(newValue) {
      if (dayjs(newValue).isValid()) {
        this.filter.expected_billing_date = this.dateFormat(
          newValue,
          "YYYY-MM-DD"
        );
      }
    },
  },
};
</script>

<style>
.input-group .form-control {
  border-left: none !important;
}
.form-control:focus {
  border-color: #ddd !important;
}
</style>
