import { api } from "@/libs/api";

class SupplierService {
  async retrieve(status = "waiting") {
    return await api.get(
      `/borrower?status=${status}&financing_program=supplier`
    );
  }

  async show(id) {
    return await api.get(`/borrower/detail/${id}`);
  }

  async verifyData(payload) {
    return await api.post("/registration/borrower/verify/data", payload);
  }

  async verifyLoan(payload) {
    return await api.post("/registration/borrower/verify/loan", payload);
  }

  async verifyCommittee(payload) {
    return await api.post("/registration/borrower/verify/committee", payload);
  }

  async uploadVerify(payload) {
    return await api.post("/registration/borrower/verify/upload", payload);
  }
}

export default new SupplierService();
