import MainLayout from "@/components/layouts/MainLayout";
import store from "@/store";

const changeSupplier = () => {
  store.dispatch("changeMenu", "borrower_supplier");
};

const changeDistributor = () => {
  store.dispatch("changeMenu", "borrower_distributor");
};

const supplier = [
  {
    path: "/permohonan-borrower/supplier/verifikasi-data",
    name: "borrower.supplier.data_verification.index",
    component: () => import("@/views/borrower/data_verification/SupplierView"),
    meta: {
      layout: MainLayout,
      pageTitle: "Verifikasi Data",
    },
    beforeEnter: changeSupplier,
  },
  {
    path: "/permohonan-borrower/supplier/verifikasi-data/:id/legal",
    name: "borrower.supplier.data_verification.legal",
    component: () => import("@/views/borrower/data_verification/LegalView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/permohonan-borrower/supplier/verifikasi-data/:id/individual",
    name: "borrower.supplier.data_verification.individual",
    component: () =>
      import("@/views/borrower/data_verification/IndividualView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/permohonan-borrower/supplier/verifikasi-data/:id/individual/summary",
    name: "borrower.supplier.data_verification.individual.summary",
    component: () =>
      import("@/views/borrower/data_verification/SummaryView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/permohonan-borrower/supplier/verifikasi-data/:id/individual/summary-decline",
    name: "borrower.supplier.data_verification.individual.summary_decline",
    component: () =>
      import("@/views/borrower/data_verification/SummaryDeclineView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/permohonan-borrower/supplier/data_verification/:id/approval",
    name: "borrower.supplier.data_verification.approval",
    component: () =>
      import("@/views/borrower/data_verification/ApprovalView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/permohonan-borrower/supplier/verifikasi-komite",
    name: "borrower.supplier.credit_verification.index",
    component: () =>
      import("@/views/borrower/credit_verification/SupplierView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Verifikasi Komite Kredit",
    },
    beforeEnter: changeSupplier,
  },
  {
    path: "/permohonan-borrower/supplier/verifikasi-komite/:id",
    name: "borrower.supplier.credit_verification.show",
    component: () =>
      import("@/views/borrower/credit_verification/ShowView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/permohonan-borrower/supplier/loan-limit",
    name: "borrower.supplier.loan_limit.index",
    component: () => import("@/views/borrower/loan_limit/SupplierView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Loan Limit",
    },
    beforeEnter: changeSupplier,
  },
  {
    path: "/permohonan-borrower/supplier/loan-limit/:id",
    name: "borrower.supplier.loan_limit.show",
    component: () => import("@/views/borrower/loan_limit/ShowView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
];

const distributor = [
  {
    path: "/permohonan-borrower/distributor/verifikasi-data",
    name: "borrower.distributor.data_verification.index",
    component: () =>
      import("@/views/borrower/data_verification/DistributorView"),
    meta: {
      layout: MainLayout,
      pageTitle: "Verifikasi Data",
    },
    beforeEnter: changeDistributor,
  },
  {
    path: "/permohonan-borrower/distributor/verifikasi-data/:id/legal",
    name: "borrower.distributor.data_verification.legal",
    component: () => import("@/views/borrower/data_verification/LegalView.vue"),
    meta: {
      financingProgram: "distributor",
    },
  },
  {
    path: "/permohonan-borrower/distributor/verifikasi-data/:id/individual",
    name: "borrower.distributor.data_verification.individual",
    component: () =>
      import("@/views/borrower/data_verification/IndividualView.vue"),
    meta: {
      financingProgram: "distributor",
    },
  },
  {
    path: "/permohonan-borrower/distributor/verifikasi-data/:id/individual/summary",
    name: "borrower.distributor.data_verification.individual.summary",
    component: () =>
      import("@/views/borrower/data_verification/SummaryView.vue"),
    meta: {
      financingProgram: "distributor",
    },
  },
  {
    path: "/permohonan-borrower/distributor/verifikasi-data/:id/individual/summary-decline",
    name: "borrower.distributor.data_verification.individual.summary_decline",
    component: () =>
      import("@/views/borrower/data_verification/SummaryDeclineView.vue"),
    meta: {
      financingProgram: "distributor",
    },
  },
  {
    path: "/permohonan-borrower/distributor/data_verification/:id/approval",
    name: "borrower.distributor.data_verification.approval",
    component: () =>
      import("@/views/borrower/data_verification/ApprovalView.vue"),
    meta: {
      financingProgram: "distributor",
    },
  },
  {
    path: "/permohonan-borrower/distributor/verifikasi-komite",
    name: "borrower.distributor.credit_verification.index",
    component: () =>
      import("@/views/borrower/credit_verification/DistributorView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Verifikasi Komite Kredit",
    },
    beforeEnter: changeDistributor,
  },
  {
    path: "/permohonan-borrower/distributor/verifikasi-komite/:id",
    name: "borrower.distributor.credit_verification.show",
    component: () =>
      import("@/views/borrower/credit_verification/ShowView.vue"),
    meta: {
      financingProgram: "distributor",
    },
  },
  {
    path: "/permohonan-borrower/distributor/loan-limit",
    name: "borrower.distributor.loan_limit.index",
    component: () => import("@/views/borrower/loan_limit/DistributorView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Loan Limit",
    },
    beforeEnter: changeDistributor,
  },
  {
    path: "/permohonan-borrower/distributor/loan-limit/:id",
    name: "borrower.distributor.loan_limit.show",
    component: () => import("@/views/borrower/loan_limit/ShowView.vue"),
    meta: {
      financingProgram: "distributor",
    },
  },
];

export default [...supplier, ...distributor];
