<template>
  <span
    class="badge rounded-pill"
    :class="{ success: success, failed: !success }"
  >
    <span class="fw-bold" v-if="success">Success</span>
    <span class="fw-bold" v-else>Failed</span>
  </span>
</template>

<script>
export default {
  props: {
    success: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
span.badge.failed {
  background: #ed3151;
  border: 1px solid #f1f8e7;
  color: #f1f8e7;
}

span.badge.success {
  background: #f1f8e7;
  border: 1px dashed #74b50c;
  color: #74b50c;
}
</style>
