<template>
  <div class="d-flex align-items-center">
    <div class="card">
      <div class="card-body d-flex justify-content-between">
        <span class="label-style text-capitalize">{{ label }}</span>
        <span class="value-style text-capitalize text-end ms-3">{{
          value
        }}</span>
      </div>
    </div>

    <div v-if="isFestive" class="festive-label ms-3">Festive</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    isFestive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 8px;
  width: 420px;
}
.label-style {
  color: #b4b4b4;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.value-style {
  color: #1a1a1a;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.festive-label {
  background-color: #a45cff;
  border-radius: 100px;
  padding: 6px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  height: fit-content;
  width: fit-content;
}
</style>
