<template>
  <div class="card">
    <div class="card-body d-flex justify-content-between">
      <span class="text-white">Available Limit</span>
      <span class="text-white fw-bold">{{ rupiahFormat(limit) }}</span>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixin';

export default {
  mixins: [mixin],
  props: {
    limit: {
      type: [Number, String],
      default: 0
    }
  }
}
</script>

<style scoped>
.card {
  background-image: linear-gradient(to left,#003566, #0080F6);
}
</style>