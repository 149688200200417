import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// components
import FullContent from "@/components/layouts/FullContent";
import AuthLayout from "@/components/layouts/AuthLayout";
import TitleCard from "@/components/cards/TitleCard";
import AlertSuccess from "@/components/utility/AlertSuccess";
import AlertFailed from "@/components/utility/AlertFailed";
import FilterCard from "@/components/cards/FilterCard";
import DisbursementFilter from "@/components/form/DisbursementFilter";
import BaseModal from "@/components/modal/BaseModal";
import ModalAcceptance from "@/components/modal/Acceptance";
import CardAcceptance from "@/components/cards/Acceptance";
import ModalCloseIcon from "@/components/modal/CloseIconModal";
import TableAcceptance from "@/components/tables/Acceptance";
import ButtonModal from "@/components/modal/ButtonModal";
import ModalButton from "@/components/buttons/ModalButton";
import ModalPrompt from "@/components/modal/PromptModal";
import ListFlush from "@/components/utility/ListFlush";
import ListItem from "@/components/utility/ListItem";
import BalanceLimit from "@/components/cards/BalanceLimit";
import LabelRegular from "@/components/form/LabelRegular";
import BaseInput from "@/components/input/BaseInput";
import InputTextBorderless from "@/components/input/InputTextBorderless";
import InputSelect from "@/components/input/InputSelect";
import DropZone from "@/components/form/DropZone";
import DropZonePdf from "@/components/form/DropZonePdf";
import DropzoneCsv from "@/components/form/DropzoneCsv";
import InputSearch from "@/components/input/InputSearch";
import InputText from "@/components/input/InputText";
import ListInvoice from "@/components/utility/ListInvoice";
import DisbursmentFilter from "@/components/form/DisbursmentFilter";
import PrincipleFilter from "@/components/form/PrincipleFilter";
import ApprovedModal from "@/components/modal/Approved";
import DismissButton from "@/components/buttons/Dismiss";
import DeclinedModal from "@/components/modal/Declined";
import DeclineVerificationModal from "@/components/modal/DeclineVerificationModal.vue";
import DisbursmentModal from "@/components/modal/Disbursment";
import DisbursmentTable from "@/components/tables/DisbursmentTable";
import ModalLink from "@/components/utility/ModalLink";
import FileUpload from "@/components/input/FileUpload";
import DocumentCard from "@/components/cards/DocumentCard";
import TablePagination from "@/components/utility/TablePagination";
import CollectionFilter from "@/components/form/CollectionFilter";
import Badge from "@/components/utility/Badge";
import OverrideBadge from "@/components/utility/OverrideBadge";
import BorrowerBadge from "@/components/utility/BorrowerBadge";
import PrincipleBadge from "@/components/utility/PrincipleBadge";
import PaymentReportBadge from "@/components/utility/PaymentReportBadge";
import PaymentFilter from "@/components/form/PaymentFilter";
import IconInput from "@/components/input/IconInput";
import BorrowerFilter from "@/components/form/BorrowerFilter";
import Tab from "@/components/tab/Tab.vue";
import TabWrapper from "@/components/tab/TabWrapper.vue";
import ImageCard from "@/components/cards/ImageCard.vue";
import DocumentFilter from "@/components/form/DocumentFilter";
import BankAccountTable from "@/components/tables/BankAccount";
import RacLegalTable from "@/components/tables/RacCompanyResultTable.vue";
import RacIndividuTable from "@/components/tables/RacResultTable.vue";
import ProductAdjustmentTable from "@/components/tables/ProductAdjustmentTable.vue";
import RadioInput from "@/components/input/RadioInput.vue";
import InputCheckBox from "@/components/input/InputCheckBox.vue";
import CurrencyInput from "@/components/input/CurrencyInput.vue";
import AutoFillTextInput from "@/components/input/AutoFillTextInput.vue";
import CompanyInfoTable from "@/components/tables/CompanyInfo.vue";
import AddressTable from "@/components/tables/AddressTable.vue";
import CapitalTable from "@/components/tables/CapitalTable.vue";
import DocumentTable from "@/components/tables/CompanyDocument.vue";
import CompanyTypeTable from "@/components/tables/CompanyType.vue";
import SelectInput from "@/components/input/SelectInput.vue";
import ReasonModal from "@/components/modal/ReasonModal.vue";
import CalendarInput from "@/components/input/CalendarInput";
import RolesModal from "@/components/modal/RolesModal";
import RacTable from "@/components/tables/RacTable";
import Alert from "@/components/Alert";
import PasswordInput from "@/components/input/PasswordInput";
import Dropdown from "@/components/Dropdown";
import StepStepper from "@/components/StepStepper";
import ApplyMultipllePrincipleAlert from "@/components/utility/ApplyMultipllePrincipleAlert";
import Tooltip from "@/components/utility/TooltipIcon";
import SubmitButton from "@/components/buttons/SubmitButton";
import Filter from "@/components/Filter";
import PreviewImageModal from "@/components/modal/PreviewImageModal.vue";
import ContactUsModal from "@/components/modal/ContactUsModal.vue";
import SwitchToggle from "@/components/switch/SwitchToggle.vue";

import "bootstrap";
import "bootstrap/scss/bootstrap.scss";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Icon from "./components/atoms/Icon";
import {
  faUserSecret,
  faPlus,
  faCircleExclamation,
  faMagnifyingGlass,
  faEllipsis,
  faArrowLeftLong,
  faCheck,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faRotateLeft,
  faTrashCan,
  faCircleCheck,
  faTriangleExclamation,
  faBan,
  faPercent,
  faDownload,
  faCalendarDays,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBell,
  faCircle,
  faEye,
  faEyeSlash,
  faFilePdf,
  faFileExcel,
} from "@fortawesome/free-regular-svg-icons";
window.$ = window.jQuery = require("jquery");

import VueCountdown from "@chenfengyuan/vue-countdown";

library.add(faUserSecret);
library.add(faPlus);
library.add(faCircleExclamation);
library.add(faMagnifyingGlass);
library.add(faBell);
library.add(faCircle);
library.add(faEllipsis);
library.add(faEye);
library.add(faEyeSlash);
library.add(faArrowLeftLong);
library.add(faCheck);
library.add(faTimes);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faRotateLeft);
library.add(faTrashCan);
library.add(faCircleCheck);
library.add(faFilePdf);
library.add(faFileExcel);
library.add(faTriangleExclamation);
library.add(faBan);
library.add(faPercent);
library.add(faDownload);
library.add(faChevronDown);
library.add(faCalendarDays);
library.add(faEdit);

const app = createApp(App);

app.config.globalProperties.$filters = {
  splitPermission(permissions) {
    return permissions.join(", ");
  },
};

import Toaster from "@meforma/vue-toaster";
app.use(router);
app.use(store);
app.use(Toaster);

// components
app.component("date-picker", Datepicker);
app.component("font-awesome", FontAwesomeIcon);
app.component("Icon", Icon);
app.component("full-content", FullContent);
app.component("auth-layout", AuthLayout);
app.component("title-card", TitleCard);
app.component("alert-failed", AlertFailed);
app.component("alert-success", AlertSuccess);
app.component("filter-card", FilterCard);
app.component("disbursement-filter", DisbursementFilter);
app.component("base-modal", BaseModal);
app.component("modal-acceptance", ModalAcceptance);
app.component("card-acceptance", CardAcceptance);
app.component("modal-close-icon", ModalCloseIcon);
app.component("table-acceptance", TableAcceptance);
app.component("button-modal", ButtonModal);
app.component("modal-prompt", ModalPrompt);
app.component("list-flush", ListFlush);
app.component("list-item", ListItem);
app.component("card-balance-limit", BalanceLimit);
app.component("label-regular", LabelRegular);
app.component("base-input", BaseInput);
app.component("InputTextBorderless", InputTextBorderless);
app.component("InputSelect", InputSelect);
app.component("DropZone", DropZone);
app.component("DropZonePdf", DropZonePdf);
app.component("DropzoneCsv", DropzoneCsv);
app.component("InputSearch", InputSearch);
app.component("InputText", InputText);
app.component("list-invoice", ListInvoice);
app.component("disbursment-filter", DisbursmentFilter);
app.component("principle-filter", PrincipleFilter);
app.component("document-filter", DocumentFilter);
app.component("approved-modal", ApprovedModal);
app.component("dismiss-button", DismissButton);
app.component("declined-modal", DeclinedModal);
app.component("reason-modal", ReasonModal);
app.component("modal-button", ModalButton);
app.component("disbursment-modal", DisbursmentModal);
app.component("disbursment-table", DisbursmentTable);
app.component("decline-verification-modal", DeclineVerificationModal);
app.component("modal-link", ModalLink);
app.component("file-upload", FileUpload);
app.component("document-card", DocumentCard);
app.component("table-pagination", TablePagination);
app.component("collection-filter", CollectionFilter);
app.component("badge", Badge);
app.component("OverrideBadge", OverrideBadge);
app.component("BorrowerBadge", BorrowerBadge);
app.component("PrincipleBadge", PrincipleBadge);
app.component("PaymentReportBadge", PaymentReportBadge);
app.component("icon-input", IconInput);
app.component("payment-filter", PaymentFilter);
app.component("borrower-filter", BorrowerFilter);
app.component("tab-wrapper", TabWrapper);
app.component("tab", Tab);
app.component("image-card", ImageCard);
app.component("document-filter", DocumentFilter);
app.component("bank-account-table", BankAccountTable);
app.component("rac-legal-table", RacLegalTable);
app.component("rac-individu-table", RacIndividuTable);
app.component("product-adjustment-table", ProductAdjustmentTable);
app.component("InputCheckBox", InputCheckBox);
app.component("currency-input", CurrencyInput);
app.component("AutoFillTextInput", AutoFillTextInput);
app.component("company-info-table", CompanyInfoTable);
app.component("address-table", AddressTable);
app.component("capital-table", CapitalTable);
app.component("document-table", DocumentTable);
app.component("company-type-table", CompanyTypeTable);
app.component("select-input", SelectInput);
app.component("alert", Alert);
app.component("calendar-input", CalendarInput);
app.component("roles-modal", RolesModal);
app.component("rac-table", RacTable);
app.component("password-input", PasswordInput);
app.component("dropdown", Dropdown);
app.component("radio-input", RadioInput);
app.component("PreviewImageModal", PreviewImageModal);
app.component("ContactUsModal", ContactUsModal);
app.component("SwitchToggle", SwitchToggle);
app.component(VueCountdown.name, VueCountdown);

app.component("StepStepper", StepStepper);
app.component("ApplyMultipllePrincipleAlert", ApplyMultipllePrincipleAlert);
app.component("tooltip-icon", Tooltip);
app.component("SubmitButton", SubmitButton);
app.component("Filter", Filter);

app.mount("#app");
