<template>
  <div class="wrapper">
    <aside
      class="sidebar-container border-end border-sidebar min-vh-100 vh-100 overflow-auto position-fixed left-0 top-0 bg-default text-white"
    >
      <img
        src="@/assets/sidebar-top-left.svg"
        alt="sidebar logo"
        class="sidebar-top-left"
      />
      <img
        src="@/assets/sidebar-bottom-right.svg"
        alt="sidebar logo"
        class="sidebar-bottom-right"
      />
      <div class="logo position-relative z-10">
        <img src="@/assets/hibank-logo.svg" alt="hibank logo" />
      </div>
      <ul
        class="accordion position-relative navbar nav-menu d-flex flex-column align-items-start z-10"
        id="accordion-sidebar"
        style="list-style: none"
      >
        <li
          class="nav-item"
          v-if="parentPermissions.includes('user-management')"
        >
          <a
            class="text-decoration-none accordion-button collapsed"
            data-bs-toggle="collapse"
            href="#management-user"
            role="button"
            aria-expanded="false"
            aria-controls="management-user"
          >
            <img
              src="@/assets/icons/manajemen-user.svg"
              alt="user"
              class="me-2"
            />
            Manajemen User
          </a>
          <div
            class="accordion-collapse collapse"
            id="management-user"
            data-parent="#accordion-sidebar"
          >
            <div class="card card-body bg-transparent border-0">
              <ul>
                <template
                  v-for="(path, index) in menuLinks.userManagement"
                  :key="index"
                >
                  <li v-if="permissions.includes(path.permission)">
                    <router-link :to="path.directTo">{{
                      path.label
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item"
          v-if="parentPermissions.includes('manajemen-akun-anggota')"
        >
          <a
            class="text-decoration-none accordion-button collapsed"
            data-bs-toggle="collapse"
            href="#management-akun"
            role="button"
            aria-expanded="false"
            aria-controls="management-akun"
          >
            <img
              src="@/assets/icons/manajemen-akun-anggota.svg"
              alt="user"
              class="me-2"
            />
            Manajemen Akun Anggota
          </a>
          <div
            class="accordion-collapse collapse"
            id="management-akun"
            data-parent="#accordion-sidebar"
          >
            <div class="card card-body bg-transparent border-0">
              <ul>
                <template
                  v-for="(path, index) in menuLinks.accountManagement"
                  :key="index"
                >
                  <li v-if="permissions.includes(path.permission)">
                    <router-link :to="path.directTo">{{
                      path.label
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item"
          v-if="parentPermissions.includes('management-principle')"
        >
          <a
            class="text-decoration-none accordion-button collapsed"
            data-bs-toggle="collapse"
            href="#management-principal"
            role="button"
            aria-expanded="false"
            aria-controls="management-principal"
          >
            <img
              src="@/assets/icons/manajemen-principal.svg"
              alt="user"
              class="me-2"
            />
            Manajemen Principal
          </a>
          <div
            class="accordion-collapse collapse"
            id="management-principal"
            data-parent="#accordion-sidebar"
          >
            <div class="card card-body bg-transparent border-0">
              <ul>
                <template
                  v-for="(path, index) in menuLinks.managementPrincipal"
                  :key="index"
                >
                  <li v-if="permissions.includes(path.permission)">
                    <router-link :to="path.directTo">{{
                      path.label
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item"
          v-if="parentPermissions.includes('permohonan-principle')"
        >
          <a
            class="text-decoration-none accordion-button collapsed"
            data-bs-toggle="collapse"
            href="#principal-submission"
            role="button"
            aria-expanded="false"
            aria-controls="principal-submission"
          >
            <img src="@/assets/icons/permohonan.svg" alt="user" class="me-2" />
            Permohonan Principal
          </a>
          <div class="collapse" id="principal-submission">
            <div class="card card-body bg-transparent border-0">
              <ul>
                <template
                  v-for="(path, index) in menuLinks.principalApplication"
                  :key="index"
                >
                  <li v-if="permissions.includes(path.permission)">
                    <router-link :to="path.directTo">{{
                      path.label
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item"
          v-if="parentPermissions.includes('management-debitur')"
        >
          <a
            class="text-decoration-none accordion-button collapsed"
            data-bs-toggle="collapse"
            href="#management-debitur"
            role="button"
            aria-expanded="false"
            aria-controls="management-debitur"
          >
            <img
              src="@/assets/icons/manajemen-borrower.svg"
              alt="user"
              class="me-2"
            />
            Management Debitur
          </a>
          <div class="collapse" id="management-debitur">
            <div class="card card-body bg-transparent border-0">
              <ul>
                <template
                  v-for="(path, index) in menuLinks.managementBorrower"
                  :key="index"
                >
                  <li v-if="permissions.includes(path.permission)">
                    <router-link :to="path.directTo">{{
                      path.label
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item"
          v-if="parentPermissions.includes('permohonan-debitur-supplier')"
        >
          <a
            class="text-decoration-none accordion-button collapsed"
            data-bs-toggle="collapse"
            href="#borrower-supplier"
            role="button"
            aria-expanded="false"
            aria-controls="borrower-supplier"
          >
            <img
              src="@/assets/icons/permohonan-supplier.svg"
              alt="user"
              class="me-2"
            />
            Permohonan Supplier
          </a>
          <div class="collapse" id="borrower-supplier">
            <div class="card card-body bg-transparent border-0">
              <ul>
                <template
                  v-for="(path, index) in menuLinks.supplierApplication"
                  :key="index"
                >
                  <li v-if="permissions.includes(path.permission)">
                    <router-link :to="path.directTo">{{
                      path.label
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item"
          v-if="parentPermissions.includes('permohonan-debitur-distributor')"
        >
          <a
            class="text-decoration-none accordion-button collapsed"
            data-bs-toggle="collapse"
            href="#borrower-distributor"
            role="button"
            aria-expanded="false"
            aria-controls="borrower-distributor"
          >
            <img
              src="@/assets/icons/permohonan-distributor.svg"
              alt="user"
              class="me-2"
            />
            Permohonan Distributor
          </a>
          <div class="collapse" id="borrower-distributor">
            <div class="card card-body bg-transparent border-0">
              <ul>
                <template
                  v-for="(path, index) in menuLinks.distributorApplication"
                  :key="index"
                >
                  <li v-if="permissions.includes(path.permission)">
                    <router-link :to="path.directTo">{{
                      path.label
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item"
          :class="{ active: $route.path === '/disbursement' }"
          v-if="permissions.includes('disbursement/read')"
        >
          <router-link
            class="border-0 text-decoration-none text-white"
            to="/disbursement"
          >
            <img src="@/assets/icons/pencairan.svg" alt="user" class="me-2" />
            Pencairan
          </router-link>
        </li>
        <li
          class="nav-item"
          v-if="parentPermissions.includes('manajemen-hari-libur')"
        >
          <a
            class="text-decoration-none accordion-button collapsed"
            data-bs-toggle="collapse"
            href="#management-dayoff"
            role="button"
            aria-expanded="false"
            aria-controls="management-dayoff"
          >
            <img
              src="@/assets/icons/manajemen-hari-libur.svg"
              alt="user"
              class="me-2"
            />
            Manajemen Hari Libur
          </a>
          <div
            class="accordion-collapse collapse"
            id="management-dayoff"
            data-parent="#accordion-sidebar"
          >
            <div class="card card-body bg-transparent border-0">
              <ul>
                <template
                  v-for="(path, index) in menuLinks.dayoffManagement"
                  :key="index"
                >
                  <li v-if="permissions.includes(path.permission)">
                    <router-link :to="path.directTo">{{
                      path.label
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item" v-if="parentPermissions.includes('audit-log')">
          <a
            class="text-decoration-none accordion-button collapsed"
            data-bs-toggle="collapse"
            href="#audit-log"
            role="button"
            aria-expanded="false"
            aria-controls="audit-log"
          >
            <img src="@/assets/icons/audit-log.svg" alt="user" class="me-2" />
            Audit Log
          </a>
          <div class="collapse" id="audit-log">
            <div class="card card-body bg-transparent border-0">
              <ul>
                <template
                  v-for="(path, index) in menuLinks.auditLog"
                  :key="index"
                >
                  <li v-if="permissions.includes(path.permission)">
                    <router-link :to="path.directTo">{{
                      path.label
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item"
          v-if="parentPermissions.includes('monitoring-reporting')"
        >
          <a
            class="text-decoration-none accordion-button collapsed"
            data-bs-toggle="collapse"
            href="#reporting-monitoring"
            role="button"
            aria-expanded="false"
            aria-controls="reporting-monitoring"
          >
            <img src="@/assets/icons/reporting.svg" alt="user" class="me-2" />
            Reporting & Monitoring
          </a>
          <div class="collapse" id="reporting-monitoring">
            <div class="card card-body bg-transparent border-0">
              <ul>
                <template
                  v-for="(path, index) in menuLinks.reporting_monitoring"
                  :key="index"
                >
                  <li v-if="permissions.includes(path.permission)">
                    <router-link :to="path.directTo">{{
                      path.label
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </aside>
    <header
      class="px-4 border-bottom d-flex justify-content-start align-items-center bg-transparent gap-5"
    >
      <dropdown class="ms-auto">
        <template #label>
          <font-awesome icon="far fa-bell" size="xl" class="text-muted" />
        </template>
        <ul class="dropdown-menu">
          <li>
            <span class="dropdown-item">You haven't any notifications</span>
          </li>
        </ul>
      </dropdown>
      <p style="font-size: 13px" class="m-0">
        Hi, <span class="fw-bold text-muted">{{ user.full_name }}</span>
      </p>
      <dropdown>
        <template #label>
          <img src="@/assets/ic_hibank.svg" alt="user" />
        </template>
        <ul class="dropdown-menu">
          <li>
            <span class="dropdown-item" @click="logout" style="cursor: pointer"
              >Logout</span
            >
          </li>
        </ul>
      </dropdown>
    </header>
    <main class="p-3 d-flex flex-column overflow-auto">
      <div class="container-fluid">
        <breadcrumb
          :page="$route.meta.pageTitle"
          :breadcrumbs="$route.meta.breadcrumbs"
          :isBack="$route.meta.isBack"
        />
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb.vue";
import Dropdown from "../Dropdown.vue";
import SearchInput from "../SearchInput.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getParentRolename, getChildrenRolename } from "@/utils/helper";
import {
  collection,
  distributorApplication,
  managementBorrower,
  managementPrincipal,
  principalApplication,
  reporting_monitoring,
  supplierApplication,
  userManagement,
  accountManagement,
  dayoffManagement,
  auditLog,
} from "@/libs/menu";
import { api } from "@/libs/api";

export default {
  components: {
    Breadcrumb,
    Dropdown,
    SearchInput,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      menuLinks: {
        userManagement,
        accountManagement,
        managementPrincipal,
        managementBorrower,
        principalApplication,
        supplierApplication,
        distributorApplication,
        collection,
        reporting_monitoring,
        dayoffManagement,
        auditLog,
      },
      isDisbursment: false,
      isDocument: false,
      isVerificationPayment: false,
      parentPermissions: [],
      childPermissions: [],
      permissions: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.fullPath === "/disbursment") {
        this.isDisbursment = true;
      } else {
        this.isDisbursment = false;
      }

      if (to.fullPath === "/document_management") {
        this.isDocument = true;
      } else {
        this.isDocument = false;
      }

      if (to.fullPath === "/payment-verification") {
        this.isVerificationPayment = true;
      } else {
        this.isVerificationPayment = false;
      }
    },
  },
  methods: {
    ...mapActions("authModule", ["doLoggedOut"]),
    ...mapMutations("alertModule", ["SET_SHOW", "SET_MESSAGE"]),
    async logout() {
      const isLoggedOut = await this.doLoggedOut();
      if (isLoggedOut) {
        this.$router.replace("/login");
      }
    },
    updateData() {
      if (this.menu !== this.$route.fullPath) {
        this.isDisbursment = false;
      } else {
        this.isDisbursment = true;
      }
    },
  },
  computed: {
    ...mapGetters(["menu"]),
  },
  async mounted() {
    try {
      const user = await api.get("/me");

      const sanitizedUser = {
        id: user.data.data.id,
        full_name: user.data.data.full_name,
        role: user.data.data.role,
        created_at: user.data.data.created_at,
        last_login: user.data.data.last_login,
        deactivate_reason: user.data.data.deactivate_reason,
        last_change_password: user.data.data.last_change_password,
        divisi: user.data.data.divisi,
        jabatan: user.data.data.jabatan,
      };

      localStorage.setItem("user", JSON.stringify(sanitizedUser));

      const allPermissions = sanitizedUser.role.permissions;

      const arr = [];
      allPermissions.map((permission) =>
        arr.push(getParentRolename(permission.permission.name))
      );
      this.parentPermissions = [...new Set(arr)];
      const arr2 = [];
      allPermissions.map((permission) =>
        arr2.push(getChildrenRolename(permission.permission.name))
      );
      this.childPermissions = arr2;

      const arr3 = [];
      allPermissions.map((permission) => arr3.push(permission.permission.name));

      this.permissions = arr3;
    } catch (error) {
      const message = error.response.data.message;
      this.SET_MESSAGE(message);
      this.SET_SHOW(true);
    }
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-areas:
    "aside header"
    "aside main";
  grid-template-columns: 300px 1fr;
  grid-template-rows: 80px 1fr;
  height: 100vh;
}

aside {
  grid-area: aside;
  width: 300px;
  padding: 24px 30px;
}

header {
  grid-area: header;
  position: sticky;
}

main {
  grid-area: main;
}

aside .logo {
  padding: 10px;
}

.accordion-button {
  color: #fff !important;
}
.accordion-button.collapsed .show {
  color: #fff !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: transparent;
  color: #fff !important;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button:focus,
.accordion-button:active {
  box-shadow: none;
  color: #fff !important;
}

.btn:focus {
  box-shadow: none;
}

.disbursment-menu .accordion-button::after {
  display: none;
}

.nav-menu .nav-item {
  width: 100%;
  padding: 15px;
}
.nav-menu .nav-item a {
  display: flex;
  align-items: center;
  color: white !important;
}
.nav-menu .nav-item .card ul {
  list-style: none;
  padding: 0;
}
.nav-menu .nav-item .card ul li a {
  text-decoration: none;
  padding: 15px;
  color: #fff;
}
.nav-menu .nav-item.active,
a.router-link-active.router-link-exact-active {
  background-color: #228499;
  color: white !important;
  border-radius: 10px;
}
.nav-menu .nav-item .card {
  padding-right: 0;
  padding-left: 0;
}
.nav-menu .nav-item .accordion-button {
  padding: 0;
  background-color: transparent;
  color: #fff;
}
.border-sidebar {
  border-radius: 0 20px 20px 0;
}
.z-10 {
  z-index: 10;
}
.sidebar-top-left {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}
.sidebar-bottom-right {
  position: fixed;
  bottom: 0;
  left: 169px;
  z-index: 0;
  border-bottom-right-radius: 20px;
}

.card li:hover {
  background-color: #228499;
  border-radius: 8px;
}
</style>

<style>
.box-overflow,
.box-overflow-x {
  width: 100%;
  max-width: calc(100vw - 132px);
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .form-card {
    height: calc(100vh - 150px) !important;
    overflow: auto;
  }

  .form-card-right .card {
    height: calc(100vh - 196px) !important;
    overflow: auto;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .box-overflow {
    max-width: calc(100vw - 385px);
  }

  .box-overflow-x {
    max-width: calc(100vw - 155px);
  }
}

.sidebar-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.sidebar-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
