import { api } from "@/libs/api";

class RegistrationService {
  async retrieve() {
    return await api.get("/registration");
  }

  async verifyComiteePrincipal(payload) {
    return await api.post("/registration/principle/verify/committee", payload);
  }

  async verifyDataPrincipal(payload) {
    return await api.post("/registration/principle/verify/data", payload);
  }

  async show(registrationId) {
    return await api.get(`/registration/${registrationId}`);
  }

  async activate(registrationId) {
    return await api.post(`/registration/${registrationId}/active`);
  }

  async deactivate(registrationId) {
    return await api.post(`/registration/${registrationId}/deactivate`);
  }

  async override(registrationId) {
    return await api.post(`/registration/${registrationId}/override`);
  }

  async showRegistration(registrationId) {
    return await api.get(`/principle/detail/${registrationId}`);
  }

  async retrieveBorrowers(status = "active") {
    return await api.get(`/registration/borrower?status=${status}`);
  }

  async activateBorrower(borrowerId) {
    return await api.post(`/registration/borrower/${borrowerId}/active`);
  }

  async deactivateBorrower(borrowerId) {
    return await api.post(`/registration/borrower/${borrowerId}/deactivate`);
  }

  async overrideBorrower() {
    return await api.get("/registration/borrower?status=rejected");
  }

  async verifyComiteeBorrower({ isApproved, reason, registerId }) {
    return await api.post("/registration/borrower/verify/committee", {
      is_approved: isApproved,
      reason,
      register_id: registerId,
    });
  }

  async verifyLoanBorrower({
    interest,
    categoryProduct,
    fine,
    isApproved,
    loanLimit,
    maxTenor,
    reason,
    registerId,
    supplierCode,
  }) {
    return await api.post("/registration/borrower/verify/loan", {
      bunga: interest,
      category_product: categoryProduct,
      denda: fine,
      is_approved: isApproved,
      loan_limit: loanLimit,
      maximal_tenor: maxTenor,
      reason: reason,
      register_id: registerId,
      supplier_code: supplierCode,
    });
  }

  async verifyUploadBorrower({ document, isApproved, reason, registerId }) {
    return await api.post("/registration/borrower/verify/upload", {
      document,
      is_approved: isApproved,
      reason,
      register_id: registerId,
    });
  }

  async showBorrower(registerId) {
    return await api.get(`/registration/borrower/${registerId}/document`);
  }

  async documentBorrower(registerId) {
    return await api.get(`/registration/borrower/${registerId}`);
  }

  async verifyDataBorrower(payload) {
    return await api.post("/registration/borrower/verify/data", payload);
  }

  async retrievePricinples(status = "active") {
    return await api.get(`/registration/principle?status=${status}`);
  }

  async showPrinciple(principleId, status = "active") {
    const response = await api.get(`/registration/principle?status=${status}`);

    return response.data.data.items.find((principle) => {
      return principle.id === principleId;
    });
  }

  async uploadVerifyPrinciple(payload) {
    return await api.post("/registration/principle/verify/upload", payload);
  }

  async validateRegistrationType(payload) {
    return await api.post("/registration/validate-registration-type", payload);
  }
}

export default new RegistrationService();
