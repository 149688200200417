<template>
  <base-modal :modal-name="name">
    <template #content>
      <div class="text-center mb-5">
        <h3 class="fw-bold fs-4">{{ title }}</h3>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <span>Data Nasabah Disimpan</span>
        <span class="fw-bold">{{ fullDateFormat(createdAt) }}</span>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <span>Verifikasi Data Disetujui</span>
        <span class="fw-bold">{{ fullDateFormat(verifiedAt) }}</span>
      </div>
      <div class="d-flex justify-content-between mb-5">
        <span>Verifikasi Komite Ditolak</span>
        <span class="fw-bold"
          >{{ fullDateFormat(declinedAt) }}
          <tooltip-icon class="ms-2" :message="reason"
        /></span>
      </div>

      <div class="text-center">
        <dismiss-button
          @click="redirect"
          label="OK"
          color="warning"
          class="fw-bold w-75 mt-5"
        />
      </div>
    </template>
  </base-modal>
</template>

<script>
import mixin from "@/mixin";
import dayjs from "dayjs";

export default {
  mixins: [mixin],
  props: {
    name: {
      type: String,
      default: "declined",
    },
    title: {
      type: String,
      default: "",
    },
    createdAt: {
      type: Date,
      default: dayjs(),
    },
    verifiedAt: {
      type: String,
    },
    reason: {
      type: String,
      default: "",
    },
    redirect: {
      type: Function,
      default: () => {
        this.$router.push({
          name: "home",
        });
      },
    },
  },
  data() {
    return {
      declinedAt: dayjs(),
    };
  },
};
</script>
