export default [
  {
    path: "/login",
    name: "auth.login",
    component: () => import("@/views/auth/LoginView.vue"),
  },
  {
    path: "/forgot-password",
    name: "auth.forgot-password",
    component: () => import("@/views/auth/ForgotPasswordView.vue"),
  },
  {
    path: "/forgot-password-verification",
    name: "auth.forgot-password-verification",
    component: () => import("@/views/auth/ForgotPasswordVerificationView.vue"),
  },
  {
    path: "/activation",
    name: "auth.activation",
    component: () => import("@/views/auth/ActivationView.vue"),
  },
];
