<template>
  <base-modal :modal-name="name">
    <template #content>
      <div class="text-center">
        <h3 class="text-muted fw-bold">{{ title }}</h3>
        <p>{{ subtitle }}</p>
      </div>
      <div class="d-flex gap-3 mt-4">
        <dismiss-modal
          :disabled="onLoading"
          ref="close-prompt-modal"
          label="Batal"
          color="outline-warning"
          class="flex-grow-1 fw-bold text-dark"
        >
        </dismiss-modal>
        <button
          :disabled="onLoading"
          type="button"
          :class="`btn btn-${color} fw-bold flex-grow-1`"
          @click="activation"
        >
          {{ label }}
        </button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import DismissModal from "./DismissModal.vue";

export default {
  components: {
    BaseModal,
    DismissModal,
  },
  props: {
    param: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "warning",
    },
    label: {
      type: String,
      default: "",
    },
    action: {
      type: Function,
      default: () => {
        alert("action");
      },
    },
  },
  data() {
    return {
      onLoading: false,
    };
  },
  methods: {
    async activation() {
      this.onLoading = true;

      await this.action(this.param);
      this.onLoading = false;
    },
    closePrompt() {
      this.$refs["close-prompt-modal"].$el.click();
    },
  },
};
</script>
