<template>
  <div
    class="link-dark text-decoration-none d-flex align-items-center gap-2 cursor-pointer"
    :id="id ? id : label"
    data-bs-toggle="dropdown"
    data-bs-auto-close="outside"
    aria-expanded="false"
  >
    <div v-if="label">{{ label }}</div>

    <Icon name="ChevronDown" size="md" class="cursor-pointer" />
  </div>
  <ul
    class="dropdown-menu"
    :aria-labelledby="id ? id : label"
    v-if="type === 'checkbox'"
  >
    <li v-for="option in options" :key="option">
      <label
        :for="option"
        class="dropdown-item d-flex align-items-center gap-2 mb-0"
      >
        <input
          :id="option"
          type="checkbox"
          :name="option"
          :modelValue="optionsModel"
          :value="option"
          :checked="options.includes(option)"
          @change="() => updateReason(option)"
        />
        <span class="text-capitalize">{{
          option === "deactivate"
            ? "inactive"
            : option === "principle"
            ? "principal"
            : option === "borrower"
            ? "debitur"
            : option
        }}</span>
      </label>
    </li>
  </ul>

  <ul
    class="dropdown-menu"
    :aria-labelledby="id ? id : label"
    v-if="type === 'radio'"
  >
    <li v-for="option in options" :key="option">
      <label
        :for="id + option"
        class="dropdown-item d-flex align-items-center gap-2 mb-0"
      >
        <input
          :id="id + option"
          type="radio"
          :name="option"
          :value="option"
          :checked="
            (option === 'terdekat' &&
              optionsModel === 'desc' &&
              orderBy === orderValue) ||
            (option === 'terlama' &&
              optionsModel === 'asc' &&
              orderBy === orderValue)
          "
          @change="() => updateReason(option)"
        />
        <span class="text-capitalize">{{ option }}</span>
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Filter",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "checkbox",
    },
    options: {
      type: Array,
      default: () => [
        "all",
        "disbursed",
        "paid",
        "rejected",
        "overdue",
        "expired",
      ],
    },
    optionsModel: {
      type: [Array, String],
    },
    orderBy: {
      type: String,
    },
    orderValue: {
      type: String,
    },
  },

  methods: {
    updateReason(value) {
      this.$emit("setFilter", `${value}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-item {
  cursor: pointer;
  border-bottom: 1px solid #00697f;

  &:hover {
    background-color: #00697f;
    color: #fff;
  }
}
</style>
