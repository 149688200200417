<template>
  <div class="input-group rounded border">
    <button class="text-muted bg-transparent border-0" type="button">
      <font-awesome icon="magnifying-glass" size="lg" />
    </button>
    <input type="text" class="form-control border-0 py-2" :placeholder="placeholder" aria-label="Example text with button addon" aria-describedby="button-addon1">
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String
    }
  }
}
</script>

<style scoped>
::placeholder {
  font-size: 15px;
}
</style>