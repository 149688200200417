<template>
  <table class="table table-bordered table-striped">
    <caption>
      Rincian Nomor
    </caption>
    <tbody>
      <tr v-if="licenseNumber">
        <td>Nomor Izin Berusaha</td>
        <th scope>{{ licenseFormat(licenseNumber) }}</th>
      </tr>
      <tr v-if="skdpNumber">
        <td>Nomor SKDP (Surat Keterangan Domisili Perusahaan)</td>
        <th scope>{{ licenseFormat(skdpNumber) }}</th>
      </tr>
      <tr v-if="isOpenCompany">
        <td>Perusahaan Terbuka</td>
        <th scope>{{ isOpenCompany ? "Ya" : "Tidak" }}</th>
      </tr>
      <tr v-if="taxNumber">
        <td>Nomor NPWP</td>
        <th scope>{{ taxFormat(taxNumber) }}</th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: ["taxNumber", "isOpenCompany", "skdpNumber", "licenseNumber"],
};
</script>

<style scoped>
th,
td {
  width: 50%;
  padding: 8px 16px;
  height: 56px;
  vertical-align: middle;
}
</style>
