import { api } from "@/libs/api";

class DayoffService {
  async getHolidays(payload) {
    return await api.get("/holidays", {
      params: payload,
    });
  }

  async getHolidaysApproval(payload) {
    return await api.get("/holidays/approval", {
      params: payload,
    });
  }

  async createHolidaysApproval(payload) {
    return await api.post("holidays/approval", payload);
  }

  async deleteHolidaysApproval(payload) {
    return await api.delete("holidays/approval", {
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  async approveHolidaysApproval(payload) {
    return await api.put("holidays/approval/approve", payload);
  }

  async rejectHolidaysApproval(payload) {
    return await api.put("holidays/approval/reject", payload);
  }

  async getHolidaysApprovalHistories(payload) {
    return await api.get("/holidays/approval/riwayat", {
      params: payload,
    });
  }

  async getHolidaysActivities(payload) {
    return await api.get("/holidays/aktivitas-perubahan", {
      params: payload,
    });
  }

  async uploadHolidayApproval(payload) {
    return await api.post("/holidays/approval/uploads", payload);
  }

  async getHolidaysApprovalbyId(id) {
    return await api.get(`/holidays/approval/${id}`);
  }

  async getHolidayTemplate() {
    return await api.get("/holidays/approval/template");
  }
}

export default new DayoffService();
