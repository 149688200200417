<template>
  <div>
    <div class="form-check">
      <input
        type="checkbox"
        @focus="onFocus"
        class="form-check-input"
        :id="id"
        @input="onInput"
        :checked="checked"
      />
      <label
        class="form-check-label"
        :class="isFocus ? 'color-primary' : 'color-gray'"
        :for="id"
        >{{ label }}</label
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "InputCheckBox",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: "checkID",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  methods: {
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    onInput(event) {
      this.$emit("update:modelValue", this.value);
    },
  },
};
</script>
