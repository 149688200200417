<template>
  <div
    @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave"
    @dragover.prevent
    @drop.prevent="handleDrop"
    :class="{ 'active-dropzone': active }"
    class="dropzone position-relative"
  >
    <label>
      <img
        v-if="image && !isPDF"
        :src="image"
        class="company-img rounded"
        alt="dropzone"
      />
      <div class="pdf-bg p-4" v-if="isPDF">
        <img
          v-if="isPDF"
          src="https://res.cloudinary.com/dldu47br2/image/upload/v1706172448/capio/hibank/uz3gdb1yxqyicwesxdk7.png"
          class="rounded cursor-pointer"
          alt="dropzone"
        />
        <br />
        <a
          class="ms-auto text-muted border-rounded cursor-pointer"
          @click="showPDfModal(image)"
        >
          <font-awesome icon="far fa-eye"></font-awesome>
        </a>
        <base-modal :modal-name="`show-pdf`" size="lg" :is-static="false">
          <template #content>
            <div class="text-center" style="height: 100vh">
              <vue-pdf-app :pdf="pdfUrl" :pageScale="pageScale"></vue-pdf-app>
            </div>
            <div class="text-center mt-5">
              <dismiss-modal
                label="OK"
                color="warning"
                class="fw-bold text-dark mt-5"
              >
              </dismiss-modal>
            </div>
          </template>
        </base-modal>
      </div>
    </label>

    <label :for="id">
      <div v-show="!objectName">
        <Icon name="Upload" size="md" color="black" />
        <div class="text-md my-2">Letakan berkas disini atau</div>
        <div class="btn btn-warning cursor-pointer mb-2 px-4">Pilih Berkas</div>
        <div class="text-center">
          <small>Format : .jpg, .jpeg, .png, .pdf, maksimal 2MB</small>
        </div>
      </div>

      <div v-show="objectName" class="mt-4">
        <div class="btn btn-outline-warning cursor-pointer mb-2 px-4">Ubah</div>
      </div>
    </label>
    <input
      type="file"
      :id="id"
      class="dropzoneFile"
      @change="handleChange"
      accept="image/*, application/pdf"
      :required="required"
    />
    <div
      class="progress w-100 position-absolute bottom-0"
      style="border-top-left-radius: 0px; border-top-right-radius: 0px"
      v-show="percentage > 0 && percentageId === id"
    >
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        :aria-valuenow="percentage"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{
          width: `${percentage}%`,
        }"
        v-show="percentageId === id"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { Modal } from "bootstrap";
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";

export default {
  name: "DropZonePdf",
  setup(props) {
    const active = ref(false);

    const toggleActive = () => {
      active.value = !active.value;
    };

    const handleDragEnter = () => {
      active.value = true;
    };

    const handleDragLeave = () => {
      active.value = false;
    };

    const handleDrop = (event) => {
      event.preventDefault();
      active.value = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        props.handleChange({ target: { files } });
      }
    };

    const isPDF = computed(() => {
      return props.objectName ? props.objectName.endsWith(".pdf") : "";
    });

    return {
      active,
      toggleActive,
      handleDragEnter,
      handleDragLeave,
      handleDrop,
      isPDF,
    };
  },
  props: {
    id: {
      type: String,
    },
    objectName: {
      type: String,
      default: "",
    },
    handleChange: {
      type: Function,
    },
    percentage: {
      type: Number,
    },
    percentageId: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    image: {
      type: String,
      default: "",
    },
  },
  components: {
    VuePdfApp,
  },
  data() {
    return {
      pdfUrl: "",
      pageScale: "page-fit",
    };
  },
  methods: {
    showPDfModal(url) {
      this.pdfUrl = url;
      new Modal(`#show-pdf`).show();
    },
  },
};
</script>

<style scoped lang="scss">
.dropzone {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease all;
  background-color: #e9f7fa;
  border-radius: 16px;
  color: #b4b4b4;
  border: 0.5px dashed #0c80ef;
  label {
    transition: 0.3s ease all;
  }
  input {
    display: none;
  }
}

.company-img {
  width: 400px;
  height: 200px;
  object-fit: cover;
}

.active-dropzone {
  background-color: #e9f7fa;
  border-radius: 16px;
  color: #b4b4b4;
  border: 0.5px dashed #0c80ef;
}

.pdf-bg {
  border-radius: 9px;
  background: #f3f3f3;
}

.object-name {
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 16px;
  text-align: center;
  color: #1a1a1a;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}
</style>
