<template>
  <div class="input-group flex-nowrap">
    <input
      :type="isShow ? 'text' : 'password'"
      class="form-control"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      @blur="onBlur"
      @input="handleInput"
      @keypress="preventSpaceAndAsterisk"
      @keydown="preventSpaceAndAsterisk"
      @paste.prevent="onPaste"
      :maxlength="20"
      style="position: relative; z-index: 1"
    />

    <div
      class="d-flex align-items-center"
      style="position: relative; z-index: 4"
    >
      <div @click="showPassword" class="show-password">
        <font-awesome icon="far fa-eye-slash" v-show="!isShow"></font-awesome>
        <font-awesome icon="far fa-eye" v-show="isShow"></font-awesome>
      </div>
    </div>
  </div>
  <div class="input-errors" v-for="(error, index) of errors" :key="index">
    <div class="text-danger mt-2 text-sm">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    placeholder: {
      required: true,
      type: String,
    },
    modelValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onBlur: {
      type: Function,
    },
    errors: {
      type: Array,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    showPassword() {
      this.isShow = !this.isShow;
    },
    handleInput(event) {
      const trimmedValue = event.target.value.replace(/\s+/g, " ").trim();
      this.$emit("update:modelValue", trimmedValue);
    },
    preventSpaceAndAsterisk(event) {
      const disabledCharacters = [" ", "*", "#", "$", "&"];

      if (disabledCharacters.includes(event.key)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #b6b6b6;
}

.form-control {
  border-radius: 100px !important;
}

.form-control:focus {
  box-shadow: none;
}

.form-control,
.btn {
  padding: 0.75rem !important;
  font-size: 15px !important;
}

.input-group button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}

.show-password {
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
  z-index: 4;
}
</style>
