import { api } from "@/libs/api";

class AcceptanceService {
  async retrieve(payload) {
    return await api.get("/loans", {
      params: payload,
    });
  }

  async histories(payload) {
    return await api.get("/config/histories", {
      params: payload,
    });
  }

  async setMinimum(payload) {
    return await api.post("/config/minimum-acceptance", payload);
  }

  async getMinimum() {
    return await api.get("/config/minimum-acceptance");
  }

  async getFile(payload) {
    return await api.get(`/loans/${payload}/document`);
  }

  async reject(loanId, payload) {
    return await api.post(`/loans/${loanId}/reject`, payload);
  }

  async disbursed(loanId, payload) {
    return await api.post(`/loans/${loanId}/disbursed`, payload);
  }

  async getLoansBorrowersRequest(payload) {
    return await api.get("/loans/borrowers/approval", {
      params: payload,
    });
  }
  async getLoansBorrowersMaker(payload) {
    return await api.get("/loans/borrowers/maker", {
      params: payload,
    });
  }

  async getLoanInvoiceBorrowerMaker(payload) {
    return await api.post("/loans/borrower/invoices/maker", payload);
  }

  async getLoanInvoiceBorrowerRequest(payload) {
    return await api.post("/loans/borrower/invoices/approval", payload);
  }

  async submitBulkAcceptanceMaker(payload) {
    return await api.post("/loans/bulk/verify/acceptance/maker", payload);
  }

  async submitBulkAcceptanceRequest(payload) {
    return await api.post("/loans/bulk/verify/acceptance/approval", payload);
  }

  async rejectBulkAcceptance(payload) {
    return await api.post("/loans/bulk/reject", payload);
  }

  async getLoanBorrowerHistory(params) {
    return await api.get("/loans/borrowers/acceptance/histories", { params });
  }

  async getLoanBorrowerRequestHistory(params) {
    return await api.get("/loans/borrowers/approval/history", { params });
  }

  async getLoanInvoiceHistoryDetail(loan_acceptance_history_id) {
    return await api.get(
      `/loans/borrowers/acceptance/histories/${loan_acceptance_history_id}/details`
    );
  }

  async getLoanInvoiceRequestHistory(payload) {
    return await api.post("/loans/borrower/invoices/approval/history", payload);
  }
}

export default new AcceptanceService();
