<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="row g-3">
          <div class="col">
            <base-input
              placeholder="Cari nama badan usaha"
              v-model="filter.key"
              end-prefix="search"
            />
          </div>
          <div class="col"></div>
        </div>
      </div>
      <div class="col-md-3 offset-md-1 d-flex gap-3 justify-content-end">
        <button
          v-if="
            showButton &&
            this.isHaveAccess('management-debitur/daftar-debitur/create')
          "
          type="button"
          class="btn btn-outline-warning fw-bold outline-none shadow-none px-4"
          @click="navigate"
        >
          <font-awesome icon="plus" class="me-2 fw-bold" size="lg" />
          Tambah Debitur
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    acceptanceStatus: {
      type: String,
      default: "",
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    submit: {
      type: Function,
      default: () => {},
    },
    navigate: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      format: `${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()}`,
      filter: {
        key: "",
      },
    };
  },
  watch: {
    "filter.key"(newValue) {
      this.$emit("setKey", newValue);
      this.submit();
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #b6b6b6;
}

a {
  color: #00697f !important;
  text-decoration: none !important;
}

a:hover {
  color: #00697f !important;
  text-decoration: none !important;
}

.form-control,
.btn {
  padding: 0.75rem;
  font-size: 15px;
}
</style>
