<template>
  <form @submit.prevent="submit">
    <div class="row">
      <div class="col-md-8">
        <div class="row g-3">
          <div class="col-5">
            <base-input
              placeholder="Cari nama badan usaha"
              v-model="filter.key"
              end-prefix="search"
            />
          </div>
        </div>
      </div>
      <div
        class="col-md-3 offset-md-1 d-flex gap-3 justify-content-end"
        v-if="
          addPrinciple &&
          this.isHaveAccess('management-principle/daftar-principle/create')
        "
      >
        <button
          type="button"
          class="btn btn-outline-warning fw-bold outline-none shadow-none px-4"
          @click="navigate"
        >
          <font-awesome icon="plus" class="me-2 fw-bold" size="lg" />
          Tambah Principal
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    datePlaceholder: {
      type: String,
    },
    principleStatus: {
      type: String,
      default: "",
    },
    addPrinciple: {
      type: Boolean,
    },
    submit: {
      type: Function,
      default: () => {},
    },
    reset: {
      type: Function,
      default: () => {},
    },
    navigate: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      format: `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}`,
      filter: {
        principle_types: "",
        key: "",
        requested_at: "",
      },
    };
  },
  watch: {
    "filter.key"(newValue) {
      this.$emit("setKey", newValue);
      this.submit();
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #b6b6b6;
}

.form-control,
.btn {
  padding: 0.75rem;
  font-size: 15px;
}
</style>
