import moment from "moment/moment";

export function formatNominal(nominal) {
  let number_string = nominal.toString().replace(/[^,\d]/g, ""),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
  return rupiah;
}

export function formatNPWP(digits) {
  let number_string = digits.toString().replace(/[^,\d]/g, ""),
    split = number_string.split(","),
    sisa = split[0].length % 4,
    result = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{4}/gi);

  if (ribuan) {
    let separator = sisa ? "-" : "";
    result += separator + ribuan.join("-");
  }

  result = split[1] !== undefined ? result + "," + split[1] : result;
  return result;
}

export function delimiter(string) {
  let newStr = "";

  for (const str of string.split("_")) {
    newStr += `${str} `;
  }

  return newStr.trimEnd();
}

export function formatFullDateFromServer(string) {
  return moment(string, "YYYY-MM-DDThh:mm:ssZ").format("DD MMMM YYYY");
}

export function formatFilename(string) {
  let removeUnderScore = string.replace(/_/g, " ");

  let splitStr = removeUnderScore.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(" ");
}

export function getParentRolename(roleName) {
  const parentName = roleName.substr(0, roleName.indexOf("/"));
  return parentName ?? roleName;
}

export function getChildrenRolename(roleName) {
  const childrenName = roleName.split("/")[1];
  return childrenName ?? roleName;
}

export function getRoleAccess(roleName) {
  const childrenName = roleName.split("/")[2];
  return childrenName ?? roleName.split("/")[1];
}

export function getExtensionName(filename) {
  return filename.split(".").pop();
}

export function formatDate(date, format = "DD MMMM YYYY") {
  return moment(date).format(format);
}
