<template>
  <div>
    <input
      :id="id"
      :type="type"
      :value="modelValue"
      :maxlength="maxlength"
      :placeholder="[[placeholder]]"
      @input="updateInput"
      @focus="onFocus"
      @blur="onBlur"
      :disabled="disabled"
      class="form-input-borderless w-100"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
    },
  },
  name: "InputTextBorderless",
  data() {
    return {
      isFocus: false,
    };
  },
  methods: {
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    updateInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-input-borderless {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #b6b6b6;
  padding: 12px 0px;
  font-size: 18px;
  font-weight: bold;
  color: #535353;
  background-color: #fff;
  height: 43px;

  &::placeholder {
    color: #b6b6b6;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid #0c80ef;
  }
}
</style>
