<template>
  <ul style="list-style: none">
    <li
      class="list-group-item d-flex justify-content-between align-content-center"
    >
      <slot></slot>
    </li>
  </ul>
</template>

<style scoped>
li:nth-child(even) {
  background-color: #fafafd;
}

li {
  padding: 15px;
}
</style>
