import { api_shared } from "@/libs/api";

class AccountService {
  async inquiry(payload) {
    return await api_shared.post("/core/accounts/inquiry", payload);
  }

  async retrieve(params) {
    return await api_shared.get("/accounts", { params });
  }

  async update({ id, status }) {
    return await api_shared.post("/accounts", {
      id,
      status,
    });
  }
}

export default new AccountService();
