import { api, api_public, api_borrower } from "@/libs/api";

class MasterService {
  async getBusinessTypes() {
    return await api_public.get("/master/business-types");
  }

  async getAnnualIncomes() {
    return await api_public.get("/master/annual-incomes");
  }

  async getAddress() {
    return await api_public.get("/master/addresses");
  }

  async getSubAddress({ parentKode, levelWilayah }) {
    return await api_public.get(
      `/master/sub-addresses?parentKode=${parentKode}&levelWilayah=${levelWilayah}`
    );
  }

  async getResidenceDuration() {
    return await api_public.get("/master/residence-durations");
  }
  async getResidenceStatuses() {
    return await api_public.get("/master/residence-statuses");
  }
  async getEducationLevels() {
    return await api_public.get("/master/educations");
  }

  async getReligion() {
    return await api_public.get("/master/religions");
  }

  async getSources() {
    return await api_public.get("/master/sources");
  }

  async getRelationshipStatus() {
    return await api_public.get("/master/relationship-statuses");
  }

  async UploadFile(payload, config) {
    return await api.post("/upload", payload, config);
  }
  async getCutOffHour() {
    return await api_borrower.get("/loans/cut-off-hour");
  }
}

export default new MasterService();
