<template>
  <div>
    <label
      class="form-label fw-bold text-muted"
      v-if="label"
      style="font-size: 15px"
    >
      {{ label }}
      <span class="text-danger" v-if="required">*</span>
    </label>

    <select
      class="form-input form-select w-100"
      :placeholder="[[placeholder]]"
      :required="required"
      @focus="onFocus"
      @blur="
        () => {
          onBlur();
          handleBlur && handleBlur();
        }
      "
      @change="handleChange"
      :value="modelValue"
      :disabled="disabled"
    >
      <option value="" disabled>{{ placeholder }}</option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.name }}
      </option>
    </select>

    <div class="input-errors" v-for="(error, index) of errors" :key="index">
      <div class="text-danger mt-2 text-sm">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    placeholder: {
      type: String,
    },
    options: {
      type: [Array, null],
      required: true,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    handleChange: {
      type: Function,
    },
    handleBlur: {
      type: [Function, undefined],
    },
    label: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
    },
  },
  name: "InputSelect",
  data() {
    return {
      isFocus: false,
    };
  },
  methods: {
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select {
  padding: 0.75rem !important;
  font-size: 15px !important;
}
</style>
