import AcceptanceService from "@/services/acceptance";

const state = {
  acceptances: null,
  acceptancesRequest: null,
  acceptancesMaker: null,
  acceptancesInvoicesRequest: null,
  acceptancesInvoicesMaker: null,
  meta: null,
  histories: null,
  minimumAcceptance: 0,
  totalTransactions: 0,
};

const getters = {
  acceptances: (state) => state.acceptances,
  acceptancesRequest: (state) => state.acceptancesRequest,
  acceptancesMaker: (state) => state.acceptancesMaker,
  acceptancesInvoicesMaker: (state) => state.acceptancesInvoicesMaker,
  acceptancesInvoicesRequest: (state) => state.acceptancesInvoicesRequest,
  meta: (state) => state.meta,
  histories: (state) => state.histories,
  minimumAcceptance: (state) => state.minimumAcceptance,
  totalTransactions: (state) => state.totalTransactions,
};

const mutations = {
  SET_ACCEPTANCES(state, payload) {
    state.acceptances = payload;
  },
  SET_ACCEPTANCES_REQUEST(state, payload) {
    state.acceptancesRequest = payload;
  },
  SET_ACCEPTANCES_MAKER(state, payload) {
    state.acceptancesMaker = payload;
  },
  SET_LOAN_INVOICE_BORROWER_REQUEST(state, payload) {
    state.acceptancesInvoicesRequest = payload;
  },
  SET_LOAN_INVOICE_BORROWER_MAKER(state, payload) {
    state.acceptancesInvoicesMaker = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_HISTORIES(state, payload) {
    state.histories = payload;
  },
  SET_MINIMUM_ACCEPTANCE(state, payload) {
    state.minimumAcceptance = payload;
  },
  SET_TOTAL_TRANSACTIONS(state, payload) {
    state.totalTransactions = payload;
  },
};

const actions = {
  async retrieveAcceptances({ commit }, payload) {
    const response = await AcceptanceService.retrieve(payload);
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_ACCEPTANCES", response.data.data.items);
    commit("SET_META", metaPayload);
  },
  async retrieveAcceptancesRequest({ commit }, payload) {
    const response = await AcceptanceService.getLoansBorrowersRequest(payload);
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_ACCEPTANCES_REQUEST", response.data.data.items);
    commit("SET_META", metaPayload);
  },
  async retrieveAcceptancesMaker({ commit }, payload) {
    const response = await AcceptanceService.getLoansBorrowersMaker(payload);
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_ACCEPTANCES_MAKER", response.data.data.items);
    commit("SET_META", metaPayload);
  },
  async retrieveLoanInvoiceBorrowerRequest({ commit }, payload) {
    const response = await AcceptanceService.getLoanInvoiceBorrowerRequest(
      payload
    );
    commit("SET_LOAN_INVOICE_BORROWER_REQUEST", response.data.data);
  },
  async retrieveLoanInvoiceBorrowerMaker({ commit }, payload) {
    const response = await AcceptanceService.getLoanInvoiceBorrowerMaker(
      payload
    );
    commit("SET_LOAN_INVOICE_BORROWER_MAKER", response.data.data);
  },
  async retrieveHistories({ commit }, payload) {
    const response = await AcceptanceService.histories(payload);

    commit("SET_HISTORIES", response.data.data.items);
  },
  async getMinimum({ commit }) {
    const response = await AcceptanceService.getMinimum();

    commit(
      "SET_MINIMUM_ACCEPTANCE",
      response.data.data.data.minimum_acceptance
    );
  },
  setTotalTransactions({ commit }, payload) {
    commit("SET_TOTAL_TRANSACTIONS", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
