<template>
  <div v-if="show">
    <div
      class="popup-backdrop"
      @click="
        () => {
          !loading && handleClose();
          resetChecked();
        }
      "
    />

    <div class="popup-content">
      <div class="d-flex w-100 flex-column">
        <div class="d-flex justify-content-between">
          <h4 class="fw-bold">{{ title }}</h4>
          <button
            type="button"
            class="btn-close"
            @click="
              () => {
                handleClose();
                resetChecked();
              }
            "
          ></button>
        </div>

        <div class="d-flex justify-content-between align-items-center my-3">
          <div>
            Total Nilai Transaksi
            <strong>{{ rupiahFormat(totalTransaction) }}</strong>
          </div>

          <div
            class="d-flex align-items-center gap-2"
            v-if="totalTransaction !== 0"
          >
            <button
              type="button"
              class="btn btn-outline-warning fw-bold text-dark flex-fill outline-none shadow-none"
              @click="handleReject"
            >
              Tolak
            </button>
            <button
              type="button"
              class="btn btn-warning border-0 fw-bold flex-fill"
              @click="handleApprove"
            >
              Setujui
            </button>
          </div>
        </div>
      </div>

      <div class="overflow-auto mh-70vh">
        <div
          class="accordion mb-3"
          id="accordionPanelsStayOpenExample"
          v-for="(invoice, index) in table"
          :key="index"
        >
          <div class="accordion-item">
            <h2
              class="accordion-header"
              :id="'panelsStayOpen-headingOne' + index"
            >
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#panelsStayOpen-collapseOne' + index"
                aria-expanded="true"
                :aria-controls="'panelsStayOpen-collapseOne' + index"
              >
                <div
                  class="d-flex justify-content-between align-items-center w-100 me-2"
                >
                  <div class="fw-bold text-dark">
                    {{ invoice.borrower_name }}
                  </div>
                  <div
                    class="border border-secondary rounded py-1 px-3 d-flex justify-content-between gap-3 text-dark"
                  >
                    <div>
                      <div class="mb-2">Total Invoice</div>
                      <strong>{{ checks[index]?.total_invoice }}</strong>
                    </div>
                    <div>
                      <div class="mb-2">Total Amount Invoice</div>
                      <strong>{{
                        rupiahFormat(checks[index]?.total_amount_invoice)
                      }}</strong>
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <div
              :id="'panelsStayOpen-collapseOne' + index"
              class="accordion-collapse collapse show"
              :aria-labelledby="'panelsStayOpen-headingOne' + index"
            >
              <div class="overflow-scroll">
                <div class="d-flex flex-column w-100 overflow-auto">
                  <div class="d-flex w-100">
                    <div
                      class="w-50px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      <input
                        type="checkbox"
                        name="check-all"
                        class="mx-3"
                        :checked="
                          checks[index]?.total_invoice === invoice.loans.length
                        "
                      />
                    </div>
                    <div
                      class="w-50px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      No
                    </div>
                    <div
                      class="w-200px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      Nama Principal
                    </div>
                    <div
                      class="w-150px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      Tipe Financing
                    </div>
                    <div
                      class="w-175px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      Tanggal Pengajuan
                    </div>
                    <div
                      class="w-80px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      Tenor
                    </div>
                    <div
                      class="w-175px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      Jadwal Pencairan
                    </div>
                    <div
                      class="w-200px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      No. Invoice
                    </div>
                    <div
                      class="w-200px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      Nilai Invoice
                    </div>
                    <div
                      class="w-175px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      Jatuh Tempo Invoice
                    </div>
                    <div
                      class="w-200px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      Nilai Pinjaman
                    </div>
                    <div
                      class="w-200px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      Nilai Yang Diterima
                    </div>
                    <div
                      class="w-200px px-3 py-2 fw-bold bg-gray d-flex align-items-center"
                    >
                      Nilai Yang Harus Dibayarkan
                    </div>
                  </div>

                  <div
                    class="d-flex flex-column"
                    v-for="(loan, index2) in invoice.loans"
                    :key="index2"
                  >
                    <div class="d-flex">
                      <div class="w-50px px-3 py-2">
                        <input
                          type="checkbox"
                          name="check-all"
                          class="mx-3"
                          :disabled="
                            currentTime > cutOffHour &&
                            dateFormat(currentDate) ===
                              dateFormat(loan.disbursement_date_request)
                          "
                          :checked="
                            checkedData.find((item) => item === loan.id)
                          "
                          @change="(e) => handleSingleCheck(e, loan, index)"
                        />
                      </div>
                      <div class="w-50px px-3 py-2">
                        {{ index2 + 1 }}
                      </div>
                      <div class="w-150px px-3 py-2">
                        {{ invoice.borrower_code }}
                      </div>
                      <div class="w-200px px-3 py-2">
                        {{ loan.registration?.principle_name }}
                      </div>
                      <div class="w-150px px-3 py-2">
                        {{ loan.registration?.financing_program }}
                      </div>
                      <div class="w-175px px-3 py-2">
                        {{ dateFormat(loan.created_at) }}
                      </div>
                      <div class="w-80px px-3 py-2">{{ loan.tenor }}</div>
                      <div class="w-175px px-3 py-2">
                        {{ dateFormat(loan.disbursement_date_request) }}
                      </div>
                      <div class="w-200px px-3 py-2">
                        {{ loan.invoice?.invoice_no }}
                      </div>
                      <div class="w-200px px-3 py-2">
                        {{ rupiahFormat(loan.invoice?.invoice_nominal) }}
                      </div>
                      <div class="w-175px px-3 py-2">
                        {{ dateFormat(loan.invoice?.invoice_due_date) }}
                      </div>
                      <div class="w-200px px-3 py-2">
                        {{ rupiahFormat(loan.principal_value) }}
                      </div>
                      <div class="w-200px px-3 py-2">
                        {{ rupiahFormat(loan.disbursement_value) }}
                      </div>
                      <div class="w-200px px-3 py-2">
                        {{ rupiahFormat(loan.repayment_value) }}
                      </div>
                    </div>

                    <div
                      class="reason-row"
                      v-if="
                        checkedData.find((item) => item === loan.id) &&
                        isAprrover
                      "
                    >
                      <strong>Alasan Perubahan: </strong>
                      <span>{{
                        loan.approve_reason
                          ? loan.approve_reason
                          : loan.rejected_reason
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin";
import { mapActions, mapGetters } from "vuex";
import masterService from "@/services/master";

export default {
  mixins: [mixin],
  props: {
    show: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "Confirmation",
    },
    dataTable: {
      type: Array,
    },
    handleClose: {
      type: Function,
    },
    isAprrover: {
      type: Boolean,
    },
    reason: {
      type: String,
    },
    totalTransaction: {
      type: Number,
    },
  },
  data() {
    return {
      table: this.dataTable,
      checkedData: [],
      checks: [],
      currentDate: null,
      currentTime: null,
    };
  },
  async created() {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "Asia/Jakarta",
    };
    this.currentTime = new Date().toLocaleString([], options);
    this.currentDate = new Date().toISOString();
    const resCutOffHour = await masterService.getCutOffHour();
    this.cutOffHour = resCutOffHour.data.data.loan_cut_off_hour;
  },
  methods: {
    ...mapActions("acceptanceModule"),
    handleSingleCheck(event, loan, index) {
      if (event.target.checked) {
        this.checkedData = [...this.checkedData, loan.id];

        let arr = this.checks;
        arr[index].total_invoice = arr[index].total_invoice + 1;
        arr[index].total_amount_invoice =
          arr[index].total_amount_invoice + loan.invoice.invoice_nominal;

        this.$emit(
          "setTotalTransaction",
          this.totalTransaction + loan.invoice.invoice_nominal
        );
      } else {
        const filtered = this.checkedData.filter((item) => item !== loan.id);
        this.checkedData = filtered;

        let arr = this.checks;
        arr[index].total_invoice = arr[index].total_invoice - 1;
        arr[index].total_amount_invoice =
          arr[index].total_amount_invoice - loan.invoice.invoice_nominal;

        this.$emit(
          "setTotalTransaction",
          this.totalTransaction - loan.invoice.invoice_nominal
        );
      }
    },
    handleApprove() {
      this.$emit("handleSubmitAcceptance");
    },
    handleReject() {
      this.$emit("handleReject");
    },
  },
  computed: {
    ...mapGetters("acceptanceModule"),
  },
  watch: {
    dataTable(newValue) {
      this.checkedData = [];
      this.table = newValue;
      this.checks = newValue.map((item) => {
        return {
          total_invoice: 0,
          total_amount_invoice: 0,
          loans: item.loans,
        };
      });
    },
    checkedData(newValue) {
      if (newValue.length === 0) {
        this.$emit("setTotalTransaction", 0);
      }
      this.$emit("setLoanIds", newValue);
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
}

input[type="checkbox"] {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
}

.accordion-button:focus {
  box-shadow: none;
}

.popup-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1056;
  transition: opacity 0.15s linear;
}

.popup-close {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 1057;

  cursor: pointer;
}

.popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1057;
  transform: translate(-50%, -50%);

  background-color: white;
  width: 80vw;
  max-height: 90vh;
  border-radius: 16px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
  padding: 40px;
}

.popup-title {
  font-size: 24px;
  color: #4f4f4f;
  text-align: center;
  font-weight: 700;
}

.popup-description {
  font-size: 15px;
  color: #4f4f4f;
  text-align: center;
}

.top-row {
  display: table;
  width: 100%;
}

th {
  padding: 0 !important;
}

td {
  padding: 0 !important;
}

.top-row > div {
  display: table-cell;
  width: auto;
  border-bottom: 1px solid #eee;
}

.w-50px {
  min-width: 50px;
  max-width: 50px;
}

.w-80px {
  min-width: 80px;
  max-width: 80px;
}
.w-100px {
  min-width: 100px;
  max-width: 100px;
}

.w-125px {
  min-width: 125px;
  max-width: 125px;
}

.w-150px {
  min-width: 150px;
  max-width: 150px;
}

.w-175px {
  min-width: 175px;
  max-width: 175px;
}

.w-200px {
  min-width: 200px;
  max-width: 200px;
}
.bg-gray {
  background-color: #e1e3e5;
}

.reason-row {
  background-color: #fff9de;
  padding: 10px 20px;
  width: 2205px;
}

.mh-70vh {
  max-height: 70vh;
}
</style>
