import SupplierService from '@/services/supplier'

const state = {
  suppliers: [],
  meta: null
}

const getters = {
  suppliers: state => state.suppliers
}

const mutations = {
  SET_SUPPLIERS (state, payload) {
    state.suppliers = payload
  }
}

const actions = {
  async retrieveSupplier ({commit}, payload = 'waiting') {
    const response = await SupplierService.retrieve(payload)

    commit('SET_SUPPLIERS', response.data.data.items)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}