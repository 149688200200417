import { api } from "@/libs/api";

class RoleService {
  async retrieve(params) {
    return await api.get("/roles", { params });
  }

  async store({ name, permissions, reason  }) {
    return await api.post("/roles", {
      name,
      permissions,
      reason
    });
  }

  async update(id, { name, permissions, reason }) {
    return await api.put(`/roles/${id}`, {
      name,
      permissions,
      reason
    });
  }
}

export default new RoleService();
