import { api } from "@/libs/api";

class DocumentService {
  async getDocuments(params) {
    return await api.get("/documents", { params });
  }

  async renewDocuments(doc_id, payload) {
    return await api.post(`/documents/${doc_id}/renew`, payload);
  }
}

export default new DocumentService();
