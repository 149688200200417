import RoleChangeService from "@/services/roleChange";

let state = {
  roleChanges: null,
  roleChangesHistories: null,
  meta: null,
};

let getters = {
  roleChanges: (state) => state.roleChanges,
  roleChangesHistories: (state) => state.roleChangesHistories,
  countRoleChange: (state) => state.roleChanges?.length,
  meta: (state) => state.meta,
};

let mutations = {
  SET_ROLECHANGE(state, payload) {
    state.roleChanges = payload;
  },
  SET_ROLECHANGE_ADDED(state, payload) {
    state.roleChanges.push(payload);
  },
  SET_ROLECHANGEL_HISTORIES(state, payload) {
    state.roleChangesHistories = payload;
  },
  DELETE_ROLECHANGE(state) {
    state.roleChanges = null;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

let actions = {
  async getRolesApproval({ commit }, params) {
    const response = await RoleChangeService.getRolesApproval(params);

    commit("SET_ROLECHANGE", response.data.data.items);

    let metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META", metaPayload);
  },

  async getRoleChangeHistories({ commit }, params) {
    const response = await RoleChangeService.getRoleChangeHistories(params);

    commit("SET_ROLECHANGEL_HISTORIES", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
