<template>
  <span
    v-if="message"
    data-bs-toggle="tooltip"
    data-bs-placement="right"
    data-bs-custom-class="custom-tooltip"
    :data-bs-title="message"
  >
    <font-awesome icon="circle-exclamation"></font-awesome>
  </span>
</template>

<script>
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm";

export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },
  mounted() {
    Array.from(
      document.querySelectorAll('span[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));
  },
};
</script>

<style scoped>
.custom-tooltip {
  --bs-tooltip-bg: var(--bs-primary);
}

.tooltip {
  z-index: 100000000;
}
</style>
