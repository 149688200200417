import AccountService from "@/services/account";

let state = {
  accounts: null,
  meta: null,
};

let getters = {
  accounts: (state) => state.accounts,
  countAccounts: (state) => state.accounts?.length,
  meta: (state) => state.meta,
};

let mutations = {
  SET_ACCOUNT(state, payload) {
    state.accounts = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

let actions = {
  async retrieveAccounts({ commit }, params) {
    const response = await AccountService.retrieve(params);

    commit("SET_ACCOUNT", response.data.data.items);

    let metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META", metaPayload);
  },

  async updateStatus({ commit }, payload) {
    const response = await AccountService.retrieve(payload);

    if (response.success) {
      this.retrieveAccounts();

      return true;
    }

    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
