<template>
  <transition name="fade">
    <div class="overlay">
      <div
        class="alert alert-failed alert-dismissible fade show d-flex align-items-center"
        role="alert"
      >
        <font-awesome icon="triangle-exclamation" class="text-danger" />
        <div class="alert-text ms-2">{{ message }}</div>

        <font-awesome
          icon="close"
          class="text-danger close-btn"
          size="lg"
          @click="handleClose"
        />
        <slot name="close"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "-",
    },
    show: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
    },
  },
};
</script>

<style scoped>
.alert {
  border: 1px dashed #dc2626;
  background-color: #f8d4d4;
  width: 50%;
  margin: 0 auto;
  top: 38%;
}

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1070;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.alert-text {
  color: #dc2626;
  font-weight: 600;
}

.close-btn {
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
}
</style>
