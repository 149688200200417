import { api } from "@/libs/api";

class AuditService {
  async getAuditBackoffice(payload) {
    return await api.get("/audit/backoffice", {
      params: payload,
    });
  }

  async getAuditBorrower(payload) {
    return await api.get("/audit/borrower", {
      params: payload,
    });
  }

  async getAuditPrinciple(payload) {
    return await api.get("/audit/principal", {
      params: payload,
    });
  }

  async getAuditBackofficeExcel(params) {
    return api.get("/audit/backoffice/excel", {
      params,
      responseType: "blob",
    });
  }

  async getAuditBorrowerExcel(params) {
    return api.get("/audit/borrower/excel", {
      params,
      responseType: "blob",
    });
  }

  async getAuditPrincipalExcel(params) {
    return api.get("/audit/principal/excel", {
      params,
      responseType: "blob",
    });
  }
}

export default new AuditService();
