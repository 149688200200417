<template>
  <transition name="fade">
    <div class="overlay">
      <div
        :class="`alert alert-${type} border-${type} alert-dismissible fade show d-flex align-items-center`"
        role="alert"
      >
        <font-awesome :icon="icon" :class="`text-${type}`" />
        <div class="alert-text ms-2">{{ message }}</div>
        <font-awesome
          icon="close"
          :class="`text-${type} close-btn`"
          size="lg"
          @click="handleClose"
        />
        <slot name="close"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "-",
    },
    show: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
    },
    type: {
      type: String,
      default: "success",
    },
  },
  computed: {
    icon() {
      let icon;

      switch (this.type) {
        case "danger":
          icon = "triangle-exclamation";
          break;
        case "success":
          icon = "circle-check";
          break;
        default:
          icon = "circle-check";
          break;
      }

      return icon;
    },
  },
};
</script>

<style scoped>
.alert {
  border-style: dashed;
  border-width: 1px;
  width: 50%;
  margin: 0 auto;
  top: 10%;
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1070;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.close-btn {
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
}
</style>
