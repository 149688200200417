import ReportService from "@/services/reporting";

const state = {
  limit_principle: null,
  limit_borrower: null,
  principle_facilities: null,
  borrower_facilities: null,
  pengajuan: null,
  payments: [],
  creations: null,
  meta: null,
};

const getters = {
  limit_principle: (state) => state.limit_principle,
  limit_borrower: (state) => state.limit_borrower,
  principle_facilities: (state) => state.principle_facilities,
  borrower_facilities: (state) => state.borrower_facilities,
  pengajuan: (state) => state.pengajuan,
  payments: (state) => state.payments,
  creations: (state) => state.creations,
  meta: (state) => state.meta,
};

const mutations = {
  SET_LIMIT_PRINCIPLE(state, payload) {
    state.limit_principle = payload;
  },
  SET_LIMIT_BORROWER(state, payload) {
    state.limit_borrower = payload;
  },
  SET_PRINCIPLE_FACILITIES(state, payload) {
    state.principle_facilities = payload;
  },
  SET_BORROWER_FACILITIES(state, payload) {
    state.borrower_facilities = payload;
  },
  SET_PENGAJUAN(state, payload) {
    state.pengajuan = payload;
  },
  SET_PAYMENTS(state, payload) {
    state.payments = payload;
  },
  SET_CREATIONS(state, payload) {
    state.creations = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

const actions = {
  async getLimitPrinciple({ commit }, params) {
    const response = await ReportService.GetReportPrinciple(params);

    commit("SET_LIMIT_PRINCIPLE", response.data.data.items);
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META", metaPayload);
  },

  async getLimitBorrower({ commit }, params) {
    const response = await ReportService.GetReportBorrower(params);
    commit("SET_LIMIT_BORROWER", response.data.data.items);
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META", metaPayload);
  },

  async getPrincipleFacilities({ commit }, params) {
    const response = await ReportService.GetFacilitiesPrinciple(params);
    commit("SET_PRINCIPLE_FACILITIES", response.data.data.items);
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META", metaPayload);
  },

  async getBorrowerFacilities({ commit }, params) {
    const response = await ReportService.GetFacilitiesPinjaman(params);
    commit("SET_BORROWER_FACILITIES", response.data.data.items);
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META", metaPayload);
  },

  async getPengajuan({ commit }, params) {
    const response = await ReportService.GetPengajuan(params);
    commit("SET_PENGAJUAN", response.data.data.items);
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META", metaPayload);
  },
  async getPaymentReports({ commit }, payload) {
    try {
      const response = await ReportService.GetPaymentReports(payload);
      const metaPayload = {
        page: response.data.data.page,
        per_page: response.data.data.per_page,
        total_data: response.data.data.total_data,
        total_page: response.data.data.total_page,
      };

      commit("SET_META", metaPayload);
      commit("SET_PAYMENTS", response.data.data.items);
    } catch (error) {
      alert(error.response.data.message);
    }
  },
  async getLimitCreations({ commit }, payload) {
    try {
      const response = await ReportService.GetLimitCreationHistories(payload);
      const metaPayload = {
        page: response.data.data.page,
        per_page: response.data.data.per_page,
        total_data: response.data.data.total_data,
        total_page: response.data.data.total_page,
      };

      commit("SET_META", metaPayload);
      commit("SET_CREATIONS", response.data.data.items);
    } catch (error) {
      alert(error.response.data.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
