import DisbursementService from "@/services/disbursement";

const state = {
  disbursements: null,
  meta: null,
};

const getters = {
  disbursements: (state) => state.disbursements,
  meta: (state) => state.meta,
};

const mutations = {
  SET_DISBURSEMENTS(state, payload) {
    state.disbursements = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

const actions = {
  async getLoansNeedDisbursed({ commit }, payload) {
    const response = await DisbursementService.getLoanNeedDisbursed(payload);
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_DISBURSEMENTS", response.data.data.items);
    commit("SET_META", metaPayload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
