import MainLayout from "@/components/layouts/MainLayout";
import store from "@/store";

const changeMenuPrincipalApp = () => {
  store.dispatch("changeMenu", "principal_application");
};

const routes = [
  {
    path: "/permohonan-principal/verifikasi-data",
    name: "principal_application.data_verification.index",
    component: () =>
      import("@/views/principal_application/data_verification/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Verifikasi Data",
      breadcrumbs: [
        {
          name: "Permohonan Principal",
          url: "/",
        },
        {
          name: "Verifikasi Data",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuPrincipalApp,
  },
  {
    path: "/permohonan-principal/verifikasi-data/:id",
    name: "principal_application.data_verification.show",
    component: () =>
      import("@/views/principal_application/data_verification/ShowView.vue"),
  },
  {
    path: "/permohonan-principal/verifikasi-data/:id/summary",
    name: "principal_application.data_verification.summary",
    component: () =>
      import("@/views/principal_application/data_verification/SummaryView.vue"),
  },
  {
    path: "/permohonan-principal/verifikasi-data/:id/summary-decline",
    name: "principal_application.data_verification.summary_decline",
    component: () =>
      import(
        "@/views/principal_application/data_verification/SummaryDeclineView.vue"
      ),
  },
  {
    path: "/permohonan-principal/verifikasi-data/:id/approval",
    name: "principal_application.data_verification.approval",
    component: () =>
      import(
        "@/views/principal_application/data_verification/ApprovalView.vue"
      ),
  },
  {
    path: "/permohonan-principal/verifikasi-komite",
    name: "principal_application.credit_verification.index",
    component: () =>
      import("@/views/principal_application/credit_verification/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Verifikasi Komite Kredit",
      breadcrumbs: [
        {
          name: "Permohonan Principal",
          url: "/",
        },
        {
          name: "Verifikasi Komite Kredit",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuPrincipalApp,
  },
  {
    path: "/permohonan-principal/verifikasi-komite/:id",
    name: "principal_application.credit_verification.show",
    component: () =>
      import("@/views/principal_application/credit_verification/ShowView.vue"),
  },
  {
    path: "/permohonan-principal/upload-dokumen",
    name: "principal_application.upload_document.index",
    component: () =>
      import("@/views/principal_application/upload_document/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Unggah Dokumen",
      breadcrumbs: [
        {
          name: "Permohonan Principal",
          url: "/",
        },
        {
          name: "Unggah Dokumen ",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuPrincipalApp,
  },
  {
    path: "/permohonan-principal/upload-dokumen/:id",
    name: "principal_application.upload_document.show",
    component: () =>
      import("@/views/principal_application/upload_document/ShowView.vue"),
  },
];

export default routes;
