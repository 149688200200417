import DayoffService from "@/services/dayoff";

const state = {
  dayoffs: null,
  dayoffsApproval: null,
  dayoffsApprovalHistories: null,
  dayoffsActivities: null,
  meta: null,
};

const getters = {
  dayoffs: (state) => state.dayoffs,
  dayoffsApproval: (state) => state.dayoffsApproval,
  dayoffsApprovalHistories: (state) => state.dayoffsApprovalHistories,
  dayoffsActivities: (state) => state.dayoffsActivities,
  meta: (state) => state.meta,
};

const mutations = {
  SET_DAYOFFS(state, payload) {
    state.dayoffs = payload;
  },
  SET_DAYOFFS_APPROVAL(state, payload) {
    state.dayoffsApproval = payload;
  },
  SET_DAYOFFS_APPROVAL_HISTORIES(state, payload) {
    state.dayoffsApprovalHistories = payload;
  },
  SET_DAYOFFS_ACTIVITIES(state, payload) {
    state.dayoffsActivities = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

const actions = {
  async getHolidays({ commit }, params) {
    const response = await DayoffService.getHolidays(params);

    commit("SET_DAYOFFS", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
  async getHolidaysApproval({ commit }, params) {
    const response = await DayoffService.getHolidaysApproval(params);

    commit("SET_DAYOFFS_APPROVAL", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
  async getHolidaysApprovalHistories({ commit }, params) {
    const response = await DayoffService.getHolidaysApprovalHistories(params);

    commit("SET_DAYOFFS_APPROVAL_HISTORIES", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
  async getHolidaysActivities({ commit }, params) {
    const response = await DayoffService.getHolidaysActivities(params);

    commit("SET_DAYOFFS_ACTIVITIES", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
