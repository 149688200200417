<template>
  <div
    class="text-md fw-500 mb-1"
    :class="active ? `color-primary` : `color-gray-2`"
  >
    {{ label }}
  </div>
  <div
    @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave"
    @dragover.prevent
    @drop.prevent="handleDrop"
    :class="{ 'active-dropzone': active }"
    class="dropzone h-100 w-100 position-relative d-flex justify-content-start align-items-center"
  >
    <label :for="id" class="my-auto">
      <div
        class="text-sm text-start justify-content-between d-flex p-3"
        v-show="objectName"
        :class="objectName ? `bg-white rounded ` : ``"
      >
        <div class="gap-3 d-flex text-gray-500">
          <FileTypeCSVIcon :class="objectName ? `` : `d-none`" />
          {{ objectName }}
        </div>
        <button
          type="button"
          :class="objectName ? `d-flex` : `d-none`"
          class="btn-close"
          ref="closeButton"
          @click="clearFile"
          aria-label="Close"
        ></button>
      </div>

      <div class="d-flex justify-content-center flex-column">
        <div>
          <Icon name="Upload" size="md" color="black" v-show="!objectName" />
        </div>
        <div v-show="!objectName" class="text-md text-gray-500 my-2">
          Tarik dan lepaskan berkas disini
        </div>
        <div class="d-flex justify-content-center" v-show="!objectName">
          <div
            v-show="!objectName"
            class="btn-warning text-center px-5 cursor-pointer mb-2 rounded-pill"
            :class="objectName ? `d-none` : ``"
          >
            Cari Berkas
          </div>
        </div>
        <small v-show="!objectName" class="text-center text-gray-500"
          >Format .csv , maksimal 2MB</small
        >
      </div>
    </label>

    <input
      type="file"
      :id="id"
      class="dropzoneFile"
      @change="handleChange"
      accept=".csv,text/csv"
    />
    <div
      class="progress w-100 position-absolute bottom-0"
      style="border-top-left-radius: 0px; border-top-right-radius: 0px"
      v-show="percentage > 0 && percentageId === id"
    >
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        :aria-valuenow="percentage"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{
          width: `${percentage}%`,
        }"
        v-show="percentageId === id"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import FileTypeCSVIcon from "@/components/icons/FileTypeCSVIcon.vue";

export default {
  name: "DropZone",
  setup(props, { emit }) {
    const active = ref(false);

    const toggleActive = () => {
      active.value = !active.value;
    };

    const handleDragEnter = () => {
      toggleActive();
    };

    const handleDragLeave = () => {
      toggleActive();
    };

    const handleDrop = (event) => {
      event.preventDefault();
      toggleActive();
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const fileName = files[0].name;
        emit("update:objectName", fileName);
        props.handleChange({ target: { files } });
      }
    };

    return {
      active,
      handleDragEnter,
      handleDragLeave,
      handleDrop,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
    },
    objectName: {
      type: String,
      default: "",
    },
    handleChange: {
      type: Function,
    },
    clearFile: {
      type: Function,
    },
    percentage: {
      type: Number,
    },
    percentageId: {
      type: String,
    },
  },
  components: { FileTypeCSVIcon },
};
</script>

<style scoped lang="scss">
.dropzone {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease all;
  background-color: #e9f7fa;
  border-radius: 16px;
  color: #b4b4b4;
  border: 0.5px dashed #0c80ef;
  label {
    transition: 0.3s ease all;
  }
  input {
    display: none;
  }
}
.active-dropzone {
  background-color: #e9f7fa;
  border-radius: 16px;
  color: #b4b4b4;
  border: 0.5px dashed #0c80ef;
}
</style>
