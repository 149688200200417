import PrincipleService from "@/services/principle";
import UploadService from "@/services/upload";

const state = {
  principles: [],
  principalChanges: [],
  principalChangesHistories: null,
  meta: null,
  principle: null,
  documents: [],
  directors: [],
  bankAccount: null,
  detail: null,
  verifyData: {
    is_all_data_valid: true,
    is_data_direktur_valid: null,
    is_informasi_perusahaan_valid: null,
    is_rekening_perusahaan_valid: null,
    nama_rekening: "",
    nomor_rekening_giro: "",
    reason: "",
    register_id: null,
  },
  disabledSubmit: null,
};

const getters = {
  principles: (state) => state.principles,
  principalChanges: (state) => state.principalChanges,
  principalChangesHistories: (state) => state.principalChangesHistories,
  meta: (state) => state.meta,
  principle: (state) => state.principle,
  documents: (state) => state.documents,
  directors: (state) => state.directors,
  bankAccount: (state) => state.bankAccount,
  verifyData: (state) => state.verifyData,
  detail: (state) => state.detail,
  disabledSubmit: (state) => {
    return (
      !state.verifyData.nama_rekening || !state.verifyData.nomor_rekening_giro
    );
  },
};

const mutations = {
  SET_PRINCIPLES(state, payload) {
    state.principles = payload;
  },
  SET_PRINCIPALCHANGES(state, payload) {
    state.principalChanges = payload;
  },
  SET_PRINCIPALCHANGES_HISTORIES(state, payload) {
    state.principalChangesHistories = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_PRINCIPLE(state, payload) {
    state.principle = payload;
  },
  ADD_DOCUMENT(state, payload) {
    state.documents.push(payload);
  },
  ADD_DIRECTOR(state, payload) {
    state.directors.push(payload);
  },
  SET_BANK_ACCOUNT(state, payload) {
    state.bankAccount = payload;
  },
  CLEAR_DOCUMENT(state) {
    state.documents = [];
  },
  CLEAR_DIRECTOR(state) {
    state.directors = [];
  },
  SET_VERIFY_DATA(state, { key, value }) {
    state.verifyData[key] = value;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
};

const actions = {
  async retrievePrinciple({ commit }, payload) {
    const response = await PrincipleService.retrieve(payload);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
    commit("SET_PRINCIPLES", response.data.data.items);
  },
  async detailPrinciple({ commit }, payload) {
    const response = await PrincipleService.detail(payload);
    const documents = response.data.data.registration.document;
    const bankAccount = response.data.data.registration.bank_account;
    const directors = response.data.data.registration.directors?.map(
      (director) => {
        return {
          director: director.fullname,
          ktp: director.ktp_photo,
        };
      }
    );
    if (directors) {
      for (const director of directors) {
        if (director["ktp"] != "") {
          const responseDoc = await UploadService.signUrl(director["ktp"]);
          commit("ADD_DIRECTOR", {
            filename: director["director"].split(" ").join("-"),
            document: responseDoc.data.data.signed_url,
          });
        }
      }
    }

    commit("SET_BANK_ACCOUNT", {
      name: bankAccount.giro_account_holder.trim(),
      number: bankAccount.giro_account_number,
    });
    commit("SET_PRINCIPLE", response.data.data);
  },

  async getPrincipalApprovals({ commit }, payload) {
    const response = await PrincipleService.getPrincipalApprovals(payload);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
    commit("SET_PRINCIPALCHANGES", response.data.data.items);
  },

  async getPrincipalChangeHistories({ commit }, params) {
    const response = await PrincipleService.getPrincipalChangeHistories(params);

    commit("SET_PRINCIPALCHANGES_HISTORIES", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },

  clearDocument({ commit }) {
    commit("CLEAR_DOCUMENT");
  },
  clearDirector({ commit }) {
    commit("CLEAR_DIRECTOR");
  },
  setVerifyData({ commit }, payload) {
    commit("SET_VERIFY_DATA", payload);
  },
  setDetail({ commit }) {
    commit("SET_DETAIL");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
