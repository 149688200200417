<template>
  <label
    class="form-label fw-bold text-muted"
    v-if="label"
    style="font-size: 15px"
  >
    {{ label }}
    <span class="text-danger" v-if="required">*</span>
    <slot name="label"></slot>
  </label>

  <select
    class="form-select"
    :class="additionalClass"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    @blur="
      () => {
        onBlur && onBlur();
      }
    "
    @change="(e) => handleChange && handleChange(e)"
  >
    <option value="" selected :disabled="disableEmptyValue">
      {{ placeholder }}
    </option>
    <option
      :value="option.value"
      v-for="(option, index) in options"
      :selected="modelValue === option.value"
      :key="index"
      :class="{ light: !isBold, dark: isBold }"
      :disabled="shouldDisableOption(option.value)"
    >
      {{ option.name }}
    </option>
  </select>
  <div class="input-errors" v-for="(error, index) of errors" :key="index">
    <div class="text-danger mt-2 text-sm">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      required: true,
      type: Array,
    },
    placeholder: {
      required: true,
      type: String,
    },
    modelValue: {
      type: [String, Number],
    },
    errors: {
      type: Array,
    },
    isBold: {
      type: Boolean,
      default: false,
    },
    disableDefaultValue: {
      type: Boolean,
      default: false,
    },
    additionalClass: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    onBlur: {
      type: Function,
    },
    handleChange: {
      type: Function,
    },
    disableEmptyValue: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    shouldDisableOption(value) {
      return value === "diskonto-a" || value === "diskonto-b";
    },
  },
};
</script>

<style lang="scss" scoped>
.light {
  color: #b6b6b6;
}

.dark {
  color: #3a3a3a;
}
.form-select {
  padding: 0.75rem !important;
  font-size: 15px !important;

  &::placeholder {
    color: #b4b4b4;
    text-align: left;
  }

  &:focus {
    outline: none;
    border: 1px solid #00697f;
  }

  &:disabled {
    opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
</style>
