<template>
  <div>
    <label
      for="file"
      class="text-center border border-1 p-4 w-100 rounded-1 bg-info"
      style="--bs-bg-opacity: 0.2"
    >
      <template v-if="loading">
        <div>
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="fw-bold">Uploading..</div>
        </div>
      </template>
      <template v-else>
        <img src="@/assets/icons/upload.svg" alt="upload" width="30" />
        <div class="text-md my-2">Letakan berkas disini atau</div>
        <div class="btn btn-warning btn-sm cursor-pointer fw-bold mb-2 rounded">
          {{ buttonText }}
        </div>
        <small class="d-block">Format : {{ format }}, maksimal 2MB</small>
      </template>
    </label>
    <input id="file" type="file" class="d-none" :accept="format" />

    <div class="input-errors" v-for="(error, index) of errors" :key="index">
      <div class="text-danger mt-2 text-sm">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: ".pdf",
    },
    errors: {
      type: Array,
    },
    buttonText: {
      type: String,
      default: "Pilih Berkas",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  name: "UploadFile",
  data() {
    return {
      isFocus: false,
    };
  },
  methods: {
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    onInput(e) {
      this.$emit("input", e.target.value);
    },
    onChange(e) {
      this.$emit("change", e);
    },
  },
};
</script>
