import { api } from "@/libs/api";

class UserChangeService {
  async getUsersApproval(params) {
    return await api.get("/users/approval", { params });
  }

  async approveUsersApproval(payload) {
    return await api.put("/users/approval/approve", payload);
  }

  async rejectUsersApproval(payload) {
    return await api.put("/users/approval/reject", payload);
  }

  async getUserChangeHistories(payload) {
    return await api.get("/users/approval/riwayat", {
      params: payload,
    });
  }

  async getUserChangebyId(id) {
    return await api.get(`/users/approval/${id}`);
  }

}

export default new UserChangeService();
