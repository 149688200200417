<template>
  <div class="stepper py-2">
    <div v-for="(step, index) in steps.slice(0, 2)" class="step" :key="index">
      <Icon :name="step.iconName" size="lg" />
      <p class="mt-3 title" :style="step.style">{{ step.label }}</p>
      <div v-if="index < 1" class="step-divider"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: Array,
    activateStep: Function,
  },
};
</script>

<style scoped lang="scss">
.stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.step {
  flex: 1;
  text-align: center;
  position: relative;
}

.step .step-divider {
  height: 1px;
  background-color: rgba(180, 180, 180, 0.5);
  position: absolute;
  top: 20%;
  left: 100%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
}

.title {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #686868;
}
</style>
