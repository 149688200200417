<template>
  <div class="card">
    <div class="card-body">
      <form @submit.prevent="submit">
        <p class="fw-bold text-muted mb-2">Filter Data</p>
        <div class="row">
          <div class="col-md-6">
            <div class="row g-3">
              <div class="col">
                <date-picker v-model="filter.date">
                  <template #dp-input="{ value }">
                    <input
                      type="text"
                      :value="value"
                      class="form-control"
                      placeholder="Masukkan Tanggal"
                    />
                  </template>
                </date-picker>
              </div>
              <div class="col">
                <select-input
                  v-model="filter.status"
                  :options="[
                    { value: 'active', name: 'Active' },
                    { value: 'nonactive', name: 'Non-Active' },
                  ]"
                  placeholder="Status"
                >
                </select-input>
              </div>
              <div class="col">
                <base-input
                  placeholder="Type here"
                  v-model="filter.type"
                ></base-input>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex gap-3 justify-content-end">
            <button
              type="submit"
              class="btn btn-outline-warning fw-bold text-dark w-25 outline-none shadow-none"
            >
              Apply
            </button>
            <button
              type="reset"
              class="btn btn-outline-primary border-0 fw-bold w-25 outline-none shadow-none"
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseInput from "../input/BaseInput.vue";
import SelectInput from "../input/SelectInput.vue";

export default {
  components: {
    BaseInput,
    SelectInput,
  },
  props: {
    submit: {
      type: Function,
      default: () => {
        alert("default");
      },
    },
  },
  data() {
    return {
      filter: {
        date: "",
        format: `${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()}`,
        status: "",
        type: "",
      },
    };
  },
};
</script>

<style scoped>
::placeholder {
  color: #b6b6b6;
}

.form-control,
.btn {
  padding: 0.75rem !important;
  font-size: 15px !important;
}
</style>
