<template>
  <div class="input-group border rounded-pill">
    <span class="input-group-text bg-transparent border-0" ref="blur">
      <span class="fw-bold">Rp</span>
    </span>
    <input
      type="text"
      class="form-control border-0 outline-none shadow-none rounded-pill"
      :placeholder="placeholder"
      :value="modelValue"
      ref="inputRef"
      disabled
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
  <div class="input-errors" v-for="(error, index) of errors" :key="index">
    <div class="text-danger mt-2 text-sm">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);

    return { inputRef };
  },
  props: {
    placeholder: {
      required: true,
      type: String,
    },
    modelValue: {
      type: String,
    },
    onBlur: {
      type: Function,
    },
    errors: {
      type: Array,
    },
    options: {
      type: Object,
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #b6b6b6;
}

.form-control,
.btn {
  background-color: transparent !important;
  border-radius: 100px !important;
  padding: 0.75rem !important;
  font-size: 15px !important;
}

.form-control {
  border-radius: 100px !important;
}
.input-group {
  background-color: #f3f3f3 !important;
}
</style>
