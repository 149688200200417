<template>
  <div class="input-group border border-1 rounded rounded-1">
    <span class="input-group-text border-0 bg-transparent">
      <Icon name="Date" size="sm" color="muted"/>
    </span>
    <input type="text" 
      :value="modelValue" 
      @input="$emit('update:modelValue', $event.target.value)"
      class="form-control border-0" 
      placeholder="Recipient's username" 
      aria-label="Recipient's username" 
      aria-describedby="basic-addon2"
    >
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  }
}
</script>