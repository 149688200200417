<template>
  <table class="table table-bordered table-striped">
    <caption>
      Rincian Invoice
    </caption>
    <tbody>
      <tr class="bg-info rounded-top" style="--bs-bg-opacity: 0.15">
        <th scope colspan="2">
          No. Invoice {{ acceptance?.invoice?.invoice_no }}
        </th>
      </tr>
      <tr>
        <td>No. Invoice</td>
        <th scope>{{ acceptance?.invoice?.invoice_no }}</th>
      </tr>
      <tr>
        <td>Nilai Invoice</td>
        <th scope>{{ rupiahFormat(acceptance?.invoice?.invoice_nominal) }}</th>
      </tr>
      <tr>
        <td>Jatuh Tempo Invoice</td>
        <th scope>{{ dateFormat(acceptance?.invoice?.invoice_due_date) }}</th>
      </tr>
      <tr>
        <td>Presentase Pencairan</td>
        <th scope>{{ acceptance?.percentage_of_disbursed }}%</th>
      </tr>
      <tr>
        <td>Nilai Pinjaman</td>
        <th scope>{{ rupiahFormat(acceptance?.principal_value) }}</th>
      </tr>
      <tr>
        <td>Bunga</td>
        <th scope>{{ acceptance?.interest_rate }}%</th>
      </tr>
      <tr>
        <td>Nilai Bunga</td>
        <th scope>{{ rupiahFormat(acceptance?.interest_value) }}</th>
      </tr>
      <tr>
        <td>Admin Fee</td>
        <th scope>{{ rupiahFormat(acceptance?.admin_fee_value) }}</th>
      </tr>
      <tr>
        <td>Nilai yang Diterima</td>
        <th scope>{{ rupiahFormat(acceptance?.disbursement_value) }}</th>
      </tr>
      <tr>
        <td>Nilai yang Harus Dibayarkan</td>
        <th scope>{{ rupiahFormat(acceptance?.repayment_value) }}</th>
      </tr>
      <tr>
        <td>Tenor</td>
        <th scope>{{ acceptance?.tenor }} hari</th>
      </tr>
      <tr>
        <td>Jadwal Pencairan</td>
        <th scope>{{ dateFormat(acceptance?.disbursement_date_request) }}</th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    acceptance: {
      type: Object,
    },
  },
};
</script>

<style scoped>
th,
td {
  width: 50%;
  padding: 8px 16px;
  height: 56px;
  vertical-align: middle;
}
</style>
