<template>
  <table class="table table-bordered table-striped">
    <caption>
      Rincian Alamat
    </caption>
    <tbody>
      <tr>
        <td>Alamat Tempat Usaha</td>
        <th scope>{{ address }}</th>
      </tr>
      <tr>
        <td>Provinsi</td>
        <th scope>{{ province }}</th>
      </tr>
      <tr>
        <td>Kabupaten/Kota</td>
        <th scope>{{ city }}</th>
      </tr>
      <tr>
        <td>Kecamatan</td>
        <th scope>{{ district }}</th>
      </tr>
      <tr>
        <td>Kelurahan</td>
        <th scope>{{ subdistrict }}</th>
      </tr>
      <tr>
        <td>Kode Pos</td>
        <th scope>{{ postalCode }}</th>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: [
    "address",
    "city",
    "province",
    "district",
    "subdistrict",
    "postalCode",
  ],
};
</script>

<style scoped>
th,
td {
  width: 50%;
  padding: 8px 16px;
  height: 56px;
  vertical-align: middle;
}
</style>
