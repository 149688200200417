<template>
  <label :for="label" class="form-label color-grey-800 fw-bold">
    {{ label }} <span class="text-danger" v-if="required">*</span>
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.form-label {
  font-weight: 700;
  font-size: 15px;
}
</style>
