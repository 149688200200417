<template>
  <table class="table table-bordered">
    <caption>
      Rincian RAC
    </caption>
    <tbody v-for="(result, index) in rac" :key="index">
      <tr v-if="result.name == 'sejarah_kredit'">
        <td>{{ result.value }}</td>
        <th scope colspan="2">
          {{
            result.value_used
              ? "Memiliki Outstanding"
              : "Tidak Memiliki Outstanding"
          }}
        </th>
      </tr>
      <tr v-else>
        <td>{{ result.value }}</td>
        <th scope>{{ result.value_used ?? "-" }}</th>
        <td class="text-center">
          <active-badge :active="result.is_passed" :label="approval" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ActiveBadge from "../utility/ActiveBadge.vue";

export default {
  components: {
    ActiveBadge,
  },
  props: {
    rac: {
      type: Array,
    },
  },
  data() {
    return {
      labels: {
        active: "Data Sesuai",
        nonactive: "Data Tidak Sesuai",
      },
      approval: {
        active: "Diterima",
        nonactive: "Ditolak",
      },
    };
  },
};
</script>
