<template>
  <div class="form-check" :class="{ 'form-check-inline': isInline }">
    <input
      class="form-check-input"
      type="radio"
      :id="`${id}-${value}`"
      :value="value"
      :checked="isChecked"
      :disabled="isDisabled"
      @change="() => change && change()"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <label :for="`${id}-${value}`">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: [String, Number, Boolean],
    },
    isInline: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Boolean],
    },
    id: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    change: {
      type: Function,
    },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-clip: content-box;
  border: 2px solid #00697f;
  background-color: transparent;
}

input[type="radio"]:checked {
  background-color: #00697f;
  padding: 0px;
  border: 2px solid #00697f;
}

.label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;
}
</style>
