import axios from "axios";
import store from "@/store";

const openModal = () => {
  store.dispatch("changeModal", {
    isOpen: true,
    title: "Sesi Login Melebihi Batas",
    description:
      "Sesi login anda telah melebihi batas. Silahkan melakukan login ulang untuk melanjutkan transaksi anda.",
  });
};

const baseURL = process.env.VUE_APP_BACKOFFICE_API_URL;
const baseURLShared = process.env.VUE_APP_MASTER_API_URL;
const baseBorrowerURL = process.env.VUE_APP_BORROWER_API_URL;

export const api = axios.create({
  baseURL: baseURL,
});

export const api_shared = axios.create({
  baseURL: baseURLShared,
});

export const api_public = axios.create({
  baseURL: baseURL,
});

export const api_borrower = axios.create({
  baseURL: baseBorrowerURL,
});

api.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};
api_shared.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};
api_public.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};
api_borrower.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

api.interceptors.request.use(async (config) => {
  const access_token = localStorage.getItem("access_token");
  const refresh_token = localStorage.getItem("refresh_token");
  const expires_at = localStorage.getItem("expires_at");

  let accessToken = access_token;

  if (new Date().valueOf() >= new Date(expires_at).valueOf()) {
    accessToken = await getRefreshToken(refresh_token);
    config.headers["Authorization"] = "Bearer " + accessToken;
  } else {
    config.headers["Authorization"] = "Bearer " + accessToken;
  }

  return config;
});

api.interceptors.response.use(
  async (res) => {
    return res;
  },
  (error) => {
    if (error.response?.data?.error_code === "440000") {
      localStorage.clear();
      openModal();
      return;
    }
    return Promise.reject(error);
  }
);

api_shared.interceptors.request.use(async (config) => {
  const access_token = localStorage.getItem("access_token");
  const refresh_token = localStorage.getItem("refresh_token");
  const expires_at = localStorage.getItem("expires_at");

  let accessToken = access_token;

  if (new Date().valueOf() >= new Date(expires_at).valueOf()) {
    accessToken = await getRefreshToken(refresh_token);
    config.headers["Authorization"] = "Bearer " + accessToken;
  } else {
    config.headers["Authorization"] = "Bearer " + accessToken;
  }

  return config;
});

api_shared.interceptors.response.use(
  async (res) => {
    return res;
  },
  (error) => {
    if (error.response?.data?.error_code === "440000") {
      localStorage.clear();
      openModal();
      return;
    }
    return Promise.reject(error);
  }
);

const getRefreshToken = async (refreshToken) => {
  try {
    const payload = {
      refresh_token: refreshToken,
    };
    const res = await api_public.post("/auth/token/refresh", payload);

    const {
      data: { refresh_token, access_token, expires_at },
    } = res.data;

    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("expires_at", expires_at);

    return access_token;
  } catch (error) {
    localStorage.clear();
    window.location.href = "/login";
  }
};
