<template>
  <div class="tabs">
    <div class="d-flex gap-2">
      <button 
        class="nav-link flex-fill" 
        v-for="title in tabTitles" 
        :key="title.title" 
        :class="{'active': title.title == selectedTitle}"
        @click="changeTab(title.title)"         
        :disabled="title.disabled">
        {{ title.title }}
      </button>
    </div>
    <div class="card rounded-0" style="height: calc(100% - 45px)">
      <div class="card-body overflow-auto">
        <slot :changeTab="changeTab" />
      </div>
    </div>
  </div>
</template>

<script>
import {provide, ref} from 'vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  setup(props, {slots}) {
    const tabTitles = ref(slots.default().map(tab => tab.props))
    const selectedTitle = ref(tabTitles.value[0].title)

    provide('selectedTitle', selectedTitle)

    return {
      tabTitles,
      selectedTitle
    }
  },
  computed: {
    ...mapGetters(['tab'])
  },
  methods: {
    ...mapActions({
      setTab: 'changeTab'
    }),
    changeTab(tab) {
      this.selectedTitle = tab
      this.setTab(tab)
      console.log(this.tab)
    }
  },
  mounted () {
    this.setTab(this.selectedTitle)
  }
}
</script>

<style scoped>
.nav-link {
  border-radius: 0;
  color: #b6b6b6;
  border: 1px solid #dadada;
  font-weight: 700;
  padding: 10px 24px;
  border-bottom: none;
}

.nav-link.active {
  border: none;
  border-bottom: 2px solid #0c80ef;
  background-color: #e8f4ff;
  color: #0c80ef;
}

.nav-link:disabled {
  color: #b6b6b6;
  border: 1px solid #dadada;
}
</style>