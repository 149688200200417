import { api } from "@/libs/api";

class RoleChangeService {
  async getRolesApproval(params) {
    return await api.get("/roles/approval", { params });
  }

  async approveRolesApproval(payload) {
    return await api.put("/roles/approval/approve", payload);
  }

  async rejectRolesApproval(payload) {
    return await api.put("/roles/approval/reject", payload);
  }

  async getRoleChangeHistories(payload) {
    return await api.get("/roles/approval/riwayat", {
      params: payload,
    });
  }

  async getRoleChangebyId(id) {
    return await api.get(`/roles/approval/${id}`);
  }

}

export default new RoleChangeService();
