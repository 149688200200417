<template>
  <a href="#" class="text-muted" data-bs-toggle="modal" :data-bs-target="`#${target}`">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    target: {
      type: String,
      default: ''
    },
  }
}
</script>