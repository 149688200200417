import { api, api_public } from "@/libs/api";

let state = {
  isLoggedIn:
    localStorage.getItem("access_token") &&
    localStorage.getItem("refresh_token"),
  accessToken: localStorage.getItem("access_token") ?? null,
  refreshToken: localStorage.getItem("refresh_token") ?? null,
  user: JSON.parse(localStorage.getItem("user")) ?? null,
  errorAlert: {
    isShow: false,
    message: "",
  },
};

let getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  user: (state) => state.user,
  errorAlert: (state) => state.errorAlert,
};

let mutations = {
  SET_IS_LOGGED_IN(state, payload) {
    state.isLoggedIn = payload;
  },
  SET_ACCESS_TOKEN(state, payload) {
    state.accessToken = payload;
  },
  SET_REFRESH_TOKEN(state, payload) {
    state.refreshToken = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_ERROR_ALERT(state, payload) {
    state.errorAlert = payload;
  },
};

let actions = {
  setLoggedIn({ commit }) {
    commit("SET_IS_LOGGED_IN", true);
  },
  async doLoggedIn({ commit }, payload) {
    try {
      const response = await api_public.post("/auth/login", payload);

      if (response.data) {
        const {
          data: { refresh_token, access_token, expires_at },
        } = response.data;

        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("expires_at", expires_at);
        commit("SET_IS_LOGGED_IN", true);
        const { data: responseUser } = await api.get("/me");

        if (responseUser?.success) {
          const { data: dataUser } = responseUser;

          const sanitizedUser = {
            id: dataUser.id,
            full_name: dataUser.full_name,
            role: dataUser.role,
            created_at: dataUser.created_at,
            last_login: dataUser.last_login,
            deactivate_reason: dataUser.deactivate_reason,
            last_change_password: dataUser.last_change_password,
            divisi: dataUser.divisi,
            jabatan: dataUser.jabatan,
          };

          localStorage.setItem("user", JSON.stringify(sanitizedUser));
        }

        return true;
      }
    } catch (error) {
      if (error.response) {
        commit("SET_ERROR_ALERT", {
          isShow: true,
          message: error.response.data.message,
        });
      } else {
        commit("SET_ERROR_ALERT", {
          isShow: true,
          message: error.message,
        });
      }
      return false;
    }
  },
  async doLoggedOut({ commit }) {
    try {
      const response = await api.post("/auth/logout");
      localStorage.clear();
      commit("SET_IS_LOGGED_IN", false);

      if (response.data.success) {
        return true;
      }
    } catch (error) {
      localStorage.clear();
      commit("SET_IS_LOGGED_IN", false);

      if (error.response) {
        commit("SET_ERROR_ALERT", {
          isShow: true,
          message: error.response.data.message,
        });
      } else {
        commit("SET_ERROR_ALERT", {
          isShow: true,
          message: error.message,
        });
      }
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
