<template>
  <form @submit.prevent="submit">
    <p class="fw-bold text-muted mb-2">Filter Data</p>
    <div class="row">
      <div class="col-md-6">
        <div class="row g-3">
          <div class="col">
            <date-picker v-model="filter.date" :enableTimePicker="false">
              <template #dp-input="{value}">
                <input type="text" :value="value" class="form-control" placeholder="Masukkan Tanggal">
              </template>
            </date-picker>
          </div>
          <div class="col">
            <select-input
              v-model="filter.status"
              :options="[
                {value: 'principle', name: 'Principle'},
                {value: 'distributor', name: 'Distributor'},
              ]"
              placeholder="Status">
            </select-input>
          </div>
          <div class="col">
            <base-input placeholder="Search by ID" v-model="filter.type"></base-input>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex gap-3 justify-content-end">
        <button type="submit" class="btn btn-outline-warning fw-bold text-dark w-25 outline-none shadow-none">Apply</button>
      </div>
    </div>
  </form>
</template>

<script>
import BaseInput from '../input/BaseInput.vue';
import SelectInput from '../input/SelectInput.vue';

export default {
  components: {
    BaseInput,
    SelectInput
  },
  props: {
    submit: {
      type: Function,
      default: () => {
        alert('default')
      }
    }
  },
  data () {
    return {
      filter: {
        date: '',
        format: `${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()}`,
        status: '',
        type: ''
      }
    }
  }
}
</script>

<style scoped>
::placeholder {
  color: #B6B6B6;
}

.form-control, .btn {
  padding: .75rem !important;
  font-size: 15px !important;
}
</style>