<template>
  <button
    class="btn w-100 btn-warning w-100 fw-bold px-4 py-2"
    :class="{ disabled: loading }"
    :style="buttonStyle"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonText() {
      return this.loading ? "...memuat" : this.label;
    },
    buttonStyle() {
      return {
        "min-width": "150px",
      };
    },
  },
  methods: {},
};
</script>

<style scoped>
button {
  font-size: 15px;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
