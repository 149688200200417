import DocumentService from "@/services/document";

const state = {
  documents: [],
  meta: null,
};

const getters = {
  documents: (state) => state.documents,
  meta: (state) => state.meta,
};

const mutations = {
  SET_DOCUMENTS(state, payload) {
    state.documents = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

const actions = {
  async getDocuments({ commit }, params) {
    const response = await DocumentService.getDocuments(params);

    commit("SET_DOCUMENTS", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
