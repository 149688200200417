import PrincipleService from "@/services/principle";

const state = {
  principals: null,
  meta: null,
};

const getters = {
  principals: (state) => state.principals,
  meta: (state) => state.meta,
};

const mutations = {
  SET_PRINCIPALS(state, payload) {
    state.principals = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

const actions = {
  async retrievePrincipal({ commit }, params) {
    const response = await PrincipleService.retrieve(params);

    commit("SET_PRINCIPALS", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
