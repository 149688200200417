<template>
  <div class="rounded-pill">
    <label
      v-if="label"
      class="fw-500 text-md mb-1"
      :class="isFocus ? 'color-primary' : 'color-gray-2'"
      >{{ label }} <span class="text-danger" v-if="required">*</span></label
    >
    <v-select
      :placeholder="placeholder"
      :options="options"
      :value="modelValue"
      :disabled="disabled"
      :label="labelName ? labelName : 'name'"
      :reduce="(option) => option[reduce]"
      @input="handleInput"
    >
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          v-bind="attributes"
          v-on="events"
          @focus="
            () => {
              onFocus();
              handleFocus && handleFocus();
            }
          "
          @blur="
            () => {
              onBlur();
              handleBlur && handleBlur();
            }
          "
        />
      </template>
    </v-select>

    <div class="input-errors" v-for="(error, index) of errors" :key="index">
      <div class="text-danger mt-2 text-sm">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "InputSearch",
  components: { vSelect },
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: [Array, null],
      required: true,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    handleChange: {
      type: Function,
    },
    handleBlur: {
      type: [Function, undefined],
    },
    handleFocus: {
      type: Function,
    },
    labelName: {
      type: Function,
    },
    errors: {
      type: Array,
    },
    reduce: {
      type: String,
      default: "id",
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  methods: {
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    handleInput(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style scoped>
.vs__dropdown-toggle {
  height: 43px !important;
  color: #535353 !important;
  border: none !important;
}
.vs__search {
  padding: 0.5rem !important;
  font-size: 15px !important;
  border: none !important;
}
</style>
