<template>
  <div class="dropdown">
    <a class="text-decoration-none" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <slot name="label"></slot>
    </a>

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>