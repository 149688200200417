import MainLayout from "@/components/layouts/MainLayout";
import store from "@/store";

const changeMenuUserManage = () => {
  store.dispatch("changeMenu", "user_management");
};

const routes = [
  {
    path: "/user-management/role",
    name: "user-management.role",
    component: () => import("@/views/user_management/RoleView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Daftar Role",
      breadcrumbs: [
        { name: "User Management", url: "/" },
        { name: "Daftar Role", url: "/" },
      ],
    },
    beforeEnter: changeMenuUserManage,
  },
  {
    path: "/user-management/user",
    name: "user-management.user",
    component: () => import("@/views/user_management/UserView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Daftar User",
      breadcrumbs: [
        { name: "User Management", url: "/" },
        { name: "Daftar User", url: "/" },
      ],
    },
    beforeEnter: changeMenuUserManage,
  },
  {
    path: "/user-management/riwayat-perubahan-user",
    name: "user-management.riwayat-perubahan-user",
    component: () =>
      import("@/views/user_management/UserChangeHistoryView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Riwayat Perubahan User",
      breadcrumbs: [
        { name: "User Management", url: "/" },
        { name: "Riwayat Perubahan User", url: "/" },
      ],
      isBack: true,
    },
    beforeEnter: changeMenuUserManage,
  },
  {
    path: "/user-management/riwayat-perubahan-role",
    name: "user-management.riwayat-perubahan-role",
    component: () =>
      import("@/views/user_management/RoleChangeHistoryView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Riwayat Perubahan Role",
      breadcrumbs: [
        { name: "User Management", url: "/" },
        { name: "Riwayat Perubahan Role", url: "/" },
      ],
      isBack: true,
    },
    beforeEnter: changeMenuUserManage,
  },
  {
    path: "/user-management/permohonan-perubahan-role",
    name: "user-management.permohonan-perubahan-role",
    component: () => import("@/views/user_management/RoleChangeView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Permohonan Perubahan Role",
      breadcrumbs: [
        { name: "User Management", url: "/" },
        { name: "Permohonan Perubahan Role", url: "/" },
      ],
    },
    beforeEnter: changeMenuUserManage,
  },
  {
    path: "/user-management/permohonan-perubahan-user",
    name: "user-management.permohonan-perubahan-user",
    component: () => import("@/views/user_management/UserChangeView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Permohonan Perubahan User",
      breadcrumbs: [
        { name: "User Management", url: "/" },
        { name: "Permohonan Perubahan User", url: "/" },
      ],
    },
    beforeEnter: changeMenuUserManage,
  },
];

export default routes;
