<template>
  <div class="switch-toggle">
    <label class="switch">
      <input type="checkbox" v-model="isChecked" />
      <span class="slider"></span>
    </label>
    <span class="switch-label">{{ isChecked ? "On" : "Off" }}</span>
  </div>
</template>

<script>
export default {
  name: "SwitchToggle",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.isChecked = newValue;
    },
    isChecked(newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>

<style scoped>
.switch-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transition: 0.4s;
  border-radius: 24px;
  border: 2px solid #02697f;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #02697f;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: white;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.switch-label {
  font-weight: bold;
  font-size: 14px;
  color: #666;
}
</style>
