import { createStore } from "vuex";
import auth from "./modules/auth";
import principal from "./modules/principal";
import registration from "./modules/registration";
import role from "./modules/role";
import roleChange from "./modules/roleChange";
import userChange from "./modules/userChange";
import user from "./modules/user";
import supplier from "./modules/supplier";
import borrower from "./modules/borrower";
import principle from "./modules/principle";
import acceptance from "./modules/acceptance";
import document from "./modules/document";
import collection from "./modules/collection";
import reporting from "./modules/reporting";
import alert from "./modules/alert";
import account from "./modules/account";
import dayoff from "./modules/dayoff";
import customers from "./modules/customers";
import auditLog from "./modules/auditLog";
import disbursement from "./modules/disbursement";

export default createStore({
  state: {
    menu: null,
    tab: null,
    modal: {
      isOpen: false,
      title: "",
      description: "",
    },
  },
  getters: {
    menu: (state) => state.menu,
    tab: (state) => state.tab,
    modal: (state) => state.modal,
  },
  mutations: {
    SET_MENU(state, payload) {
      state.menu = payload;
    },
    SET_TAB(state, payload) {
      state.tab = payload;
    },
    SET_MODAL(state, payload) {
      state.modal = payload;
    },
  },
  actions: {
    changeMenu({ commit }, payload) {
      commit("SET_MENU", payload);
    },
    changeTab({ commit }, payload) {
      commit("SET_TAB", payload);
    },
    changeModal({ commit }, payload) {
      commit("SET_MODAL", payload);
    },
  },
  modules: {
    authModule: auth,
    roleModule: role,
    roleChangeModule: roleChange,
    userChangeModule: userChange,
    userModule: user,
    principalModule: principal,
    registrationModule: registration,
    supplierModule: supplier,
    borrowerModule: borrower,
    principleModule: principle,
    acceptanceModule: acceptance,
    documentModule: document,
    collectionModule: collection,
    reportingModule: reporting,
    alertModule: alert,
    accountModule: account,
    dayoffModule: dayoff,
    customersModule: customers,
    auditModule: auditLog,
    disbursementModule: disbursement,
  },
});
