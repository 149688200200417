import BorrowerService from "@/services/borrower";

const state = {
  borrowers: [],
  suppliers: [],
  distributors: [],
  borrowerChanges: [],
  borrowerChangesHistories: null,
  validation: {
    is_all_data_valid: true,
  },
  businessPhoto: [],
  isLegal: true,
  meta: null,
  borrower: null,
  detail: null,
  documents: [],
  directors: [],
  verifyData: {
    escrow_bank_account_holder: "",
    escrow_bank_account_number: "",
    giro_bank_account_holder: "",
    giro_bank_account_number: "",
    is_all_data_valid: true,
    is_bisnis_valid_valid: null,
    is_data_direktur_valid: null,
    is_informasi_kerabat_valid: null,
    is_informasi_personal_valid: null,
    is_informasi_perusahaan_valid: null,
    is_rekening_valid: null,
    rac: [
      {
        is_valid: null,
        rac_id: "",
        value: "",
      },
    ],
    reason: "",
    registration_id: "",
  },
  rac: [],
  disabledSubmit: null,
};

const getters = {
  borrowers: (state) => state.borrowers,
  suppliers: (state) => state.suppliers,
  distributors: (state) => state.distributors,
  borrowerChanges: (state) => state.borrowerChanges,
  borrowerChangesHistories: (state) => state.borrowerChangesHistories,
  validation: (state) => state.validation,
  isLegal: (state) => state.isLegal,
  countBorrower: (state) => state.borrowers.length,
  meta: (state) => state.meta,
  documents: (state) => state.documents,
  directors: (state) => state.directors,
  borrower: (state) => state.borrower,
  verifyData: (state) => state.verifyData,
  rac: (state) => state.rac,
  detail: (state) => state.detail,
  businessPhoto: (state) => state.businessPhoto,
  disabledSubmit: (state) => {
    return (
      !state.verifyData.escrow_bank_account_holder ||
      !state.verifyData.escrow_bank_account_number ||
      !state.verifyData.giro_bank_account_holder ||
      !state.verifyData.giro_bank_account_number
    );
  },
};

const mutations = {
  SET_BORROWERS(state, payload) {
    state.borrowers = payload;
  },
  SET_SUPPLIERS(state, payload) {
    state.suppliers = payload;
  },
  SET_DISTRIBUTORS(state, payload) {
    state.distributors = payload;
  },
  SET_BORROWERCHANGES(state, payload) {
    state.borrowerChanges = payload;
  },
  SET_BORROWERCHANGES_HISTORIES(state, payload) {
    state.borrowerChangesHistories = payload;
  },
  SET_VALIDATION(state, payload) {
    state.validation = payload;
  },
  RESET_VALIDATION(state, payload) {
    state.validation = {};
  },
  SET_IS_LEGAL(state, payload) {
    state.isLegal = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_BORROWER(state, payload) {
    state.borrower = payload;
  },
  ADD_DOCUMENT(state, payload) {
    state.documents.push(payload);
  },
  ADD_DIRECTOR(state, payload) {
    state.directors.push(payload);
  },
  ADD_BUSINESS_PHOTO(state, payload) {
    state.businessPhoto.push(payload);
  },
  CLEAR_DOCUMENT(state) {
    state.documents = [];
  },
  CLEAR_DIRECTOR(state) {
    state.directors = [];
  },
  CLEAR_BUSINESS_PHOTO(state) {
    state.businessPhoto = [];
  },
  SET_VERIFY_DATA(state, { key, value }) {
    state.verifyData[key] = value;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_RAC(state, payload) {
    state.rac = payload;
  },
  CLEAR_RAC(state) {
    state.rac = [];
  },
  CLEAR_BORROWER(state) {
    state.borrowers = [];
    state.meta = null;
  },
  RESET_VERIFY_DATA(state) {
    state.verifyData = {
      escrow_bank_account_holder: "",
      escrow_bank_account_number: "",
      giro_bank_account_holder: "",
      giro_bank_account_number: "",
      is_all_data_valid: true,
      is_bisnis_valid_valid: null,
      is_data_direktur_valid: null,
      is_informasi_kerabat_valid: null,
      is_informasi_personal_valid: null,
      is_informasi_perusahaan_valid: null,
      is_rekening_valid: null,
      rac: [
        {
          is_valid: null,
          rac_id: "",
          value: "",
        },
      ],
      reason: "",
      registration_id: "",
    };
  },
};

const actions = {
  async retrieveBorrower({ commit }, payload) {
    try {
      const response = await BorrowerService.retrieve(payload);
      const metaPayload = {
        page: response.data.data.page,
        per_page: response.data.data.per_page,
        total_data: response.data.data.total_data,
        total_page: response.data.data.total_page,
      };

      commit("SET_META", metaPayload);
      commit("SET_BORROWERS", response.data.data.items);
    } catch (error) {
      alert(error.response.data.message);
    }
  },
  async detailBorrower({ commit }, payload) {
    try {
      const response = await BorrowerService.show(payload);
      const financingProgram =
        response.data.data.registration.financing_program;
      const borrowers = await BorrowerService.retrieve({
        financing_program: financingProgram,
        page: 1,
        per_page: 100,
      });
      const detail = borrowers.data.data.items.filter(
        (borrower) => borrower.id == payload
      )[0];

      commit("SET_BORROWER", response.data.data);
      commit("SET_DETAIL", detail);

      return response;
    } catch (error) {
      alert(error.response.data.message);
    }
  },

  async retrieveDistributor({ commit }, status) {
    try {
      const response = await BorrowerService.retrieveDistributor(status);

      commit("SET_DISTRIBUTORS", response.data.data.items);
    } catch (error) {
      alert(error.response.data.message);
    }
  },

  async getBorrowerApprovals({ commit }, payload) {
    try {
      const response = await BorrowerService.getBorrowerApprovals(payload);

      const metaPayload = {
        page: response.data.data.page,
        per_page: response.data.data.per_page,
        total_data: response.data.data.total_data,
        total_page: response.data.data.total_page,
      };

      commit("SET_META", metaPayload);
      commit("SET_BORROWERCHANGES", response.data.data.items);
    } catch (error) {
      alert(error.response.data.message);
    }
  },

  async getBorrowerChangeHistories({ commit }, params) {
    try {
      const response = await BorrowerService.getBorrowerChangeHistories(params);

      commit("SET_BORROWERCHANGES_HISTORIES", response.data.data.items);

      const metaPayload = {
        page: response.data.data.page,
        per_page: response.data.data.per_page,
        total_data: response.data.data.total_data,
        total_page: response.data.data.total_page,
      };

      commit("SET_META", metaPayload);
    } catch (error) {
      alert(error.response.data.message);
    }
  },
  addNewValidation({ commit }, payload) {
    commit("SET_VALIDATION", payload);
  },
  resetValidation({ commit }) {
    commit("RESET_VALIDATION");
  },
  setIsLegal({ commit }, payload) {
    commit("SET_IS_LEGAL", payload);
  },
  setVerifyData({ commit }, payload) {
    commit("SET_VERIFY_DATA", payload);
  },
  clearDocument({ commit }) {
    commit("CLEAR_DOCUMENT");
  },
  clearDirector({ commit }) {
    commit("CLEAR_DIRECTOR");
  },
  clearRAC({ commit }) {
    commit("CLEAR_RAC");
  },
  clearBusinessPhoto({ commit }) {
    commit("CLEAR_BUSINESS_PHOTO");
  },
  clearBorrowers({ commit }) {
    commit("CLEAR_BORROWER");
  },
  resetVerifyData({ commit }) {
    commit("RESET_VERIFY_DATA");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
