import CollectionService from "@/services/collection";

const state = {
  meta: null,
  meta_interactions: null,
  meta_related_loans: null,
  meta_other_loans: null,
  bill_reminders: null,
  due_dates: null,
  late_payments: null,
  arrears: null,
  task_managements: null,
  interactions: null,
  related_loans: null,
  other_loans: null,
  errors: {
    show: false,
    message: "",
  },
  success: {
    show: false,
    message: "",
  },
  loading: null,
};

const getters = {
  meta: (state) => state.meta,
  meta_interactions: (state) => state.meta_interactions,
  meta_related_loans: (state) => state.meta_related_loans,
  meta_other_loans: (state) => state.meta_other_loans,
  bill_reminders: (state) => state.bill_reminders,
  due_dates: (state) => state.due_dates,
  late_payments: (state) => state.late_payments,
  arrears: (state) => state.arrears,
  task_managements: (state) => state.task_managements,
  interactions: (state) => state.interactions,
  related_loans: (state) => state.related_loans,
  other_loans: (state) => state.other_loans,
  errors: (state) => state.errors,
  success: (state) => state.success,
  loading: (state) => state.loading,
};

const mutations = {
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_META_INTERACTIONS(state, payload) {
    state.meta_interactions = payload;
  },
  SET_META_RELATED_LOANS(state, payload) {
    state.meta_related_loans = payload;
  },
  SET_META_OTHER_LOANS(state, payload) {
    state.meta_other_loans = payload;
  },
  SET_ERRORS(state, payload) {
    state.errors = payload;
  },
  SET_SUCCESS(state, payload) {
    state.success = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_COLLECTION_BILL_REMINDER(state, payload) {
    state.bill_reminders = payload;
  },
  SET_COLLECTION_DUE_DATES(state, payload) {
    state.due_dates = payload;
  },
  SET_COLLECTION_LATE_PAYMENTS(state, payload) {
    state.late_payments = payload;
  },
  SET_COLLECTION_ARREARS(state, payload) {
    state.arrears = payload;
  },
  SET_COLLECTION_TASK_MANAGEMENTS(state, payload) {
    state.task_managements = payload;
  },
  SET_INTERACTIONS(state, payload) {
    state.interactions = payload;
  },
  SET_RELATED_LOANS(state, payload) {
    state.related_loans = payload;
  },
  SET_OTHER_LOANS(state, payload) {
    state.other_loans = payload;
  },
};

const actions = {
  async retrieveBillReminder({ commit }, payload) {
    commit("SET_LOADING", true);
    const response = await CollectionService.retrieveBillReminder(payload);
    if (!response?.response?.data?.success) {
      commit("SET_ERRORS", {
        show: true,
        message: response?.response?.data?.message,
      });
      commit("SET_LOADING", false);
      return;
    }
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
    commit("SET_COLLECTION_BILL_REMINDER", response.data.data.items);
    commit("SET_LOADING", false);
  },

  async retrieveDueDate({ commit }, payload) {
    commit("SET_LOADING", true);
    const response = await CollectionService.retrieveDueDate(payload);
    if (!response?.response?.data?.success) {
      commit("SET_ERRORS", {
        show: true,
        message: response?.response?.data?.message,
      });
      commit("SET_LOADING", false);
      return;
    }
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
    commit("SET_COLLECTION_DUE_DATES", response.data.data.items);
    commit("SET_LOADING", false);
  },

  async retrieveLatePayment({ commit }, payload) {
    commit("SET_LOADING", true);
    const response = await CollectionService.retrieveLatePayment(payload);
    if (!response?.response?.data?.success) {
      commit("SET_ERRORS", {
        show: true,
        message: response?.response?.data?.message,
      });
      commit("SET_LOADING", false);
      return;
    }
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
    commit("SET_COLLECTION_LATE_PAYMENTS", response.data.data.items);
    commit("SET_LOADING", false);
  },

  async retrieveArrears({ commit }, payload) {
    commit("SET_LOADING", true);
    const response = await CollectionService.retrieveArrears(payload);
    if (!response?.response?.data?.success) {
      commit("SET_ERRORS", {
        show: true,
        message: response?.response?.data?.message,
      });
      commit("SET_LOADING", false);
      return;
    }
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
    commit("SET_COLLECTION_ARREARS", response.data.data.items);
    commit("SET_LOADING", false);
  },

  async retrieveTaskManagement({ commit }, payload) {
    commit("SET_LOADING", true);
    const response = await CollectionService.retrieveTaskManagement(payload);
    if (!response?.response?.data?.success) {
      commit("SET_ERRORS", {
        show: true,
        message: response?.response?.data?.message,
      });
      commit("SET_LOADING", false);
      return;
    }
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    const items = response.data.data.items;
    if (items?.length > 0) {
      const resp_collector = await CollectionService.retrieveCollector();
      const collectors = resp_collector?.data?.data?.items || [];
      const collectors_mapped = collectors.map((collector) => ({
        id: collector.id,
        name: collector.full_name,
      }));

      items.forEach((item) => {
        item.collectors = collectors_mapped;
      });
    }

    commit("SET_META", metaPayload);
    commit("SET_COLLECTION_TASK_MANAGEMENTS", response.data.data.items);
    commit("SET_LOADING", false);
  },

  async getInteractions({ commit }, params) {
    commit("SET_LOADING", true);
    const response = await CollectionService.getInteractions(
      params[0],
      params[1]
    );
    if (!response?.response?.data?.success) {
      commit("SET_ERRORS", {
        show: true,
        message: response?.response?.data?.message,
      });
      commit("SET_LOADING", false);
      return;
    }
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META_INTERACTIONS", metaPayload);
    commit("SET_INTERACTIONS", response.data.data.items);
    commit("SET_LOADING", false);
  },

  async postInteractions({ commit }, payload) {
    commit("SET_LOADING", true);
    const response = await CollectionService.postInteractions(payload);
    if (!response?.response?.data?.success) {
      commit("SET_ERRORS", {
        show: true,
        message: response?.response?.data?.message,
      });
      commit("SET_LOADING", false);
      return;
    }
    commit("SET_LOADING", false);
  },

  async changeAssignment({ commit }, payload) {
    commit("SET_LOADING", true);
    const response = await CollectionService.changeAssignment(payload);
    if (response.status == 200) {
      commit("SET_SUCCESS", {
        show: true,
        message: "Berhasil mengubah petugas",
      });
    } else {
      commit("SET_ERRORS", {
        show: true,
        message: response?.message || "Failed to change assignment",
      });
    }

    commit("SET_LOADING", false);
  },

  async retrieveRelatedLoan({ commit }, params) {
    commit("SET_LOADING", true);
    const response = await CollectionService.retrieveRelatedLoan(
      params[0],
      params[1]
    );
    if (!response?.response?.data?.success) {
      commit("SET_ERRORS", {
        show: true,
        message: response?.response?.data?.message,
      });
      commit("SET_LOADING", false);
      return;
    }
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META_RELATED_LOANS", metaPayload);
    commit("SET_RELATED_LOANS", response.data.data.items);
    commit("SET_LOADING", false);
  },

  async retrieveOtherLoan({ commit }, params) {
    commit("SET_LOADING", true);
    const response = await CollectionService.retrieveOtherLoan(
      params[0],
      params[1]
    );
    if (!response?.response?.data?.success) {
      commit("SET_ERRORS", {
        show: true,
        message: response?.response?.data?.message,
      });
      commit("SET_LOADING", false);
      return;
    }
    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META_OTHER_LOANS", metaPayload);
    commit("SET_OTHER_LOANS", response.data.data.items);
    commit("SET_LOADING", false);
  },

  setErrors({ commit }, payload) {
    commit("SET_ERRORS", payload);
  },

  clearErrors({ commit }) {
    commit("SET_ERRORS", {
      show: false,
      message: "",
    });
  },
  clearSuccess({ commit }) {
    commit("SET_SUCCESS", {
      show: false,
      message: "",
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
