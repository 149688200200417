<template>
  <button
    type="button"
    :class="`btn btn-${color} py-2 px-4 fw-bold`"
    data-bs-toggle="modal"
    :data-bs-target="`#${modal}`"
    :disabled="disabled"
  >
    <slot></slot>
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    modal: {
      default: "-",
      type: String,
    },
    color: {
      default: "-",
      type: String,
    },
    label: {
      default: "-",
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
};
</script>
