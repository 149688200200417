<template>
  <table class="table table-bordered table-wrapper rounded-parent">
    <caption>
      Rincian Produk
    </caption>
    <tbody>
      <tr>
        <td>Kategori Produk</td>
        <th scope>{{ category ?? "-" }}</th>
      </tr>
      <tr>
        <td>Loan Limit</td>
        <th scope>{{ formatRupiah(loan ?? 0) }}</th>
      </tr>
      <tr>
        <td>Bunga (p.a)</td>
        <th scope>{{ decimalFormat(interest) ?? 0 }}%</th>
      </tr>
      <tr>
        <td>Denda (p.a)</td>
        <th scope>{{ decimalFormat(fine) ?? 0 }}%</th>
      </tr>
      <tr>
        <td>Max Tenor</td>
        <th scope>{{ tenor ?? 0 }} hari</th>
      </tr>
      <tr v-if="feeType?.admin_fee_type == 'dynamic'">
        <td>Biaya Layanan (per transaksi) - Dynamic Fee</td>
        <th scope>
          {{ decimalFormat(feeType?.admin_fee_dynamic_rate ?? 0) }}% <br />
          min.
          {{ formatRupiah(feeType?.admin_fee_dynamic_minimum_amount ?? 0) }}
        </th>
      </tr>
      <tr v-else>
        <td>Biaya Layanan (per transaksi) - Fixed Fee</td>
        <th scope>{{ formatRupiah(feeType?.admin_fee_fixed_amount ?? 0) }}</th>
      </tr>

      <tr v-if="feeType?.provisi_type == 'dynamic'">
        <td>Biaya Provisi - Dynamic Fee</td>
        <th scope>
          {{ decimalFormat(feeType?.provisi_dynamic_rate ?? 0) }}% <br />
          {{ formatRupiah(feeType?.provisi_dynamic_amount ?? 0) }}
        </th>
      </tr>
      <tr v-else>
        <td>Biaya Provisi - Fixed Fee</td>
        <th scope>{{ formatRupiah(feeType?.provisi_fixed_amount ?? 0) }}</th>
      </tr>
      <tr>
        <td>Nomor PK</td>
        <th scope>{{ feeType?.no_pk ?? "-" }}</th>
      </tr>
      <tr>
        <td>Tanggal PK</td>
        <th scope>{{ dateFormat(feeType?.tanggal_pk) ?? "-" }}</th>
      </tr>
      <tr>
        <td>Tanggal Jatuh Tempo Fasilitas</td>
        <th scope>{{ dateFormat(feeType?.facility_due_date) ?? "-" }}</th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import mixin from "@/mixin";
import { formatNominal } from "@/utils/helper";

export default {
  mixins: [mixin],
  props: {
    category: {
      type: String,
      default: "",
    },
    loan: {
      type: [String, Number],
      default: 0,
    },
    interest: {
      type: [String, Number],
      default: 0,
    },
    fine: {
      type: [String, Number],
      default: 0,
    },
    tenor: {
      type: [String, Number],
      default: 0,
    },
    feeType: {
      type: String,
      default: null,
    },
    dynamicRate: {
      type: [String, Number],
      default: null,
    },
    dynamicNominal: {
      type: [String, Number],
      default: null,
    },
    fixedNominal: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    formatRupiah(value) {
      if (value) {
        return `Rp ${formatNominal(value)}`;
      } else {
        return "Rp. 0";
      }
    },
  },
};
</script>

<style scoped>
th,
td {
  width: 50%;
  padding: 8px 16px;
  height: 56px;
  vertical-align: middle;
}

td {
  text-align: left;
  color: #686868;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

th {
  text-align: right;
  color: #686868;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.table-wrapper {
  max-height: 52vh;
  overflow: auto;
  display: inline-block;
}

@media (min-width: 273px) {
  .table-wrapper {
    max-height: 30vh;
  }
}

@media (min-width: 274px) and (max-width: 341px) {
  .table-wrapper {
    max-height: 30vh;
  }
}

@media (min-width: 342px) and (max-width: 455px) {
  .table-wrapper {
    max-height: 30vh;
  }
}

@media (min-width: 456px) and (max-width: 546px) {
  .table-wrapper {
    max-height: 30vh;
  }
}

@media (min-width: 547px) and (max-width: 683px) {
  .table-wrapper {
    max-height: 30vh;
  }
}

@media (min-width: 684px) and (max-width: 781px) {
  .table-wrapper {
    max-height: 30vh;
  }
}

@media (min-width: 782px) and (max-width: 911px) {
  .table-wrapper {
    max-height: 10vh;
  }
}

@media (min-width: 912px) and (max-width: 1093px) {
  .table-wrapper {
    max-height: 26vh;
  }
}

@media (min-width: 1094) and (max-width: 1242px) {
  .table-wrapper {
    max-height: 46vh;
  }
}

@media (min-width: 1243px) and (max-width: 1366px) {
  .table-wrapper {
    max-height: 46vh;
  }
}

@media (min-width: 1367px) and (max-width: 1517px) {
  .table-wrapper {
    max-height: 50vh;
  }
}

@media (min-width: 1518px) and (max-width: 1821px) {
  .table-wrapper {
    max-height: 55vh;
  }
}

@media (min-width: 1822px) and (max-width: 2051px) {
  .table-wrapper {
    max-height: 60vh;
  }
}

@media (min-width: 2052px) and (max-width: 2732px) {
  .table-wrapper {
    max-height: 60vh;
  }
}

@media (min-width: 2733px) and (max-width: 4102px) {
  .table-wrapper {
    max-height: 60vh;
  }
}

@media (min-width: 4103px) and (max-width: 5464px) {
  .table-wrapper {
    max-height: 60vh;
  }
}
</style>
