<template>
  <label
    :for="label"
    class="form-label fw-bold text-muted"
    v-if="label != ''"
    style="font-size: 15px"
  >
    {{ label }}
    <span class="text-danger" v-if="required">*</span>
    <slot name="label"></slot>
  </label>
  <div v-if="type === 'text'" class="position-relative">
    <input
      :type="type"
      class="form-control"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      :required="required"
      @blur="
        () => {
          blurEvent();
          onBlur && onBlur();
        }
      "
      @input="$emit('update:modelValue', $event.target.value)"
      :maxlength="maxlength"
      :minlength="minlength"
      :title="title"
    />
    <div
      class="right-position"
      v-if="
        endPrefix !== '' &&
        endPrefix !== 'search' &&
        endPrefix !== 'chevron-down' &&
        endPrefix !== 'calendar'
      "
    >
      {{ endPrefix }}
    </div>
    <button
      type="submit"
      class="right-position no-border"
      v-if="endPrefix === 'search'"
    >
      <font-awesome icon="magnifying-glass" size="lg" color="#00697F" />
    </button>
    <div class="right-position" v-if="endPrefix === 'chevron-down'">
      <font-awesome icon="chevron-down" size="lg" color="#00697F" />
    </div>
    <div class="right-position" v-if="endPrefix === 'calendar'">
      <font-awesome icon="calendar-days" size="lg" color="#00697F" />
    </div>

    <div class="d-flex align-items-center" v-if="maxlength > 0 && iHaveLength">
      <div
        class="position-absolute text-sm color-gray-2 ps-2 bg-white"
        style="right: 10px; top: 14px"
      >
        {{ modelValue.length }}/{{ maxlength }}
      </div>
    </div>
  </div>
  <div v-if="type === 'email'" class="position-relative">
    <input
      :type="type"
      class="form-control"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      :required="required"
      @blur="
        () => {
          blurEvent();
          onBlur && onBlur();
        }
      "
      @input="$emit('update:modelValue', $event.target.value)"
      :maxlength="maxlength"
      :minlength="minlength"
      :title="title"
    />
    <div
      class="right-position"
      v-if="endPrefix !== '' && endPrefix !== 'search'"
    >
      {{ endPrefix }}
    </div>
    <div class="right-position" v-if="endPrefix === 'search'">
      <font-awesome icon="magnifying-glass" size="lg" color="#00697F" />
    </div>

    <div class="d-flex align-items-center" v-if="maxlength > 0 && iHaveLength">
      <div
        class="position-absolute text-sm color-gray-2 ps-2 bg-white"
        style="right: 10px; top: 14px"
      >
        {{ modelValue.length }}/{{ maxlength }}
      </div>
    </div>
  </div>
  <div v-if="type === 'haveLength'" class="position-relative">
    <input
      :type="type"
      class="form-control"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      :required="required"
      @blur="
        () => {
          blurEvent();
          onBlur && onBlur();
        }
      "
      @input="$emit('update:modelValue', $event.target.value)"
      :maxlength="maxlength"
      :minlength="minlength"
      :title="title"
    />
    <div
      class="right-position"
      v-if="endPrefix !== '' && endPrefix !== 'search'"
    >
      {{ endPrefix }}
    </div>
    <div class="right-position" v-if="endPrefix === 'search'">
      <font-awesome icon="magnifying-glass" size="lg" color="#00697F" />
    </div>

    <div class="d-flex align-items-center" v-if="maxlength > 0">
      <div
        class="position-absolute text-sm color-gray-2 ps-2 bg-white"
        style="right: 10px; top: 14px"
      >
        {{ modelValue.length }}/{{ maxlength }}
      </div>
    </div>
  </div>
  <div v-if="type === 'npwp'" class="position-relative">
    <input
      :type="type"
      class="form-control"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      :required="required"
      @blur="
        () => {
          blurEvent();
          onBlur && onBlur();
        }
      "
      @input="$emit('update:modelValue', $event.target.value)"
      :maxlength="maxlength"
      :minlength="minlength"
      :title="title"
      v-maska="'##.###.###.#-###.###'"
    />
    <div
      class="right-position"
      v-if="endPrefix !== '' && endPrefix !== 'search'"
    >
      {{ endPrefix }}
    </div>
    <div class="right-position" v-if="endPrefix === 'search'">
      <font-awesome icon="magnifying-glass" size="lg" color="#00697F" />
    </div>

    <div class="d-flex align-items-center" v-if="maxlength > 0 && iHaveLength">
      <div
        class="position-absolute text-sm color-gray-2 ps-2 bg-white"
        style="right: 10px; top: 14px"
      >
        {{ modelValue.length }}/{{ maxlength }}
      </div>
    </div>
  </div>
  <div v-if="type === 'number'" class="position-relative">
    <input
      :type="type"
      class="form-control"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      :required="required"
      @blur="
        () => {
          blurEvent();
          onBlur && onBlur();
        }
      "
      @input="$emit('update:modelValue', $event.target.value)"
      :maxlength="maxlength"
      :minlength="minlength"
      :title="title"
      @keypress="type === 'number' && isNumber($event)"
      @keyup.delete="type === 'number' && isNumber($event)"
      @paste="type === 'number' && isNumber($event)"
    />

    <div class="d-flex align-items-center" v-if="maxlength > 0 && iHaveLength">
      <div
        class="position-absolute text-sm color-gray-2 ps-2 bg-white"
        style="right: 10px; top: 14px"
      >
        {{ modelValue.length }}/{{ maxlength }}
      </div>
    </div>
  </div>
  <div v-if="type === 'alphanumerical'" class="position-relative">
    <input
      :type="'text'"
      class="form-control"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      :required="required"
      @blur="
        () => {
          blurEvent();
          onBlur && onBlur();
        }
      "
      @input="$emit('update:modelValue', $event.target.value)"
      :maxlength="maxlength"
      :minlength="minlength"
      :title="title"
      v-on:keypress="isLetterOrNumber($event)"
    />

    <div class="d-flex align-items-center" v-if="maxlength > 0 && iHaveLength">
      <div
        class="position-absolute text-sm color-gray-2 ps-2 bg-white"
        style="right: 10px; top: 14px"
      >
        {{ modelValue.length }}/{{ maxlength }}
      </div>
    </div>
  </div>

  <div class="input-errors" v-for="(error, index) of errors" :key="index">
    <div class="text-danger mt-2 text-sm">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin";
export default {
  mixins: [mixin],
  props: {
    maxlength: {
      type: [Number, String],
      default: 255,
    },
    minlength: {
      type: [Number, String],
      default: null,
    },
    type: {
      default: "text",
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onBlur: {
      type: Function,
    },
    errors: {
      type: Array,
    },
    label: {
      type: String,
      default: "",
    },
    endPrefix: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    iHaveLength: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      onFocus: false,
    };
  },
  methods: {
    validationMax(value, max) {
      if (value.length > max) {
        return value.slice(0, max);
      } else {
        return value;
      }
    },
    blurEvent() {
      this.onFocus = false;
    },
  },
  mounted() {},
};
</script>

<style scoped>
::placeholder {
  color: #b6b6b6;
}

.form-control::placeholder {
  color: #b6b6b6;
}
.form-control:focus::placeholder {
  color: #b4b4b4;
}
.form-control:focus {
  box-shadow: none !important;
  border-color: #00697f !important;
}

.form-control.error {
  border-color: #dc2626 !important;
}

.form-control.error::placeholder {
  border-color: #dc2626 !important;
}

.form-control {
  padding: 0.75rem !important;
  font-size: 15px !important;
}

.form-control:disabled {
  background-color: #f3f3f3 !important;
}

.w-88 {
  width: 88%;
}
.no-border {
  border: 0;
  outline: 0;
}

.right-position {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  font-weight: 600;
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
