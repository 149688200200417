<template>
  <div class="card rounded-pill">
    <div
      class="card-body d-flex justify-content-start gap-3 align-items-center py-4"
    >
      <Icon name="File" size="md" color="##00697F" />
      <small class="text-capitalize overflow-hidden flex-fill">{{
        label
      }}</small>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modal", "label", "filename"],
};
</script>
