import CustomerService from "@/services/customers";

const state = {
  customers: null,
  customersApproval: null,
  configurationsHistories: null,
  meta: null,
};

const getters = {
  customers: (state) => state.customers,
  customersApproval: (state) => state.customersApproval,
  configurationsHistories: (state) => state.configurationsHistories,
  meta: (state) => state.meta,
};

const mutations = {
  SET_CUSTOMERS(state, payload) {
    state.customers = payload;
  },
  SET_CUSTOMERS_APPROVAL(state, payload) {
    state.customersApproval = payload;
  },
  SET_CONFIGURATIONS_HISTORIES(state, payload) {
    state.configurationsHistories = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

const actions = {
  async getCustomers({ commit }, params) {
    const response = await CustomerService.getCustomers(params);

    commit("SET_CUSTOMERS", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
  async getCustomersApproval({ commit }, params) {
    const response = await CustomerService.getCustomersApproval(params);

    commit("SET_CUSTOMERS_APPROVAL", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
  async getConfigurationsHistories({ commit }, params) {
    const response = await CustomerService.getConfigurationHistories(params);

    commit("SET_CONFIGURATIONS_HISTORIES", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
