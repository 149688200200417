import { api } from "@/libs/api";

class BorrowerService {
  async getCustomerDetails(payload) {
    return await api.get("/customers/registration", {
      params: payload,
    });
  }

  async getListOfPrinciples(payload) {
    return await api.get("/principle/lov", {
      params: payload,
    });
  }

  async retrieve(payload) {
    return await api.get("/borrower", {
      params: payload,
    });
  }

  async postBorrowerRegistration(payload) {
    return await api.post(`/registration/borrower`, payload);
  }

  async postMultiplePrinciple(payload) {
    return await api.post(`/borrower/add/principle`, payload);
  }

  async retrieveBorrower(params) {
    return await api.get("/borrower", { params });
  }

  async show(borrowerId) {
    return await api.get("/borrower/detail/" + borrowerId);
  }

  async getBorrowerRegistration(borrowerId, params) {
    const response = await api.get(`/borrower`, { params });
    return response.data.data.items.find((borrower) => {
      return borrower.borrower_id === borrowerId;
    });
  }

  async deactivated(registrationId, payload) {
    return await api.post(
      `/registration/borrower/${registrationId}/deactivate`,
      payload
    );
  }

  async activated(registrationId, payload) {
    return await api.post(
      `/registration/borrower/${registrationId}/active`,
      payload
    );
  }

  async fetchRac(borrowerId) {
    return await api.get("/rac/" + borrowerId);
  }

  async fetchRacResult(borrowerId) {
    return await api.get("/rac/" + borrowerId + "/result");
  }

  async retrieveDistributor(status = "waiting") {
    return await api.get(
      `/borrower?status=${status}&financing_program=distributor`
    );
  }

  async resubmit(payload) {
    return await api.post(`/registration/borrower/override`, payload);
  }

  async updateBorrowerFacilityDuration({
    facility_due_date,
    facility_tenor,
    reason_changed,
    registration_id,
  }) {
    return await api.post(`/borrower/update/facility/duration`, {
      facility_due_date,
      facility_tenor,
      reason_changed,
      registration_id,
    });
  }

  async getBorrowerApprovals(payload) {
    return await api.get("/borrowers/approval", {
      params: payload,
    });
  }

  async getBorrowerChangeHistories(payload) {
    return await api.get("/borrowers/approval/riwayat", {
      params: payload,
    });
  }

  async getBorrowerChangebyId(id) {
    return await api.get(`/borrowers/approval/${id}`);
  }

  async updateBorrowerRegistration(payload) {
    return await api.put("/borrowers/approval/registrasi-fasilitas", payload);
  }

  async approveUpdateBorrower(payload) {
    return await api.put("/borrowers/approval/approve", payload);
  }

  async rejectUpdateBorrower(payload) {
    return await api.put("/borrowers/approval/reject", payload);
  }
}

export default new BorrowerService();
