<template>
  <table class="table table-bordered table-striped">
    <caption>
      Rincian Merchant
    </caption>
    <tbody>
      <tr>
        <td>Tipe Merchant</td>
        <th scope>{{ merchantType }}</th>
      </tr>
      <tr>
        <td>Nama Badan Usaha</td>
        <th scope>{{ companyName }}</th>
      </tr>
      <tr v-if="annualOmzet">
        <td>Omzet Tahunan</td>
        <th scope>{{ rupiahFormat(annualOmzet) }}</th>
      </tr>
      <tr>
        <td>Jenis Usaha</td>
        <th scope>{{ businessType }}</th>
      </tr>
      <tr>
        <td>Berdiri Sejak</td>
        <th scope>
          {{
            establishedMonth !== 0
              ? this.dateFormat(establishedMonth, "MMMM")
              : ""
          }}
          {{ established !== 0 ? established : "" }}
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { formatNominal } from "@/utils/helper";
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    merchantType: {
      type: String,
    },
    companyName: {
      type: String,
    },
    annualOmzet: {
      type: String,
    },
    established: {
      type: String,
    },
    brand: {
      type: String,
    },
    businessType: {
      type: String,
    },
    establishedMonth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      merchant_type:
        this?.detail?.borrower_type == "legal" ? "Badan Usaha" : "Individu",
      long_established: this.detail?.legal_business_information
        ?.usaha_sejak_tahun
        ? Math.ceil(
            new Date().getFullYear() -
              Number(this.detail?.legal_business_information?.usaha_sejak_tahun)
          )
        : 1,
      omset_in_year: formatNominal(
        this.detail?.legal_business_information?.omzet_tahunan ?? 0
      ),
    };
  },
  methods: {
    setMerchantType() {
      this.merchant_type =
        this.detail?.borrower_type == "legal" ? "Badan Usaha" : "Individu";
    },
    setEstablished() {
      this.long_established = this.detail?.legal_business_information
        ?.usaha_sejak_tahun
        ? Math.ceil(
            new Date().getFullYear() -
              Number(this.detail?.legal_business_information?.usaha_sejak_tahun)
          )
        : 1;
    },
  },
  mounted() {
    this.setMerchantType();
    this.setEstablished();
  },
};
</script>

<style scoped>
th,
td {
  width: 50%;
  padding: 8px 16px;
  height: 56px;
  vertical-align: middle;
}
</style>
