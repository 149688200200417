import dayjs from "dayjs";
import "dayjs/locale/id";
import moment from "moment";
import UploadService from "@/services/upload";

dayjs.locale("id");

export default {
  methods: {
    dateFormat(date, format = `DD/MM/YYYY`) {
      if (!date) {
        return "";
      }

      return dayjs(date).format(format);
    },
    timeStampFormat(date, format = `DD/MM/YYYY HH:mm:ss`) {
      if (date === null) {
        return "-";
      }

      return dayjs(date).format(format);
    },
    fullDateFormat(date) {
      if (date === null) {
        return "";
      }

      return dayjs(date).format(`DD MMMM YYYY`);
    },
    rupiahFormat(total) {
      if (!total || isNaN(total)) {
        return "Rp 0";
      }

      const stringValue = String(Number(total).toFixed(3));
      const parts = stringValue.split(".");

      let formattedIntegerPart = "";
      for (let i = parts[0].length - 1, count = 0; i >= 0; i--) {
        formattedIntegerPart = parts[0][i] + formattedIntegerPart;
        count++;
        if (count % 3 === 0 && i !== 0) {
          formattedIntegerPart = "." + formattedIntegerPart;
        }
      }

      let formattedDecimalPart = "";
      if (parts[1] !== "000") {
        let decimalUsed = Number(parts[1].substring(0, 2));
        let limiter = Number(parts[1][2]);

        if (limiter >= 5) {
          decimalUsed += 1;
        }

        if (decimalUsed === 100) {
          let tempNumber = Number(parts[0]);
          tempNumber += 1;
          parts[0] = `${tempNumber}`;
          formattedIntegerPart = "";
          for (let i = parts[0].length - 1, count = 0; i >= 0; i--) {
            formattedIntegerPart = parts[0][i] + formattedIntegerPart;
            count++;
            if (count % 3 === 0 && i !== 0) {
              formattedIntegerPart = "." + formattedIntegerPart;
            }
          }
        } else {
          formattedDecimalPart = `,${decimalUsed.toString().padStart(2, "0")}`;
        }
      }

      return `Rp ${formattedIntegerPart}${formattedDecimalPart}`;
    },
    decimalFormat(value) {
      if (value === null || value === undefined) {
        return "";
      }

      const number = parseFloat(value).toFixed(2);
      const [integerPart, decimalPart] = number.split(".");

      let formattedIntegerPart = "";
      for (let i = integerPart.length - 1, count = 0; i >= 0; i--) {
        formattedIntegerPart = integerPart[i] + formattedIntegerPart;
        count++;
        if (count % 3 === 0 && i !== 0) {
          formattedIntegerPart = "." + formattedIntegerPart;
        }
      }
      return formattedIntegerPart + "," + decimalPart;
    },
    formatExpiredKTP(value) {
      const formattedValue = this.fullDateFormat(value);

      if (
        value === "seumur-hidup" ||
        formattedValue.split("/").slice(-1)[0] === "9999"
      ) {
        return "Seumur Hidup";
      }

      return formattedValue;
    },
    countEstablished(usaha_sejak_tahun, usaha_sejak_bulan) {
      const currentYear = new Date().getFullYear();
      let yearsDifference = currentYear - usaha_sejak_tahun;

      const currentMonth = new Date().getMonth() + 1;
      let monthsDifference = currentMonth - usaha_sejak_bulan;

      if (monthsDifference < 0) {
        yearsDifference--;
        monthsDifference += 12;
      }

      return `${yearsDifference} tahun ${monthsDifference} bulan`;
    },
    taxFormat(value) {
      if (typeof value === "string") {
        return value.replace(
          /(\d{2})(\d{3})(\d{3})(\d)(\d{3})(\d{3})/,
          "$1.$2.$3.$4-$5.$6"
        );
      }
    },
    licenseFormat(value) {
      if (typeof value === "string") {
        return value.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3");
      }
    },
    strSpliter(value, limiter = "_") {
      if (!value) return "";

      return value.split(limiter).join(" ");
    },
    strTrimmer(str) {
      const maxLength = 75;
      const string = str.join(", ");
      const trimmedString = string.substr(0, maxLength);

      if (trimmedString.length >= maxLength) {
        return `${trimmedString}...`;
      }

      return trimmedString;
    },
    fileNaming(value) {
      let name = this.strSpliter(value, "-");

      switch (value) {
        case "nib_sku_photo":
          name = "Dokumen NIB";
          break;
        case "tempat_usaha_photo":
          name = "Foto Tempat Usaha";
          break;
        case "ktp_photo":
          name = "Foto KTP";
          break;
        case "selfie_with_ktp_photo":
          name = "Foto Swafoto dengan KTP";
          break;
        case "kk_photo":
          name = "Foto Kartu Keluarga";
          break;
        case "akta_surat_menikah_photo":
          name = "Foto Foto Akta/Surat Nikah";
          break;
        case "nib_photo":
          name = "NIB Perusahaan";
          break;
        case "tdp_photo":
          name = "TDP Perusahaan";
          break;
        case "npwp_photo":
          name = "NPWP";
          break;
        case "stock_photo":
          name = "Foto Produk";
          break;
        case "business_place_photo":
          name = "Foto Tempat Usaha";
          break;
      }

      return name;
    },
    isPdf(url) {
      return url.toLowerCase().includes(".pdf");
    },
    showPdfModal(pdfUrl) {
      window.open(pdfUrl, "_blank");
    },
    roleNaming(value) {
      const permissions = value.split("/");
      const permission = permissions[0].split("-").join(" ");
      let access = permissions[1];

      if (access !== undefined) {
        access = access
          .replace(/-/g, " ")
          .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
      }

      const formattedAccess = access ? `- ${access}` : "";

      return this.capitalizeFirstLetter(`${permission} ${formattedAccess}`);
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    indexingTable(filter, index) {
      return parseInt(filter.page) > 1
        ? parseInt(filter.per_page) * (parseInt(filter.page) - 1) + index + 1
        : index + parseInt(filter.page);
    },
    checkExtension(filename) {
      return filename.split(".").pop();
    },
    toCapitalize(value) {
      return value;
    },
    removeEmptyObject(values) {
      const filter = values;

      for (const key in filter) {
        if (
          filter[key] === "" ||
          filter[key] === null ||
          filter[key] === undefined
        ) {
          delete filter[key];
        }
      }

      return filter;
    },

    formatDataHistory(values) {
      if (!values) {
        return "-";
      }

      let currentVal = values;

      if (currentVal && currentVal[0] === "{") {
        const parse = JSON.parse(currentVal);

        if (typeof parse === "object") {
          currentVal = this.removeEmptyObject(parse);
          currentVal = JSON.stringify(currentVal);

          return currentVal
            .replace(/["{}]+/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ");
        }
      }

      if (typeof currentVal === "object") {
        currentVal = this.removeEmptyObject(currentVal);
        currentVal = JSON.stringify(currentVal);

        return currentVal
          .replace(/["{}]+/g, "")
          .replace(/,/g, "\n")
          .replace(/:/g, ": ")
          .replace(/_/g, " ");
      }

      return currentVal;
    },

    getDifferences(valueA, valueB) {
      if (valueA && valueA[0] === "{" && valueB && valueB[0] === "{") {
        const a = JSON.parse(valueA);
        const b = JSON.parse(valueB);
        const newObj = Object.entries(b)
          .filter(([key, val]) => a[key] !== val && key in a)
          .reduce((a, [key, v]) => ({ ...a, [key]: v }), {});

        return this.formatDataHistory(newObj);
      } else if (valueB && valueB[0] === "{") {
        return this.formatDataHistory(valueB);
      } else {
        return valueB;
      }
    },

    isHaveAccess(accessName) {
      const user = JSON.parse(localStorage.getItem("user"));
      const permissions = user.role.permissions;
      const findRole = permissions.find(
        (value) => value.permission.name === accessName
      );

      if (!findRole) {
        console.log("findRole", findRole);
        return false;
      } else {
        return true;
      }
    },

    groupingData(data) {
      let values = [];

      for (const item of data) {
        const find = values.find(
          (existingItem) => existingItem.role === item.split("/")[0]
        );

        if (!find) {
          const permissions = data.filter(
            (permission) => permission.split("/")[0] === item.split("/")[0]
          );
          values = [
            ...values,
            {
              role: item.split("/")[0],
              permissions: permissions.sort((a, b) => a.localeCompare(b)),
            },
          ];
        }
      }

      return values;
    },

    stringToNumber(string) {
      if (typeof string === "number") {
        return string;
      }
      let removeDots = null;
      if (typeof string === "string") {
        removeDots = string.split(".").join("");
      }
      if (removeDots) {
        return parseInt(removeDots);
      }
    },

    inputNumberFormat(angka, prefix) {
      let number_string = angka.replace(/[^,\d]/g, "").toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      if (split[1] !== undefined) {
        rupiah += "," + split[1];
      }

      if (prefix === undefined) {
        return rupiah;
      } else {
        return rupiah ? "Rp. " + rupiah : "";
      }
    },

    mergeObject(obj1, obj2) {
      let answer = {};
      for (let key in obj1) {
        if (
          answer[key] === undefined ||
          answer[key] === null ||
          answer[key] === ""
        )
          answer[key] = obj1[key];
      }
      for (let key in obj2) {
        if (
          answer[key] === undefined ||
          answer[key] === null ||
          answer[key] === ""
        )
          answer[key] = obj2[key];
      }
      return answer;
    },

    changeStripToEmptyString(object) {
      let newObj = {};
      for (const [key, value] of Object.entries(object)) {
        if (value === "-") {
          newObj[key] = "";
        } else {
          newObj[key] = value;
        }
      }
      return newObj;
    },

    toInteger(string) {
      if (typeof string === "number") {
        return string;
      }

      if (string === "" || string === "-") {
        return 0;
      }

      return parseInt(string);
    },
    jsonParse(str) {
      try {
        return JSON.parse(str);
      } catch (err) {
        console.error(`Error parsing JSON string: ${err}`);
        return null;
      }
    },
    deepEqual(a, b) {
      return JSON.stringify(a) === JSON.stringify(b);
    },
    isNumber(event) {
      const regExp = /[^0-9,]/g;

      const charCode = event.which || event.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 44
      ) {
        event.preventDefault();
      } else if (
        event.type === "paste" &&
        regExp.test(event.clipboardData.getData("text"))
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },

    formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      const day = String(dateTime.getDate()).padStart(2, "0");
      const month = String(dateTime.getMonth() + 1).padStart(2, "0");
      const year = String(dateTime.getFullYear()).slice(-2);
      const hours = String(dateTime.getHours()).padStart(2, "0");
      const minutes = String(dateTime.getMinutes()).padStart(2, "0");
      const seconds = String(dateTime.getSeconds()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },
    formatDateOfBirth(dateString) {
      if (dateString.length !== 8) {
        return "Invalid Date";
      }

      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6);
      const day = dateString.slice(6, 8);

      const monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agusttus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      const monthIndex = parseInt(month, 10) - 1;

      const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

      return formattedDate;
    },
    formatDateToYYYYMMDD(dateString) {
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      const parts = dateString.split(" ");
      if (parts.length === 3) {
        const day = parts[0];
        const monthIndex = months.indexOf(parts[1]) + 1;
        const year = parts[2];
        return `${year}-${monthIndex
          .toString()
          .padStart(2, "0")}-${day.padStart(2, "0")}`;
      } else {
        return null;
      }
    },
    mapGenderCodeToLabel(genderCode) {
      return genderCode === "MALE" ? "Pria" : "Perempuan";
    },
    formatGender(value) {
      if (value) {
        if (value === "F") {
          return "Perempuan";
        } else {
          return "Pria";
        }
      } else {
        return "Pria";
      }
    },
    parseDateToYearAndMonth(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      return { year, month };
    },
    convertData(data) {
      return data.replace(/["{}]/g, "").replace(/,/g, ", ").replace(/:/g, ": ");
    },
    weekPicker(newDates) {
      if (newDates && newDates.length === 2) {
        console.log(newDates);
        const startDate = moment(newDates[0]).format("YYYY-MM-DD");
        const endDate = moment(newDates[1]).format("YYYY-MM-DD");
        const subtractedEndDate = moment(endDate)
          .subtract(2, "days")
          .format("YYYY-MM-DD");
        return [startDate, subtractedEndDate];
      }
      return [];
    },
    onPaste(event) {
      event.preventDefault();
    },
    handleOpenContactUsModal() {
      this.modalContactUs.isOpen = true;
    },
    handleCloseContactUsModal() {
      this.modalContactUs.isOpen = false;
    },
    async fetchAndSetImageUrl(objectName) {
      try {
        const resUpload = await UploadService.getFiles(objectName);

        if (resUpload.data instanceof Blob) {
          const blob = resUpload.data;
          const imageUrl = URL.createObjectURL(blob);
          this.imageUrl = imageUrl;
          return imageUrl;
        } else {
          console.error("Response does not contain a blob:", resUpload);
          return null;
        }
      } catch (error) {
        console.error("Error fetching or processing image:", error);
        return null;
      }
    },
    async fetchAndSetPdfUrl(objectName) {
      try {
        const resUpload = await UploadService.getFiles(objectName);

        if (resUpload.headers["content-type"] === "application/pdf") {
          const pdfUrl = URL.createObjectURL(resUpload.data);
          return pdfUrl;
        } else {
          console.error("Response does not contain a PDF:", resUpload);
          return null;
        }
      } catch (error) {
        console.error("Error fetching or processing PDF:", error);
        return null;
      }
    },
  },
};
