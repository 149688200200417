const state = {
  isShow: false,
  message: null,
  type: ''
}

const getters = {
  isShow: state => state.isShow,
  message: state => state.message,
  type: state => state.type,
}

const mutations = {
  SET_SHOW(state, payload) {
    state.isShow = payload
  },
  SET_MESSAGE(state, payload) {
    state.message = payload
  },
  SET_TYPE(state, payload) {
    state.type = payload
  },
}

const actions = {
  closeAlert({commit}) {
    commit('SET_MESSAGE', null)
    commit('SET_TYPE', '')
    commit('SET_SHOW', false)
  },
  showupAlert({commit}, {message, type}) {
    commit('SET_MESSAGE', message)
    commit('SET_TYPE', type)
    commit('SET_SHOW', true)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}