import { api } from "@/libs/api";

let state = {
  users: null,
  meta: null,
};

let getters = {
  users: (state) => state.users,
  meta: (state) => state.meta,
};

let mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },
  DELETE_USER(state, payload) {
    state.users.splice(payload, 1);
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  DELETE_USERS(state) {
    state.users = null;
  },
};

let actions = {
  async retrieveUser({ commit }, params) {
    const { data } = await api.get("/users", { params });

    if (data.success) {
      let users = data.data.items;
      commit("SET_USERS", users);
      let metaPayload = {
        page: data.data.page,
        per_page: data.data.per_page,
        total_data: data.data.total_data,
        total_page: data.data.total_page,
      };
      commit("SET_META", metaPayload);

      return true;
    }

    return false;
  },
  async deleteUser({ commit, state }, payload) {
    const index = state.users.findIndex((user) => user.id === payload);

    if (index !== -1) {
      commit("DELETE_USER", index);

      return true;
    }

    return false;
  },
  deleteUsers({ commit }) {
    console.log("delete???");
    commit("DELETE_USERS");
  },
  async storeUser({ commit }, payload) {
    const response = await api.post("/users", payload);

    if (response.success) {
      this.retrieveUser();

      return true;
    }

    return false;
  },
  async updateUser({ commit }, payload) {
    const response = await api.put(`/users/${payload.id}`, payload.user);

    if (response.success) {
      this.retrieveUser();

      return true;
    }

    return false;
  },
  async activateUser({ commit }, payload) {
    const response = await api.post(`/users/${payload}/activate`);

    if (response.success) {
      this.retrieveUser();

      return true;
    }

    return false;
  },
  async deactiveUser({ commit }, payload) {
    const response = await api.post(`/users/${payload}/deactivate`);

    if (response.success) {
      this.retrieveUser();

      return true;
    }

    return false;
  },
  async retriveCollector({ commit }, params) {
    const { data } = await api.get("/users/collector", { params });

    if (data.success) {
      return data.data;
    }

    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
