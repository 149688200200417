<template>
  <base-modal modal-name="declined-reason" :show-close="true" size="lg">
    <template #title>
      <h3 class="fw-bold">Tolak Permohonan</h3>
    </template>
    <template #content>
      <form @submit.prevent="submit">
        <p style="margin-top: -12px">
          Anda menolak permohonan, mohon isi alasan penolakan.
        </p>
        <div class="mb-4">
          <label-regular label="Alasan Penolakan" />

          <radio-input
            label="Dokumen Tidak Sesuai"
            value="Dokumen Tidak Sesuai"
            v-model="reason"
            @change="
              (e) => {
                $emit('setReason', reason);
                handleCheck(e);
              }
            "
          />
          <radio-input
            label="Data tidak Sesuai"
            value="Data tidak Sesuai"
            v-model="reason"
            @change="
              (e) => {
                $emit('setReason', reason);
                handleCheck(e);
              }
            "
          />
          <radio-input
            label="Lainnya"
            value="Lainnya"
            v-model="reason"
            @change="handleCheck"
          />

          <div class="px-4">
            <textarea
              v-if="showTextArea"
              cols="30"
              rows="10"
              class="form-control mt-3"
              v-model="otherReason"
              placeholder="Masukan alasan.."
              @change="updateReason"
            >
            </textarea>
          </div>
        </div>

        <div class="text-center">
          <button
            type="submit"
            class="btn btn-warning w-50"
            :disabled="loading || reason === ''"
          >
            {{ loading ? "Memuat..." : "Kirim" }}
          </button>
          <button
            type="button"
            class="btn btn-secondary d-none"
            data-bs-dismiss="modal"
            ref="close-declined-reason"
          >
            Close
          </button>
        </div>
      </form>
    </template>
  </base-modal>
</template>

<script>
export default {
  components: {},
  props: {
    submit: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reason: "",
      otherReason: "",
      borrowerType: "",
      showTextArea: false,
    };
  },
  mounted() {
    this.borrowerType = localStorage.getItem("borrower_type");
  },
  methods: {
    updateReason() {
      this.$emit("setReason", `${this.reason}, ${this.otherReason}`);
    },
    handleCheck(e) {
      if (e.target.checked) {
        this.showTextArea = true;
      } else {
        this.showTextArea = false;
      }
    },
  },
};
</script>
