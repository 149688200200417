<template>
  <transition name="fade">
    <div class="overlay" @click="handleClose">
      <div
        class="alert alert-success alert-dismissible fade show d-flex align-items-center p-4"
        role="alert"
      >
        <div class="text-left">
          <p class="text-lg fw-bold">
            Principal Berhasil Ditambahkan
            <span><Icon name="Checklist" size="md" /></span>
          </p>
          <p class="text-md">
            Principal berhasil ditambahkan kepada debitur <b>{{ email }}</b
            >. Silahkan menunggu hingga proses verifikasi selesai untuk
            mengaktifkan debitur ini.
          </p>
        </div>
        <slot name="close"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "-",
    },
    email: {
      type: String,
      default: "-",
    },
    show: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
    },
  },
};
</script>

<style scoped>
.alert {
  background-color: #fff;
  width: 32%;
  margin: 0 auto;
  top: 32%;
  border-radius: 24px;
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.success-text {
  color: #047854;
}

.close-btn {
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
}
</style>
