import { api } from "@/libs/api";

class CollectionService {
  async retrieveBillReminder(params) {
    return await api.get(`/loans/collections/pengingat-tagihan`, { params });
  }

  async retrieveDueDate(params) {
    return await api.get(`/loans/collections/jatuh-tempo`, { params });
  }

  async retrieveLatePayment(params) {
    return await api.get(`/loans/collections/terlambat`, { params });
  }

  async retrieveArrears(params) {
    return await api.get(`/loans/collections/menunggak`, { params });
  }

  async retrieveTaskManagement(params) {
    return await api.get(`/loans/tasks`, { params });
  }

  async retrieveRelatedLoan(loan_id, params) {
    return await api.get(`/loans/${loan_id}/related-loans`, { params });
  }

  async retrieveOtherLoan(loan_id, params) {
    return await api.get(`/loans/${loan_id}/other-loans`, { params });
  }

  async getInteractions(loan_id, params) {
    return await api.get(`/loans/${loan_id}/interactions`, { params });
  }

  async postInteractions(payload) {
    return await api.post(`/loans/${payload.loan_id}/interactions`, payload);
  }

  async retrieveCollector(payload) {
    return await api.get(`/users/collector`, payload);
  }

  async changeAssignment(payload) {
    return await api.put(`/loans/${payload.loan_id}/assignment`, payload);
  }
}

export default new CollectionService();
