<template>
  <div class="tab-content" v-show="title == selectedTitle">
    <slot :next="next" />
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  setup () {
    const selectedTitle = inject('selectedTitle')


    return {
      selectedTitle
    }
  },
  methods: {
    next (tab) {
      this.selectedTitle = tab
    }
  },
}
</script>