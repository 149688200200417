<template>
  <div v-if="show">
    <div class="popup-backdrop" @click="handleClose" />
    <div class="popup-close" @click="handleClose">
      <font-awesome icon="close" size="xl" color="#FFFF" />
    </div>

    <div class="popup-content">
      <img :src="image" alt="img-preview" class="popup-image" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
      default: () => alert("close"),
    },
    image: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.popup-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1056;
  transition: opacity 0.15s linear;
}

.popup-close {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 1057;

  cursor: pointer;
}

.popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1057;
  transform: translate(-50%, -50%);
}

.popup-image {
  width: 100%;
  height: auto;
  min-width: 70vw;
  max-height: 70vh;
  object-fit: contain;
}
</style>
