import { createRouter, createWebHistory } from "vue-router";
import auth from "./routes/auth";
import user_management from "./routes/user_management";
import account_management from "./routes/account_management";
import store from "@/store";
import management_borrower from "./routes/management_borrower";
import principal_application from "./routes/principal_application";
import supplier_application from "./routes/supplier_application";
import distributor_application from "./routes/distributor_application";
import management_principal from "./routes/management_principal";
import MainLayout from "@/components/layouts/MainLayout";
import collection from "./routes/collection";
import borrower from "./routes/borrower";
import reporting_monitoring from "./routes/reporting_monitoring";
import dayoff_management from "./routes/dayoff_management";
import audit_log from "./routes/audit_log";

const changeMenu = () => {
  store.dispatch("changeMenu", "/disbursment");
};

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView"),
    meta: {
      layout: MainLayout,
    },
    beforeEnter: changeMenu,
  },
  {
    path: "/404",
    name: "notfound",
    component: () => import("@/views/404"),
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("@/views/Maintenance"),
  },
  {
    path: "/disbursement",
    name: "disbursment",
    component: () => import("@/views/DisbursmentView"),
    meta: {
      layout: MainLayout,
      pageTitle: "Pencairan",
      breadcrumbs: [{ name: "Pencairan", url: "/" }],
    },
    beforeEnter: changeMenu,
  },
  {
    path: "/renew-password",
    name: "renew-password",
    component: () => import("@/views/RenewPasswordView"),
  },
  ...auth,
  ...user_management,
  ...account_management,
  ...management_borrower,
  ...principal_application,
  ...supplier_application,
  ...distributor_application,
  ...management_principal,
  ...collection,
  ...borrower,
  ...reporting_monitoring,
  ...dayoff_management,
  ...audit_log,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const maintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE === "true";
  const startTime = process.env.VUE_APP_SYSTEM_START;
  const endTime = process.env.VUE_APP_SYSTEM_END;

  const isLoggedIn = store.getters["authModule/isLoggedIn"];

  if (to.path === "/maintenance") {
    if (maintenanceMode || isInSystemOnTime(startTime, endTime)) {
      next();
    } else {
      next("/");
    }
  } else if (maintenanceMode || isInSystemOnTime(startTime, endTime)) {
    next("/maintenance");
  } else if (
    to.name !== "auth.login" &&
    to.name !== "auth.forgot-password" &&
    to.name !== "auth.forgot-password-verification" &&
    to.name !== "auth.activation" &&
    to.name !== "renew-password" &&
    !isLoggedIn
  ) {
    return next("/login");
  } else if (to.name === "auth.login" && isLoggedIn) {
    return next("/");
  }

  next();
});

function isInSystemOnTime(startTime, endTime) {
  const now = new Date();
  const start = parseTime(startTime);
  const end = parseTime(endTime);

  const isInSystemOnTime = now >= start && now <= end;

  return !isInSystemOnTime;
}

function parseTime(timeString) {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export default router;
