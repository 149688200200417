<template>
  <div v-if="show">
    <div class="popup-backdrop" @click="() => !loading && handleClose()" />

    <div class="popup-content">
      <h1 class="popup-title">{{ title }}</h1>
      <div class="popup-description">{{ description }}</div>
      <div class="d-flex justify-content-between gap-2 mt-5">
        <button
          class="btn w-100 fw-700"
          :class="submitColor === 'Yellow' ? 'btn-warning' : 'btn-danger'"
          :disabled="loading"
          @click="handleSubmit"
        >
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            v-if="loading"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          {{ !loading && submitText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    handleClose: {
      type: Function,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: "Yes",
    },
    submitColor: {
      type: String,
      default: "Yellow",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    handleSubmit: {
      type: Function,
    },
  },
};
</script>

<style scoped>
.popup-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1056;
  transition: opacity 0.15s linear;
}

.popup-close {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 1057;

  cursor: pointer;
}

.popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1057;
  transform: translate(-50%, -50%);

  background-color: white;
  width: 600px;
  border-radius: 16px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
  padding: 40px;
}

.popup-title {
  font-size: 24px;
  color: #4f4f4f;
  text-align: center;
  font-weight: 700;
}

.popup-description {
  font-size: 15px;
  color: #4f4f4f;
  text-align: center;
}
</style>
