<template>
  <div 
    class="modal fade" 
    :id="modalName" 
    tabindex="-1" 
    :aria-labelledby="modalName" 
    aria-hidden="true">
    <div :class="`modal-dialog modal-${size} modal-dialog-scrollable`">
      <div class="modal-content p-3">
        <div class="modal-header border-0">
          <slot name="title"></slot>
          <button type="button" class="btn-close ms-3" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalName: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    }
  }
}
</script>