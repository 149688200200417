<template>
  <div class="wrapper">
    <header class="d-flex justify-content-between align-items-center">
      <a
        href="#"
        class="fw-bold text-decoration-none text-dark fs-4"
        @click="$router.go(-1)"
      >
        <font-awesome icon="arrow-left-long" class="me-3"></font-awesome>
        {{ title }}
      </a>
      <slot name="header-slot"></slot>
    </header>
    <main>
      <slot name="content"></slot>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Back",
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wrapper {
  padding: 40px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  height: 100vh;
}

.header {
  padding: 40px 40px 24px;
}

main {
  height: calc(100vh - 170px);
}
</style>
