<template>
  <form @submit.prevent="submit">
    <p class="fw-bold text-muted mb-2">Filter Data</p>
    <div class="row">
      <div class="col-md-8">
        <div class="row g-3">
          <div class="col">
            <date-picker
              v-model="filter.upload_date"
              :placeholder="datePlaceholder"
              @cleared="filter.upload_date = ''"
              :enableTimePicker="false"
              autoApply
              inputClassName="form-control-date"
            />
          </div>
          <div class="col">
            <select-input
              v-model="filter.statuses"
              :options="[
                { value: 'active,expired', name: 'Status' },
                { value: 'active', name: 'Active' },
                { value: 'expired', name: 'Expired' },
                { value: 'expired-soon', name: 'Expired Soon' },
              ]"
              placeholder=""
              disableDefaultValue
            />
          </div>
          <div class="col">
            <base-input placeholder="Search Name" v-model="filter.name" />
          </div>
        </div>
      </div>
      <div class="col-md-3 offset-md-1 d-flex gap-3 justify-content-end">
        <button
          type="submit"
          class="btn btn-outline-warning fw-bold flex-fill outline-none shadow-none"
        >
          Apply
        </button>
        <button
          @click="reset"
          type="reset"
          class="btn btn-outline-primary border-0 fw-bold flex-fill outline-none shadow-none"
        >
          Reset
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import mixin from "@/mixin";
import dayjs from "dayjs";
import { mapActions } from "vuex";

export default {
  mixins: [mixin],
  props: {
    datePlaceholder: {
      type: String,
    },
  },
  data() {
    return {
      format: `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}`,
      filter: {
        statuses: "active,expired",
        name: "",
        upload_date: "",
      },
      page: {
        page: 1,
        per_page: 10,
      },
    };
  },
  methods: {
    ...mapActions("documentModule", ["getDocuments"]),
    async submit() {
      const filter = { ...this.page };

      for (const key in this.filter) {
        if (this.filter[key] !== "") {
          filter[key] = this.filter[key];
        }
      }

      await this.getDocuments(filter);
    },
    async reset() {
      this.filter = {
        name: "",
        upload_date: "",
        statuses: "active,expired",
      };

      const renew = { ...this.filter, ...this.page };

      await this.getDocuments(renew);
    },
  },
  watch: {
    "filter.upload_date"(newValue) {
      if (dayjs(newValue).isValid()) {
        this.filter.upload_date = this.dateFormat(newValue, "YYYY-MM-DD");
      }
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #b6b6b6;
}

.form-control,
.btn {
  padding: 0.75rem !important;
  font-size: 15px !important;
}
</style>
