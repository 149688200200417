<template>
  <table class="table table-bordered table-data-verification">
    <caption>
      Rincian Perusahaan
    </caption>
    <tbody>
      <tr>
        <th class="information">Informasi Perusahaan</th>
        <td>
          <OverrideBadge
            :active="company"
            :label="labels"
            class="py-3 px-4"
          ></OverrideBadge>
        </td>
      </tr>
      <tr>
        <th class="information">Informasi Rekening</th>
        <td>
          <OverrideBadge
            :active="accounts"
            :label="labels"
            class="py-3 px-4"
          ></OverrideBadge>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    company: {
      type: Boolean,
      default: true,
    },
    director: {
      type: Boolean,
      default: false,
    },
    accounts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labels: {
        active: "Data Sesuai",
        nonactive: "Data Tidak Sesuai",
      },
      approval: {
        active: "Diterima",
        nonactive: "Ditolak",
      },
    };
  },
};
</script>

<style scoped>
.table-data-verification td {
  width: 50%;
  vertical-align: middle;
  text-align: center;
}

.table-data-verification th.information {
  text-align: left;
  font-weight: normal;
  white-space: inherit;
}
</style>
