<template>
  <span 
    :class="`badge rounded-pill fw-bold rounded-pill text-bg-${type} text-${type} border border-${type} p-3`" 
    style="--bs-bg-opacity: .15;"
  >
    {{ label }}
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: ''
    },
  },
};
</script>

<style scoped>
span {
  border-style: dashed;
}
</style>
