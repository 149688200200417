import { Modal } from "bootstrap";
import $ from "jquery";

export default {
  methods: {
    showModal(modal) {
      new Modal(`#${modal}`).show();
    },
    disposeModal(modal) {
      console.log(modal);
      $(`#${modal}`).removeClass("in");
      $(".modal-backdrop").remove();
      $("body").removeClass("modal-open");
      $("body").css("padding-right", "");
      $(`#${modal}`).hide();
    },
  },
};
