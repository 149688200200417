<template>
  <transition name="fade">
    <div class="overlay" @click="handleClose">
      <div
        class="alert alert-success alert-dismissible fade show d-flex align-items-center"
        role="alert"
      >
        <font-awesome icon="circle-check" class="text-success"></font-awesome>

        <div class="success-text ms-2">{{ message }}</div>

        <font-awesome
          icon="close"
          class="text-success close-btn"
          size="lg"
        ></font-awesome>
        <slot name="close"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "-",
    },
    show: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
    },
  },
};
</script>

<style scoped>
.alert {
  border: 1px dashed #047854;
  background-color: #e0fff5;
  width: 50%;
  margin: 0 auto;
  top: 10%;
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.success-text {
  color: #047854;
}

.close-btn {
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
}
</style>
