export const userManagement = [
  {
    directTo: "/user-management/role",
    label: "Daftar Role",
    permission: "user-management/buat-role/read",
  },
  {
    directTo: "/user-management/user",
    label: "Daftar User",
    permission: "user-management/buat-user/read",
  },
  {
    directTo: "/user-management/permohonan-perubahan-role",
    label: "Permohonan Perubahan Role",
    permission: "user-management/permohonan-perubahan-role/read",
  },
  {
    directTo: "/user-management/permohonan-perubahan-user",
    label: "Permohonan Perubahan User",
    permission: "user-management/permohonan-perubahan-user/read",
  },
];

export const accountManagement = [
  {
    directTo: "/account-management/view",
    label: "Daftar Akun",
    permission: "manajemen-akun-anggota/daftar-akun/read",
  },
  {
    directTo: "/account-management/configuration",
    label: "Konfigurasi",
    permission: "manajemen-akun-anggota/konfigurasi/read",
  },
  {
    directTo: "/account-management/change-request",
    label: "Permohonan Perubahan",
    permission: "manajemen-akun-anggota/permohonan-perubahan/read",
  },
];

export const managementPrincipal = [
  {
    directTo: "/management-principal/principal",
    label: "Daftar Principal",
    permission: "management-principle/daftar-principle/read",
  },
  {
    directTo: "/management-principal/override",
    label: "Override",
    permission: "management-principle/daftar-override/read",
  },
  {
    directTo: "/management-principal/permohonan-perubahan-principal",
    label: "Permohonan Perubahan",
    permission: "management-principle/permohonan-perubahan/read",
  },
];

export const managementBorrower = [
  {
    directTo: "/management-debitur/borrower",
    label: "Daftar Debitur",
    permission: "management-debitur/daftar-debitur/read",
  },
  {
    directTo: "/management-debitur/override",
    label: "Override",
    permission: "management-debitur/daftar-override/read",
  },
  {
    directTo: "/management-debitur/permohonan-perubahan-borrower",
    label: "Permohonan Perubahan",
    permission: "management-debitur/permohonan-perubahan/read",
  },
];

export const principalApplication = [
  {
    directTo: "/permohonan-principal/verifikasi-data",
    label: "Verifikasi Data",
    permission: "permohonan-principle/verifikasi-data/read",
  },
  {
    directTo: "/permohonan-principal/verifikasi-komite",
    label: "Verifikasi Komite Kredit",
    permission: "permohonan-principle/verifikasi-komite-kredit/read",
  },
];

export const supplierApplication = [
  {
    directTo: "/permohonan-borrower/supplier/verifikasi-data",
    label: "Verifikasi Data",
    permission: "permohonan-debitur-supplier/verifikasi-data/read",
  },
  {
    directTo: "/permohonan-borrower/supplier/loan-limit",
    label: "Loan Limit",
    permission: "permohonan-debitur-supplier/loan-limit/read",
  },
  {
    directTo: "/permohonan-borrower/supplier/verifikasi-komite",
    label: "Verifikasi Komite Kredit",
    permission: "permohonan-debitur-supplier/komite-verifikasi/read",
  },
];

export const distributorApplication = [
  {
    directTo: "/permohonan-borrower/distributor/verifikasi-data",
    label: "Verifikasi Data",
    permission: "permohonan-debitur-distributor/verifikasi-data/read",
  },
  {
    directTo: "/permohonan-borrower/distributor/loan-limit",
    label: "Loan Limit",
    permission: "permohonan-debitur-distributor/loan-limit/read",
  },
  {
    directTo: "/permohonan-borrower/distributor/verifikasi-komite",
    label: "Verifikasi Komite Kredit",
    permission: "permohonan-debitur-distributor/komite-verifikasi/read",
  },
];

export const acceptance = [
  {
    directTo: "/acceptance/permohonan",
    label: "Permohonan Acceptance",
    permission: "permohonan-acceptance",
  },
  {
    directTo: "/acceptance/persetujuan",
    label: "Persetujuan Acceptance",
    permission: "persetujuan-acceptance",
  },
  {
    directTo: "/acceptance/pengaturan",
    label: "Pengaturan",
    permission: "pengaturan",
  },
];

export const collection = [
  {
    directTo: "/collection/bill_reminder",
    label: "Pengingat Tagihan",
    permission: "pengingat-tagihan",
  },
  {
    directTo: "/collection/due_date",
    label: "Jatuh Tempo",
    permission: "jatuh-tempo",
  },
  {
    directTo: "/collection/late_payment",
    label: "Terlambat",
    permission: "terlambat",
  },
  {
    directTo: "/collection/arrears",
    label: "Menunggak",
    permission: "menunggak",
  },
  {
    directTo: "/collection/task_management",
    label: "Manajemen Tugas",
    permission: "management-tugas",
  },
];

export const reporting_monitoring = [
  {
    directTo: "/reporting-monitoring/limit-principal",
    label: "Limit Per-Principal",
    permission: "monitoring-reporting/limit-per-principal/read",
  },
  {
    directTo: "/reporting-monitoring/limit-borrower",
    label: "Limit per-Debitur",
    permission: "monitoring-reporting/limit-per-debitur/read",
  },
  {
    directTo: "/reporting-monitoring/laporan-fasilitas-principal",
    label: "Laporan Fasilitas per-Principal",
    permission: "monitoring-reporting/laporan-fasilitas-per-principal/read",
  },
  {
    directTo: "/reporting-monitoring/laporan-fasilitas-peminjaman",
    label: "Laporan Fasilitas Peminjaman",
    permission: "monitoring-reporting/laporan-fasilitas-pinjaman/read",
  },
  {
    directTo: "/reporting-monitoring/pengajuan-dalam-proses",
    label: "Pengajuan Dalam Proses",
    permission: "monitoring-reporting/pengajuan-dalam-proses/read",
  },
  {
    directTo: "/reporting-monitoring/regulator-report",
    label: "Regulator Report",
    permission: "monitoring-reporting/regulator-report/read",
  },
  {
    directTo: "/reporting-monitoring/laporan-pembayaran",
    label: "Laporan Pembayaran",
    permission: "monitoring-reporting/laporan-pembayaran/read",
  },
];

export const dayoffManagement = [
  {
    directTo: "/dayoff-management/setting",
    label: "Pengaturan Hari Libur",
    permission: "manajemen-hari-libur/pengaturan/read",
  },
  {
    directTo: "/dayoff-management/change-request",
    label: "Permohonan Perubahan",
    permission: "manajemen-hari-libur/permohonan-perubahan/read",
  },
];

export const auditLog = [
  {
    directTo: "/audit-log/backoffice",
    label: "Backoffice Log",
    permission: "audit-log/backoffice-log/read",
  },
  {
    directTo: "/audit-log/borrower",
    label: "Debitur Log",
    permission: "audit-log/debitur-log/read",
  },
  {
    directTo: "/audit-log/principle",
    label: "Principal Log",
    permission: "audit-log/principal-log/read",
  },
];
