<template>
  <form @submit.prevent="submit">
    <div class="row">
      <div class="col-md-8">
        <div class="row g-3">
          <div class="col-6">
            <base-input
              placeholder="Cari nomor invoice"
              v-model="filter.invoice_no"
              end-prefix="search"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from "vuex";
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  data() {
    return {
      filter: {
        invoice_no: "",
      },
      page: {
        page: 1,
        per_page: 10,
      },
    };
  },
  methods: {
    ...mapActions("reportingModule", ["getPaymentReports"]),
    async submit() {
      const filter = { ...this.page };

      for (const key in this.filter) {
        if (this.filter[key] !== "") {
          filter[key] = this.filter[key];
        }
      }

      await this.getPaymentReports(filter);
    },
    async reset() {
      this.filter = {
        invoice_no: "",
      };

      await this.getPaymentReports(this.page);
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #b6b6b6;
}

.form-control,
.btn {
  padding: 0.75rem !important;
  font-size: 15px !important;
}
</style>
