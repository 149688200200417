import UserChangeService from "@/services/userChange";

let state = {
  userChanges: null,
  userChangesHistories: null,
  meta: null,
};

let getters = {
  userChanges: (state) => state.userChanges,
  userChangesHistories: (state) => state.userChangesHistories,
  countUserChange: (state) => state.userChanges?.length,
  meta: (state) => state.meta,
};

let mutations = {
  SET_USERCHANGE(state, payload) {
    state.userChanges = payload;
  },
  SET_USERCHANGE_ADDED(state, payload) {
    state.userChanges.push(payload);
  },
  SET_USERCHANGEL_HISTORIES(state, payload) {
    state.userChangesHistories = payload;
  },
  DELETE_USERCHANGE(state) {
    state.userChanges = null;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

let actions = {
  async getUsersApproval({ commit }, params) {
    const response = await UserChangeService.getUsersApproval(params);

    commit("SET_USERCHANGE", response.data.data.items);

    let metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META", metaPayload);
  },

  async getUserChangeHistories({ commit }, params) {
    const response = await UserChangeService.getUserChangeHistories(params);

    commit("SET_USERCHANGEL_HISTORIES", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
