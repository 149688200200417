<template>
  <div
    @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave"
    @dragover.prevent
    @drop.prevent="handleDrop"
    :class="{ 'active-dropzone': active }"
    class="dropzone position-relative"
  >
    <label :for="id">
      <img
        v-if="image"
        :src="image"
        class="company-img rounded"
        alt="dropzone"
      />

      <div v-show="!objectName">
        <Icon name="Upload" size="md" color="black" />
        <div class="text-md my-2">Letakan berkas disini atau</div>
        <div class="btn btn-warning cursor-pointer mb-2 px-4">Pilih Berkas</div>
        <div class="text-center">
          <small>Format : .jpg, .jpeg, .png, maksimal 2MB</small>
        </div>
      </div>
      <div v-show="objectName" class="mt-4">
        <div class="btn btn-outline-warning cursor-pointer mb-2 px-4">
          Ubah Foto
        </div>
      </div>
    </label>
    <input
      type="file"
      :id="id"
      class="dropzoneFile"
      @change="handleChange"
      accept="image/jpg, image/jpeg, image/png"
      :required="required"
    />
    <div
      class="progress w-100 position-absolute bottom-0"
      style="border-top-left-radius: 0px; border-top-right-radius: 0px"
      v-show="percentage > 0 && percentageId === id"
    >
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        :aria-valuenow="percentage"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{
          width: `${percentage}%`,
        }"
        v-show="percentageId === id"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "DropZone",
  setup(props, { emit }) {
    const active = ref(false);

    const handleDragEnter = () => {
      active.value = true;
    };

    const handleDragLeave = () => {
      active.value = false;
    };

    const handleDrop = (event) => {
      event.preventDefault();
      active.value = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const fileName = files[0].name;
        emit("update:objectName", fileName);
        props.handleChange({ target: { files } });
      }
    };

    return {
      active,
      handleDragEnter,
      handleDragLeave,
      handleDrop,
    };
  },
  props: {
    id: {
      type: String,
    },
    objectName: {
      type: String,
      default: "",
    },
    handleChange: {
      type: Function,
    },
    percentage: {
      type: Number,
    },
    percentageId: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    image: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.dropzone {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease all;
  background-color: #e9f7fa;
  border-radius: 16px;

  color: #b4b4b4;
  border: 0.5px dashed #0c80ef;
  label {
    transition: 0.3s ease all;
  }
  input {
    display: none;
  }
}

.company-img {
  width: 400px;
  height: 200px;
  object-fit: cover;
}
.active-dropzone {
  background-color: #e9f7fa;
  border-radius: 16px;
  color: #b4b4b4;
  border: 0.5px dashed #0c80ef;
}

.object-name {
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 16px;
  text-align: left;
}

.object-icon {
  width: 50px;
  height: 50px;
}
</style>
