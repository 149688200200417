import MainLayout from "@/components/layouts/MainLayout";
import store from "@/store";

const changeMenuCollection = () => {
  store.dispatch("changeMenu", "collection");
};

export default [
  {
    path: "/collection/bill_reminder",
    name: "collection.bill_reminder.index",
    component: () => import("@/views/collection/bill_reminder/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Pengingat Tagihan",
      breadcrumbs: [
        { name: "Collection", url: "/" },
        { name: "Pengingat Tagihan", url: "/" },
      ],
    },
    beforeEnter: changeMenuCollection,
  },
  {
    path: "/collection/:id/:loan_id",
    name: "collection.show",
    component: () => import("@/views/collection/ShowView.vue"),
  },

  {
    path: "/collection/due_date",
    name: "collection.due_date.index",
    component: () => import("@/views/collection/due_date/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Jatuh Tempo",
      breadcrumbs: [
        { name: "Collection", url: "/" },
        { name: "Jatuh Tempo", url: "/" },
      ],
    },
    beforeEnter: changeMenuCollection,
  },
  {
    path: "/collection/late_payment",
    name: "collection.late_payment.index",
    component: () => import("@/views/collection/late_payment/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Terlambat",
      breadcrumbs: [
        { name: "Collection", url: "/" },
        { name: "Terlambat", url: "/" },
      ],
    },
    beforeEnter: changeMenuCollection,
  },
  {
    path: "/collection/arrears",
    name: "collection.arrears.index",
    component: () => import("@/views/collection/arrears/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Menunggak",
      breadcrumbs: [
        { name: "Collection", url: "/" },
        { name: "Menunggak", url: "/" },
      ],
    },
    beforeEnter: changeMenuCollection,
  },
  {
    path: "/collection/task_management",
    name: "collection.task_management.index",
    component: () => import("@/views/collection/task_management/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Manajemen Tugas",
      breadcrumbs: [
        { name: "Collection", url: "/" },
        { name: "Manajemen Tugas", url: "/" },
      ],
    },
    beforeEnter: changeMenuCollection,
  },
];
