import { api } from "@/libs/api";

class DisbursementService {
  async checkAvailability() {
    return await api.get("/loans/disbursed/check/availability");
  }

  async getLoanNeedDisbursed(payload) {
    return await api.get("/loans/disbursed/retry", {
      params: payload,
    });
  }

  async getLoanNeedDisbursedDetail(loan_id) {
    return await api.get(`/loans/disbursement-detail/${loan_id}`);
  }

  async getLoanNeedDisbursedCSV(payload) {
    return await api.get("/loans/disbursed/retry/csv", {
      params: payload,
    });
  }

  async retryDisbursement() {
    return await api.post("/loans/retry");
  }

  async disburePaymentById(loan_id, payload) {
    return await api.post(`/loans/${loan_id}/disbursed`, payload);
  }
}

export default new DisbursementService();
