<template>
  <div
    class="modal fade"
    :id="modalName"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="modalName"
    aria-hidden="true"
    data-bs-backdrop="static"
    v-if="isStatic"
    style="background-color: transparent"
  >
    <div
      :class="`modal-dialog modal-dialog-centered modal-${size} modal-dialog-scrollable`"
    >
      <div class="modal-content p-4">
        <div
          class="modal-header justify-content-between border-0"
          v-if="showClose"
        >
          <slot name="title"></slot>
          <button
            type="button"
            class="btn-close ms-3"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="onClosed"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    :id="modalName"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="modalName"
    aria-hidden="true"
    v-else
  >
    <div
      :class="`modal-dialog modal-dialog-centered modal-${size} modal-dialog-scrollable`"
    >
      <div class="modal-content">
        <div
          class="modal-header justify-content-between border-0"
          v-if="showClose"
        >
          <slot name="title"></slot>
          <button
            type="button"
            class="btn-close ms-3"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="onClosed"
          ></button>
        </div>
        <div class="modal-body p-5">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalName: {
      required: true,
      type: String,
    },
    size: {
      type: String,
      default: "",
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    onClosed: {
      type: Function,
      default: () => {},
    },
    isStatic: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
