import MainLayout from "@/components/layouts/MainLayout.vue";
import store from "@/store";

const changeMenuManageBorrower = () => {
  store.dispatch("changeMenu", "management_borrower");
};

const borrower = [
  {
    path: "/management-debitur/borrower",
    name: "management_borrower.index",
    component: () =>
      import("@/views/management_borrower/borrower/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Daftar Debitur",
      breadcrumbs: [
        {
          name: "Management Debitur",
          url: "/",
        },
        {
          name: "Daftar Debitur",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuManageBorrower,
  },
  {
    path: "/management-debitur/borrower/:id",
    name: "management_borrower.borrower.show",
    component: () =>
      import("@/views/management_borrower/borrower/DetailView.vue"),
  },
  {
    path: "/management-debitur/borrower-individu/edit/:id",
    name: "management_borrower.borrower-individu.edit",
    component: () =>
      import(
        "@/views/management_borrower/borrower/EditIndividualBorrowerView.vue"
      ),
  },
  {
    path: "/management-debitur/borrower-legal/edit/:id",
    name: "management_borrower.borrower-legal.edit",
    component: () =>
      import("@/views/management_borrower/borrower/EditLegalBorrowerView.vue"),
  },
  {
    path: "/management-debitur/create/borrower",
    name: "management_borrower.create.borrower",
    component: () =>
      import("@/views/management_borrower/borrower/CreateBorrower.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Tambah Debitur",
      isBack: true,
    },
    beforeEnter: changeMenuManageBorrower,
  },
];

const override = [
  {
    path: "/management-debitur/override",
    name: "management_borrower.override",
    component: () =>
      import("@/views/management_borrower/override/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Management Debitur - Override",
      breadcrumbs: [
        {
          name: "Management Debitur",
          url: "/",
        },
        {
          name: "Override",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuManageBorrower,
  },
  {
    path: "/management-debitur/override/:id",
    name: "management_borrower.show",
    component: () =>
      import("@/views/management_borrower/override/ShowView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Pengajuan Kembali",
      isBack: true,
    },
    beforeEnter: changeMenuManageBorrower,
  },
];

const changeRequest = [
  {
    path: "/management-debitur/permohonan-perubahan-borrower",
    name: "management_borrower.changeRequest.index",
    component: () =>
      import("@/views/management_borrower/changeRequest/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Permohonan Perubahan",
      breadcrumbs: [
        {
          name: "Management Debitur",
          url: "/",
        },
        {
          name: "Permohonan Perubahan",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuManageBorrower,
  },
  {
    path: "/management-debitur/riwayat-perubahan",
    name: "management_borrower.changeRequest.BorrowerChangeHistoryView",
    component: () =>
      import(
        "@/views/management_borrower/changeRequest/BorrowerChangeHistoryView.vue"
      ),
    meta: {
      layout: MainLayout,
      pageTitle: "Permohonan Perubahan",
      breadcrumbs: [
        { name: "Management Debitur", url: "/" },
        { name: "Riwayat Permohonan Perubahan", url: "/" },
      ],
      isBack: true,
    },
    beforeEnter: changeMenuManageBorrower,
  },
];

export default [...borrower, ...override, ...changeRequest];
