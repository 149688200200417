import AuditService from "@/services/auditLog";

const state = {
  auditBackoffice: null,
  auditBorrower: null,
  auditPrinciple: null,
  auditFilter: {
    start_date: "",
    end_date: "",
    jenis_perubahan: "",
    name: "",
    page: 1,
    per_page: 10,
  },
  auditFilterFront: {
    start_date: "",
    end_date: "",
    name: "",
    page: 1,
    per_page: 10,
  },
  meta: null,
};

const getters = {
  auditBackoffice: (state) => state.auditBackoffice,
  auditBorrower: (state) => state.auditBorrower,
  auditPrinciple: (state) => state.auditPrinciple,
  auditFilter: (state) => state.auditFilter,
  auditFilterFront: (state) => state.auditFilterFront,
  meta: (state) => state.meta,
};

const mutations = {
  SET_AUDIT_BACKOFFICE(state, payload) {
    state.auditBackoffice = payload;
  },
  SET_AUDIT_BORROWER(state, payload) {
    state.auditBorrower = payload;
  },
  SET_AUDIT_PRINCIPLE(state, payload) {
    state.auditPrinciple = payload;
  },
  SET_AUDIT_FILTER(state, payload) {
    state.auditFilter = payload;
  },
  SET_AUDIT_FILTER_FRONT(state, payload) {
    state.auditFilterFront = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

const actions = {
  async getAuditBackoffice({ commit }, params) {
    const response = await AuditService.getAuditBackoffice(params);

    commit("SET_AUDIT_BACKOFFICE", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
  async getAuditBorrower({ commit }, params) {
    const response = await AuditService.getAuditBorrower(params);

    commit("SET_AUDIT_BORROWER", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
  async getAuditPrinciple({ commit }, params) {
    const response = await AuditService.getAuditPrinciple(params);

    commit("SET_AUDIT_PRINCIPLE", response.data.data.items);

    const metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };

    commit("SET_META", metaPayload);
  },
  async setAuditFilter({ commit }, values) {
    commit("SET_AUDIT_FILTER", values);
  },
  async setAuditFilterFront({ commit }, values) {
    commit("SET_AUDIT_FILTER_FRONT", values);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
