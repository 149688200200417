<template>
  <div class="card">
    <div class="card-body">
      <div class="text-center">
        <h5 class="fw-bold text-muted mb-3">Informasi Supplier</h5>
        <img
          v-if="acceptance?.registration?.financing_program == 'legal'"
          alt="acceptance-card"
          src="@/assets/logo.png"
          height="100"
        />
      </div>

      <list-flush>
        <list-item>
          <p class="m-0">Nama Badan Usaha</p>
          <p class="m-0 fw-bold">
            {{ acceptance?.registration?.principle_name }}
          </p>
        </list-item>
        <list-item>
          <p class="m-0">Tipe Merchant</p>
          <p class="m-0 fw-bold">
            {{
              acceptance?.registration?.financing_program == "legal"
                ? "Perusahaan"
                : "Individu"
            }}
          </p>
        </list-item>
        <list-item>
          <p class="m-0">Jenis Usaha</p>
          <p class="m-0 fw-bold">Jenis Usaha</p>
        </list-item>
        <list-item>
          <p class="m-0">Tipe Fasilitas</p>
          <p class="m-0 fw-bold text-capitalize">
            {{ strSpliter(acceptance?.product, "-") }}
          </p>
        </list-item>
        <list-item>
          <p class="m-0">Denda</p>
          <p class="m-0 fw-bold">{{ acceptance?.fine_rate }}%</p>
        </list-item>
      </list-flush>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    acceptance: {
      type: Object,
    },
  },
};
</script>
