<template>
  <component :is="this.$route.meta.layout || 'div'">
    <router-view />

    <TimeoutModal
      :show="this.$store.state.modal.isOpen"
      :title="this.$store.state.modal.title"
      :description="this.$store.state.modal.description"
      submitText="OK"
      :handleClose="handleClose"
      :handleSubmit="handleClose"
    />
  </component>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import TimeoutModal from "./components/modal/TimeoutModal.vue";

const openModal = () => {
  store.dispatch("changeModal", {
    isOpen: true,
    title: "Session Timeout",
    description: "Your session is timeout klik OK to redirect to login page",
  });
};

export default {
  components: {
    TimeoutModal,
  },
  data() {
    return {
      IDLE_TIMEOUT: 15 * 60,
      idleSecondsTimer: 0,
      idleSecondsCounter: 0,
    };
  },
  mounted() {
    document.onclick = () => {
      this.idleSecondsCounter = 0;
    };
    document.onmousemove = () => {
      this.idleSecondsCounter = 0;
    };
    document.onkeydown = () => {
      this.idleSecondsCounter = 0;
    };
    document.onkeyup = () => {
      this.idleSecondsCounter = 0;
    };

    this.idleSecondsTimer = window.setInterval(this.CheckIdleTime, 1000);

    const channel = new BroadcastChannel("tab");
    let isOriginal = true;
    const isLoggedIn = store.getters["authModule/isLoggedIn"];
    channel.postMessage("another-tab");
    channel.addEventListener("message", (msg) => {
      if (isLoggedIn) {
        if (msg.data === "another-tab" && isOriginal) {
          // message received from 2nd tab
          // reply to all new tabs that the website is already open
          channel.postMessage("already-open");
        }
        if (msg.data === "already-open") {
          isOriginal = false;
          // message received from original tab
          // replace this with whatever logic you need
          alert("Cannot open multiple instances");
          this.$router.push({
            path: "/404",
            replace: true,
          });
        }
      }
    });
  },
  methods: {
    ...mapActions("authModule", ["doLoggedOut"]),
    async logout() {
      const isLoggedOut = await this.doLoggedOut();
      if (isLoggedOut) {
        this.$router.replace("/login");
      }
    },
    CheckIdleTime() {
      const isLoggedIn = store.getters["authModule/isLoggedIn"];

      this.idleSecondsCounter++;
      if (this.idleSecondsCounter >= this.IDLE_TIMEOUT) {
        window.clearInterval(this.idleSecondsTimer);

        if (isLoggedIn) {
          openModal();
        }
      }
    },
    handleClose() {
      this.logout();
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800&display=swap");

.text-black-80 {
  color: #6b6b6b;
}
.text-expired {
  color: #686868;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}
.padding-bottom-6 {
  padding-bottom: 8px;
}
.padding-bottom-8 {
  padding-bottom: 8px;
}
.section-gap {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.bg-default {
  background-color: #00697f !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-blue {
  background-image: url("@/assets/bg-blue.png");
  background-repeat: no-repeat;
  background-size: 100% 60%;
  width: 100%;
}
.bg-grey {
  background-image: url("@/assets/bg-grey.png");
  background-repeat: no-repeat;
  background-size: 100% 60%;
  width: 100%;
}
.bg-orange {
  background-image: url("@/assets/bg-orange.png");
  background-repeat: no-repeat;
  background-size: 100% 60%;
  width: 100%;
}

.bg-yellow {
  background-color: #ffc007;
}

.bg-green {
  background-color: #74b50c !important;
}

.text-table-header {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #3a3a3a;
}
.text-table-body {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #6b6b6b;
}
.icon-color {
  color: #6b6b6b;
}
::placeholder {
  color: #b6b6b6;
}

.wrapper-content {
  min-height: calc(100vh - 200px);
}

.form-control {
  border-radius: 100px !important;
}

textarea.form-control {
  border-radius: 0.375rem !important;
}

.border-rounded {
  border-radius: 100px;
}

.rounded-parent {
  border-radius: 16px !important;
}

.btn {
  border-radius: 100px !important;
}

.text-decoration-none {
  color: #d45214;

  a {
    text-decoration: none;
  }
}

.form-control-date {
  padding: 0.75rem 0.75rem 0.75rem 2rem !important;
  font-size: 15px !important;
  box-shadow: none !important;
  border-radius: 100px !important;
}

.form-check-label {
  cursor: pointer;
}

.form-select {
  border-radius: 100px !important;
}

.form-select:focus {
  box-shadow: none !important;
}

.form-check-input {
  box-shadow: none !important;
  cursor: pointer;
}

.table {
  table-layout: auto;
  overflow-x: auto;
}

.table th,
.table td {
  text-align: left;
}

.table th {
  white-space: nowrap;
}

table thead th {
  padding: 12px 10px !important;
}

#app {
  font-family: "Inter", sans-serif;
  height: 100vh;
}
.vh-90 {
  height: 90vh !important;
}

.color-primary {
  color: #00697f !important;

  a {
    color: #00697f;
  }

  &:hover {
    color: #00697f;
  }
}
.color-orange {
  color: #d45214 !important;
}
.color-grey {
  color: #6b6b6b;
}
.color-light-grey {
  color: #b6b6b6;
}

.color-black-60 {
  color: #b4b4b4;
}
.color-grey-600 {
  color: #686868;
}

.color-grey-800 {
  color: #1a1a1a;
}
.fw-700 {
  font-weight: 700 !important;
}
.btn-warning {
  font-weight: 700 !important;
  background-color: #00697f !important;
  color: #fff !important;
  border: 1px solid #00697f !important;
}
.btn-warning:focus {
  box-shadow: none !important;
}
.btn-warning:disabled {
  background-color: #e6e6e6 !important;
  color: #b4b4b4 !important;
  border: 1px solid #e6e6e6 !important;
}
.btn-danger {
  font-weight: 700 !important;
}
.btn-outline-warning {
  font-weight: 700 !important;
  color: #00697f !important;
  background-color: transparent !important;

  border: 1px solid #00697f !important;
}
.btn-outline-warning:hover {
  background-color: transparent !important;
  color: #00697f !important;
}
.btn-outline-warning:focus {
  background-color: transparent !important;
  color: #00697f !important;
  box-shadow: none !important;
}
.btn-outline-warning:active {
  background-color: transparent !important;
  color: #00697f !important;
}

.btn-outline-primary {
  color: #00697f !important;
  background-color: transparent !important;
}
.btn-outline-primary:hover {
  color: #00697f !important;
  background-color: transparent !important;
}

.btn-primary {
  font-size: 14px !important;
  background-color: #00697f !important;
  border: 1px solid #00697f !important;
}

.btn-primary:disabled {
  background-color: #f3f3f3 !important;
  color: #b4b4b4 !important;
  border: 1px solid #f3f3f3 !important;
}

.btn-green {
  background-color: #74b50c !important;
  color: #fff !important;
  border-radius: 100px;
  padding: 10px 16px !important;
  text-align: center;
  font-weight: 700 !important;
  font-size: 14px !important;
  border: 1px solid #74b50c !important;
}

.btn-green:disabled {
  background-color: #e6e6e6 !important;
  color: #b4b4b4 !important;
  border: 1px solid #e6e6e6 !important;
}

.btn-red {
  background-color: #ed3151 !important;
  color: #fff !important;
  border-radius: 100px;
  padding: 10px 16px !important;
  text-align: center;
  font-weight: 700 !important;
  font-size: 14px !important;
  border: 1px solid #ed3151 !important;
}

.text-sm {
  font-size: 12px;
}

.text-md {
  font-size: 14px;
}

.text-lg {
  font-size: 16px;
}

.text-xl {
  font-size: 20px;
}

.page-title {
  font-size: 150% !important;
}

.card-file {
  height: 300px;

  img {
    object-fit: contain;
    width: auto;
    height: 200px;
  }
}

.bg-light-blue {
  background-color: #e7f4ff !important;
}

.text-primary {
  color: #0f80f0;
}

.text-gray-500 {
  color: #686868 !important;
}

.cursor-pointer {
  cursor: pointer;
}

caption {
  display: none !important;
}

.font-weight-normal {
  font-weight: normal;
}

.company-img {
  width: 80px;
  height: auto;
  object-fit: scale-down;
}

.approval-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.max-height-content {
  overflow: auto;
  min-height: calc(100vh - 400px);
  max-height: calc(100vh - 400px);
}

.max-height-content-300 {
  overflow: auto;
  min-height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
}

.max-height-content-400 {
  overflow: auto;
  min-height: calc(100vh - 400px);
  max-height: calc(100vh - 400px);
}

.max-height-content-350 {
  overflow: auto;
  min-height: calc(100vh - 350px);
  max-height: calc(100vh - 350px);
}

.max-height-content-470 {
  overflow: auto;
  min-height: calc(100vh - 470px);
  max-height: calc(100vh - 470px);
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 2;
}

.vs__dropdown-toggle {
  border-radius: 100px !important;
  height: 48px;
}

.vs__actions {
  padding-right: 14px !important;
}

.vs__dropdown-menu {
  max-height: 35vh !important;
  z-index: 1059 !important;
}

// .borrower-approval {
//   background-color: transparent !important;
// }

// .table > :not(caption) > * > * {
//   background-color: initial;
// }
// .modal.table > :not(caption) > * > * {
//   background-color: transparent;
// }

.modal {
  th {
    background-color: initial !important;
  }
}

.popup-content {
  th {
    background-color: initial !important;
  }
}
</style>
