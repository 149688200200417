import { api } from "@/libs/api";

class UploadService {
  async upload(payload) {
    return await api.post("/upload", payload);
  }

  async signUrl(object_name) {
    return await api.post("/upload/sign-url", {
      object_name,
    });
  }

  async getFiles(object_name) {
    return await api.get(`/files/${object_name}`, {
      responseType: "blob",
    });
  }
}

export default new UploadService();
