import { api } from "@/libs/api";

class ReportService {
  GetReportPrinciple(params) {
    return api.get("/monitoring-reporting/limit-per-principal", {
      params,
    });
  }
  GetReportBorrower(params) {
    return api.get("/monitoring-reporting/limit-per-borrower", {
      params,
    });
  }

  GetFacilitiesPrinciple(params) {
    return api.get("/reports/facilities/principle", {
      params,
    });
  }

  GetFacilitiesPinjaman(params) {
    return api.get("/reports/facilities/loan", {
      params,
    });
  }

  GetPengajuan(params) {
    return api.get("/loans", {
      params,
    });
  }

  GetLimitCreationHistories(params) {
    return api.get("/limit-creation-histories", {
      params,
    });
  }

  GetLimitCreationHistoriesDownload(params) {
    return api.get("/limit-creation-histories/download", {
      params,
      responseType: "blob",
    });
  }

  ResendLimitCreationHistories(params) {
    return api.get("/limit-creation-histories/resend", { params });
  }

  GetPaymentReports(params) {
    return api.get("/payments/history", {
      params,
    });
  }

  GetDownloadPrinciple(params) {
    return api.get("/monitoring-reporting/limit-per-principal/download", {
      params,
      responseType: "blob",
    });
  }

  GetDownloadBorrower(params) {
    return api.get("/monitoring-reporting/limit-per-borrower/download", {
      params,
      responseType: "blob",
    });
  }

  GetReportPrincipleDownload(params) {
    return api.get("/reports/facilities/principle/excel", {
      params,
      responseType: "blob",
    });
  }

  GetReportBorrowerDownload(params) {
    return api.get("/reports/facilities/loan/excel", {
      params,
      responseType: "blob",
    });
  }

  GetLoansReport(params) {
    return api.get("/reports/facilities/loans/excel", {
      responseType: "blob",
      params,
    });
  }

  GetPaymentsReportsDownload(params) {
    return api.get("/payments/history/excel", {
      responseType: "blob",
      params,
    });
  }
}

export default new ReportService();
