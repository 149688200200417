<template>
  <div class="d-flex justify-content-between my-2">
    <h3 class="fw-bold page-title">
      <font-awesome
        v-if="isBack"
        icon="chevron-left"
        class="me-3 cursor-pointer"
        @click="$router.go(-1)"
      />{{ page }}
    </h3>
    <ul class="breadcrumbs">
      <li
        class="breadcrumbs-item"
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
      >
        <router-link to="">{{ breadcrumb.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
    page: {
      type: [String, Number],
    },
    isBack: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.breadcrumbs-item {
  list-style: none;
  display: inline-block;
}

.breadcrumbs-item a {
  text-decoration: none;
}

.breadcrumbs-item:not(:last-of-type)::after {
  content: "/";
  margin: 0 5px;
  color: #ccc;
}

.breadcrumbs-item:not(:last-of-type) a {
  color: black;
}

.page-title {
  font-size: 150% !important;
}
</style>
