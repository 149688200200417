<template>
  <div class="vh-100">
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-lg-5 d-none d-md-block"
          style="
            padding: 0px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="bg-auth">
            <img src="@/assets/bg.svg" alt="bg-img" />
          </div>
        </div>
        <div class="col-lg-7" style="padding: 0px">
          <div
            class="form-container bg-white border-start d-flex justify-content-center align-items-center"
          >
            <div class="my-auto form-size">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.row {
  margin: 0;
}

.container-fluid {
  padding: 0 !important;
  height: 100vh !important;
}

.auth-image-container {
  z-index: 1;
  zoom: 1.1;
}

@media only screen and (max-width: 1600px) {
  .auth-image-container {
    zoom: 0.82;
  }
}

.form-container {
  z-index: 2;
  height: 100vh;
  padding: 8rem 4rem;
}

.bg-auth {
  background-size: cover;
  background-position: center;
  object-position: center;
  object-fit: cover;
  height: 100%;
}

.auth-text {
  font-size: 14px;
}
.form-size {
  width: 32vw;
}

@media only screen and (max-width: 768px) {
  .form-size {
    width: 100vw;
  }
}
</style>
