import { api } from "@/libs/api";
import RoleService from "@/services/role";

let state = {
  roles: null,
  allRoles: null,
  permissions: [],
  meta: null,
};

let getters = {
  roles: (state) => state.roles,
  allRoles: (state) => state.allRoles,
  permissions: (state) => state.permissions,
  countRole: (state) => state.roles?.length,
  meta: (state) => state.meta,
};

let mutations = {
  SET_ROLE(state, payload) {
    state.roles = payload;
  },
  SET_ALL_ROLE(state, payload) {
    state.allRoles = payload;
  },
  SET_PERMISSION(state, payload) {
    state.permissions = payload;
  },
  SET_ROLE_ADDED(state, payload) {
    state.roles.push(payload);
  },
  DELETE_ROLES(state) {
    state.roles = null;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
};

let actions = {
  async retrieveRole({ commit }, params) {
    const response = await RoleService.retrieve(params);

    commit("SET_ROLE", response.data.data.items);

    let metaPayload = {
      page: response.data.data.page,
      per_page: response.data.data.per_page,
      total_data: response.data.data.total_data,
      total_page: response.data.data.total_page,
    };
    commit("SET_META", metaPayload);
  },
  async retrieveAllRole({ commit }, params) {
    const response = await RoleService.retrieve(params);
    let results = response.data.data.items;
    if (response.data.success && response.data.data.total_page > 1) {
      for (let i = 2; i <= response.data.data.total_page; i++) {
        const resAll = await RoleService.retrieve({ per_page: 10, page: i });
        results.push(...resAll.data.data.items);
      }
    }
    commit("SET_ALL_ROLE", results);
  },
  async storeRole({ commit }, payload) {
    const response = await api.post("/roles", payload);

    if (response.success) {
      this.retrieve();

      return true;
    }

    return false;
  },
  async updateRole({ commit }, payload) {
    const response = await api.put(`/roles/${payload.id}`, payload.role);

    if (response.success) {
      this.retrieve();

      return true;
    }

    return false;
  },
  destroy(payload) {
    alert(`delete ${payload}`);
  },
  async retrievePermission({ commit }) {
    const { data } = await api.get("/permissions");

    if (data.success) {
      const permissions = data.data;

      commit("SET_PERMISSION", permissions);
    }
  },
  deleteRoles({ commit }) {
    commit("DELETE_ROLES");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
