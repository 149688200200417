import MainLayout from "@/components/layouts/MainLayout";
import store from "@/store";

const changeMenuSupplierApp = () => {
  store.dispatch("changeMenu", "supplier_application");
};

export default [
  {
    path: "/supplier_application/data_verification",
    name: "supplier_application.data_verification.index",
    component: () => import("@/views/borrower/data_verification/SupplierView"),
    meta: {
      layout: MainLayout,
      pageTitle: "Verifikasi Data",
      financingProgram: "supplier",
      breadcrumbs: [
        {
          name: "Permohonan Debitur Supplier",
          url: "/",
        },
        {
          name: "Verifikasi Data",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuSupplierApp,
  },
  {
    path: "/supplier_application/data_verification/:id/legal",
    name: "supplier_application.data_verification.legal",
    component: () => import("@/views/borrower/data_verification/LegalView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/supplier_application/data_verification/:id/individual",
    name: "supplier_application.data_verification.individual",
    component: () =>
      import("@/views/borrower/data_verification/IndividualView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/supplier_application/data_verification/:id/approval",
    name: "supplier_application.data_verification.approval",
    component: () =>
      import("@/views/borrower/data_verification/ApprovalView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/supplier_application/credit_verification",
    name: "supplier_application.credit_verification.index",
    component: () =>
      import("@/views/borrower/credit_verification/SupplierView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Verifikasi Komite Kredit",
      financingProgram: "supplier",
      breadcrumbs: [
        {
          name: "Permohonan Debitur Supplier",
          url: "/",
        },
        {
          name: "Verifikasi Komite Kredit",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuSupplierApp,
  },
  {
    path: "/supplier_application/credit_verification/:id",
    name: "supplier_application.credit_verification.show",
    component: () =>
      import("@/views/borrower/credit_verification/ShowView.vue"),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/supplier_application/loan_limit",
    name: "supplier_application.loan_limit.index",
    component: () => import("@/views/borrower/loan_limit/SupplierView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Loan Limit",
      financingProgram: "supplier",
      breadcrumbs: [
        {
          name: "Permohonan Debitur Supplier",
          url: "/",
        },
        {
          name: "Loan Limit",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuSupplierApp,
  },
  {
    path: "/supplier_application/loan_limit/:id",
    name: "supplier_application.loan_limit.show",
    component: () => import("@/views/borrower/loan_limit/ShowView.vue"),
    props: (route) => ({
      ...route.params,
    }),
    meta: {
      financingProgram: "supplier",
    },
  },
  {
    path: "/supplier_application/data_verification/:id/summary",
    name: "supplier_application.data_verification.summary.show",
    component: () => import("@/views/borrower/data_verification/ApprovalView"),
    meta: {
      financingProgram: "supplier",
    },
  },
];
