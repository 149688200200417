import MainLayout from "@/components/layouts/MainLayout";
import store from "@/store";

const changeMenuDayoff = () => {
  store.dispatch("changeMenu", "dayoff");
};

const routes = [
  {
    path: "/dayoff-management/setting",
    name: "dayoff-management.setting",
    component: () => import("@/views/dayoff_management/DayOffSetting.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Pengaturan Hari Libur",
      breadcrumbs: [
        { name: "Manajemen Hari Libur", url: "/" },
        { name: "Pengaturan", url: "/" },
      ],
    },
    beforeEnter: changeMenuDayoff,
  },
  {
    path: "/dayoff-management/calendar",
    name: "dayoff-management.calendar",
    component: () => import("@/views/dayoff_management/DayOffCalendar.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Kalender Hari Libur",
      breadcrumbs: [
        { name: "Manajemen Hari Libur", url: "/" },
        { name: "Kalender Hari Libur", url: "/" },
      ],
      isBack: true,
    },
    beforeEnter: changeMenuDayoff,
  },
  {
    path: "/dayoff-management/change-request",
    name: "dayoff-management.change-request",
    component: () => import("@/views/dayoff_management/ChangeRequestView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Permohonan Perubahan",
      breadcrumbs: [
        { name: "Manajemen Hari Libur", url: "/" },
        { name: "Permohonan Perubahan", url: "/" },
      ],
    },
    beforeEnter: changeMenuDayoff,
  },
  {
    path: "/dayoff-management/change-request/history",
    name: "dayoff-management.change-request.history",
    component: () =>
      import("@/views/dayoff_management/ChangeRequestViewHistory.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Riwayat Permohonan Perubahan",
      breadcrumbs: [
        { name: "Manajemen Hari Libur", url: "/" },
        { name: "Permohonan Perubahan", url: "/" },
      ],
      isBack: true,
    },
    beforeEnter: changeMenuDayoff,
  },
];

export default routes;
