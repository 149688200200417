<template>
  <span
    class="badge rounded-pill fw-bold"
    :class="{
      active: active,
      'non-active ': !active,
    }"
  >
    {{ active ? label.active : label.nonactive }}
  </span>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    label: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped>
span.badge.non-active {
  background-color: #fdeaee;
  border: 1px dashed #ed3151;
  color: #ed3151;
}

span.badge.active {
  background-color: #f1f8e7;
  border: 1px dashed #74b50c;
  color: #74b50c;
}
</style>
