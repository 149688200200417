<template>
  <span class="fw-bold text-muted">{{ fileNaming(name) }}</span>
  <div class="card mb-3 mt-2">
    <div class="card-body text-center">
      <img
        :src="image"
        class="figure-img card-img rounded"
        style="height: 300px"
        alt="..."
      />
      <slot />
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    name: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.figure-img {
  object-fit: cover;
}
</style>
>
